import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { EMAIL_DOMAIN_REGEX, PropTypes } from 'constants';
import { getFormError, isMemberAdmin, isMemberEditor } from 'helpers';

import Body1 from 'components/UI/Typography/Body1';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

import List from '../List';

import s from './index.scss';

export default function OrganizationAccess(props) {
  const { t } = useTranslation();

  const [pendingRemove, setPendingRemove] = useState({});

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const onRemove = (memberDomain) => {
    setPendingRemove({
      ...pendingRemove,
      [memberDomain]: true,
    });

    return props.onRemove(memberDomain)
      .then(() => {
        const { [memberDomain]: _, ...nextValue } = pendingRemove;
        setPendingRemove(nextValue);
        message.success(t('share.org.removeSuccess', { domain: memberDomain }));
      });
  };

  const onSubmit = (data) => {
    clearError('domain');

    // Ensure domain is lowercased without whitespace.
    let domain = data.domain
      .toLowerCase()
      .trim();

    // Remove @ symbol (unsupported by API).
    if (domain[0] === '@') {
      domain = domain.slice(1);
    }

    // Domain must be valid.
    if (!EMAIL_DOMAIN_REGEX.test(domain)) {
      setError('domain', 'invalid', t('share.org.errorInvalidDomain'));
      return undefined;
    }

    // Domain cannot be already invited.
    if (props.members.find((member) => member.userId === domain)) {
      setError('domain', 'duplicate', t('share.org.errorDuplicateDomain'));
      return undefined;
    }

    return props.onInvite({ domain })
      .then(() => {
        message.success(t('share.org.inviteSuccess', { domain }));
        reset();
      });
  };

  return !isMemberAdmin(props.user) && !isMemberEditor(props.user) ? null : (
    <>
      <div className={s.title}>
        {t('share.org.title')}
      </div>
      <Body1
        className={s.message}
        tag="p"
      >
        {t('share.org.message')}
      </Body1>
      <form
        className={s.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className={s.input}
          dark
          disabled={formState.isSubmitting}
          error={getFormError(errors, 'domain')}
          name="domain"
          placeholder={t('form.email.placeholderDomain')}
          ref={register({
            invalid: 'foo',
            required: t('share.org.errorNoDomain'),
          })}
        />
        <Button
          className={s.button}
          dark
          loading={formState.isSubmitting}
          type="submit"
        >
          {t('share.org.submit')}
        </Button>
      </form>
      <List
        members={props.members}
        onRemove={onRemove}
        removing={pendingRemove}
        user={props.user}
        warning={t('share.org.warning')}
      />
    </>
  );
}

OrganizationAccess.propTypes = {
  members: PropTypes.arrayOf(PropTypes.Member).isRequired,
  onInvite: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  user: PropTypes.Member.isRequired,
};

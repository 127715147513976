import initialState from '../initialState';
import {
  RECEIVE_PROJECT,
  UPDATE_YAW,
} from '../actions/types';

export default (state = initialState.yaw, action) => {
  switch (action.type) {
    case RECEIVE_PROJECT:
      return action.payload.scenes.length
        ? action.payload.scenes[0].angle || 0
        : state;
    case UPDATE_YAW:
      return action.payload;
    default:
      return state;
  }
};

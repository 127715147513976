import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getAppHotKeys } from 'react-hotkeys';

import { appHotkeys } from 'constants/hotkeys';
import { getPathProjectId, isEditor } from 'selectors/router';
import { getIdToken } from 'selectors/user';

import Scrollbar from 'components/UI/Scrollbar';

import Sequence from './Sequence';

import s from './index.scss';

class HotkeysList extends React.Component {
  state = {
    noHeader: false,
    shortcuts: getAppHotKeys(appHotkeys),
  }

  componentDidMount() {
    this.setNoHeader();
  }

  componentDidUpdate() {
    this.setNoHeader();
  }

  setNoHeader = () => {
    const { editing, idToken, projectId } = this.props;
    const noHeader = (projectId && !editing) || !idToken;

    if (this.state.noHeader !== noHeader) {
      this.setState({ noHeader });
    }
  }

  render() {
    const { noHeader, shortcuts } = this.state;
    const { editing, idToken, open } = this.props;

    // Disable shortcuts guide outside the editor context for now.
    if (!editing || !idToken) {
      return null;
    }

    const buttonProps = open ? {} : { tabIndex: -1 };

    return (
      <div className={classNames(s.root, { [s.noHeader]: noHeader, [s.open]: open })}>
        <div className={s.panel}>
          <Scrollbar>
            <div className={s.panelInner}>
              <div className={s.header}>
                <h2 className={s.title}>
                  Keyboard shortcuts
                </h2>
                <button
                  aria-label="Close shortcuts panel"
                  className={s.button}
                  onClick={this.props.onClose}
                  type="button"
                  {...buttonProps}
                />
              </div>
              {shortcuts.map((item) => (
                <div className={s.group} key={item.group}>
                  <h3 className={s.title}>
                    {item.group}
                  </h3>
                  <ul className={s.list}>
                    {item.keys.map((key) => (
                      <li className={s.item} key={key.description}>
                        <span className={s.description}>
                          {key.description}
                        </span>
                        <span className={s.sequence}>
                          <Sequence keys={key.sequence} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

HotkeysList.propTypes = {
  editing: PropTypes.bool.isRequired,
  idToken: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
};

export const PureTestComponent = HotkeysList;

const mapStateToProps = (state) => ({
  editing: isEditor(state),
  idToken: getIdToken(state),
  projectId: getPathProjectId(state),
});

export default connect(
  mapStateToProps,
)(HotkeysList);

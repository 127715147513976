export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';

export const DISABLE_CONTROLS = 'DISABLE_CONTROLS';
export const ENABLE_CONTROLS = 'ENABLE_CONTROLS';

export const RECEIVE_FORGE_TOKEN = 'RECEIVE_FORGE_TOKEN';

export const RECEIVE_POST_ATTACHMENT = 'RECEIVE_POST_ATTACHMENT';
export const RECEIVE_PATCH_ATTACHMENT = 'RECEIVE_PATCH_ATTACHMENT';
export const RECEIVE_RESUME_ATTACHMENT = 'RECEIVE_RESUME_ATTACHMENT';
export const RECEIVE_ATTACHMENT = 'RECEIVE_ATTACHMENT';
export const RECEIVE_ATTACHMENTS = 'RECEIVE_ATTACHMENTS';
export const RECEIVE_DELETE_ATTACHMENT = 'RECEIVE_DELETE_ATTACHMENT';
export const UPLOAD_NEW_ATTACHMENT = 'UPLOAD_NEW_ATTACHMENT';

export const RECEIVE_DELETE_ASSET_VIEW = 'RECEIVE_DELETE_ASSET_VIEW';
export const RECEIVE_PATCH_ASSET_VIEW = 'RECEIVE_PATCH_ASSET_VIEW';
export const RECEIVE_POST_ASSET_VIEW = 'RECEIVE_POST_ASSET_VIEW';

export const UPDATE_ATTACHMENT_UPLOAD_PROGRESS = 'UPDATE_ATTACHMENT_UPLOAD_PROGRESS';
export const UPDATE_ATTACHMENT_UPLOAD = 'UPDATE_ATTACHMENT_UPLOAD';
export const UPDATE_ATTACHMENT_UPLOAD_FAILED = 'UPDATE_ATTACHMENT_UPLOAD_FAILED';
export const DELETE_ATTACHMENT_UPLOAD_COMPLETED = 'DELETE_ATTACHMENT_UPLOAD_COMPLETED';

export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT';

export const RECEIVE_PUBLICATION = 'RECEIVE_PUBLICATION';

// Scenes
export const REQUEST_REPLACE_SCENE = 'REQUEST_REPLACE_SCENE';
export const REQUEST_SCENE_UPDATE = 'REQUEST_SCENE_UPDATE';
export const RECEIVE_SCENE = 'RECEIVE_SCENE';
export const RECEIVE_SCENES_FOR_SPACE = 'RECEIVE_SCENES_FOR_SPACE';

export const RECEIVE_DELETE_SCENE = 'RECEIVE_DELETE_SCENE';
export const RECEIVE_DELETE_SPACE_SCENE = 'RECEIVE_DELETE_SPACE_SCENE';

export const RECEIVE_CONSTELLATION = 'RECEIVE_CONSTELLATION';
export const RECEIVE_CONSTELLATIONS = 'RECEIVE_CONSTELLATIONS';
export const RECEIVE_DELETE_CONSTELLATION = 'RECEIVE_DELETE_CONSTELLATION';
export const RECEIVE_DELETE_CONSTELLATION_SCENE = 'RECEIVE_DELETE_CONSTELLATION_SCENE';

export const RECEIVE_SPACE = 'RECEIVE_SPACE';
export const RECEIVE_SPACES = 'RECEIVE_SPACES';

export const RECEIVE_UPDATE_SPACE_SCENE = 'RECEIVE_UPDATE_SPACE_SCENE';
export const BULK_UPDATE_SPACE_SCENES = 'BULK_UPDATE_SPACE_SCENES';

export const RECEIVE_MEMBERS = 'RECEIVE_MEMBERS';

export const RECEIVE_OWNERS = 'RECEIVE_OWNERS';
export const SET_OWNER_DENSITY_ORG = 'SET_OWNER_DENSITY_ORG';
export const RECEIVE_OWNER_DENSITY_ORG = 'RECEIVE_OWNER_DENSITY_ORG';

export const SET_ID_TOKEN = 'SET_ID_TOKEN';

export const SET_MAP_DIMENSIONS = 'SET_MAP_DIMENSIONS';

export const SET_MINIMAP_CENTER = 'SET_MINIMAP_CENTER';

export const UPDATE_PANEL_WIDTHS = 'UPDATE_PANEL_WIDTHS';
export const UPDATE_YAW = 'UPDATE_YAW';

export const SET_EDITOR_FILTER = 'SET_EDITOR_FILTER';
export const SET_EDITOR_FILTERS = 'SET_EDITOR_FILTERS';
export const SET_EDITOR_IS_VISIBLE_SIDEBAR = 'SET_EDITOR_IS_VISIBLE_SIDEBAR';
export const SET_EDITOR_PREVIEW = 'SET_EDITOR_PREVIEW';

export const RECEIVE_PATCH_LINK = 'RECEIVE_PATCH_LINK';
export const RECEIVE_POST_LINK = 'RECEIVE_POST_LINK';
export const RECEIVE_DELETE_LINK = 'RECEIVE_DELETE_LINK';

export const RECEIVE_POST_AUTOLINK = 'RECEIVE_POST_AUTOLINK';

export const RECEIVE_POST_REDACT = 'RECEIVE_POST_REDACT';

export const RECEIVE_POST_PUBLISH = 'RECEIVE_POST_PUBLISH';
export const RECEIVE_DELETE_PROJECT = 'RECEIVE_DELETE_PROJECT';

export const RECEIVE_POTREE_SIGNED_URLS = 'RECEIVE_POTREE_SIGNED_URLS';

export const RECEIVE_DENSITY_SENSORS = 'RECEIVE_DENSITY_SENSORS';

export const RECEIVE_POINT_CLOUD = 'RECEIVE_POINT_CLOUD';
export const RECEIVE_POINT_CLOUDS = 'RECEIVE_POINT_CLOUDS';
export const RECEIVE_POINT_CLOUD_SIGNATURES = 'RECEIVE_POINT_CLOUD_SIGNATURES';
export const RECEIVE_POINT_CLOUD_DERIVATIVES = 'RECEIVE_POINT_CLOUD_DERIVATIVES';
export const RECEIVE_DERIVATIVES_TIFFS = 'RECEIVE_DERIVATIVES_TIFFS';
export const RECEIVE_POST_POINT_CLOUD = 'RECEIVE_POST_POINT_CLOUD';
export const RECEIVE_PATCH_POINT_CLOUD = 'RECEIVE_PATCH_POINT_CLOUD';
export const RECEIVE_PATCH_POINT_CLOUDS = 'RECEIVE_PATCH_POINT_CLOUDS';
export const RECEIVE_DELETE_POINT_CLOUD = 'RECEIVE_DELETE_POINT_CLOUD';
export const RECEIVE_DELETE_POINT_CLOUDS = 'RECEIVE_DELETE_POINT_CLOUDS';
export const RECEIVE_POST_MERGE_POINT_CLOUD = 'RECEIVE_POST_MERGE_POINT_CLOUD';

export const RECEIVE_EXTRACTIONS = 'RECEIVE_EXTRACTIONS';
export const RECEIVE_DELETE_EXTRACTION = 'RECEIVE_DELETE_EXTRACTION';
export const RECEIVE_POST_EXTRACTION = 'RECEIVE_POST_EXTRACTION';
export const REQUEST_FILE_SIZE_ESTIMATE = 'REQUEST_FILE_SIZE_ESTIMATE';
export const RECEIVE_FILE_SIZE_ESTIMATE = 'RECEIVE_FILE_SIZE_ESTIMATE';

export const RECEIVE_SIGNED_URLS = 'RECEIVE_SIGNED_URLS';
export const REQUEST_SIGNED_URLS = 'REQUEST_SIGNED_URLS';

export const ADD_TO_EDITOR_SELECTED_LINKS = 'ADD_TO_EDITOR_SELECTED_LINKS';
export const REMOVE_FROM_EDITOR_SELECTED_LINKS = 'REMOVE_FROM_EDITOR_SELECTED_LINKS';
export const SET_EDITOR_SELECTED_LINKS = 'SET_EDITOR_SELECTED_LINKS';
export const SET_TOUR_EDITOR_FOCUSED_CONSTELLATION = 'SET_TOUR_EDITOR_FOCUSED_CONSTELLATION';

export const ADD_TO_EDITOR_SELECTED_SCENE_IDS = 'ADD_TO_EDITOR_SELECTED_SCENE_IDS';
export const REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS = 'REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS';
export const SET_EDITOR_SELECTED_SCENE_IDS = 'SET_EDITOR_SELECTED_SCENE_IDS';
export const SET_EDITOR_SELECTED_SCENE_IDS_TIME = 'SET_EDITOR_SELECTED_SCENE_IDS_TIME';
export const SET_EDITOR_SELECTED_SCENE_CURRENT_TIME = 'SET_EDITOR_SELECTED_SCENE_CURRENT_TIME';
export const SET_EDITOR_SELECTED_SCENE_DURATION = 'SET_EDITOR_SELECTED_SCENE_DURATION';

export const SET_EDITOR_SELECTED_MODEL_CARD = 'SET_EDITOR_SELECTED_MODEL_CARD';

export const CLOSE_EDITOR_PANELS = 'CLOSE_EDITOR_PANELS';
export const OPEN_EDITOR_PANELS = 'OPEN_EDITOR_PANELS';

export const SET_SHOULD_FOCUS_SCENE_SETTINGS = 'SET_SHOULD_FOCUS_SCENE_SETTINGS';

// App Launcher
export const CLOSE_LAUNCHER = 'CLOSE_LAUNCHER';
export const TOGGLE_LAUNCHER = 'TOGGLE_LAUNCHER';

// Floor Plans
export const RECEIVE_CREATE_FLOOR_PLAN = 'RECEIVE_CREATE_FLOOR_PLAN';
export const RECEIVE_DELETE_FLOOR_PLAN = 'RECEIVE_DELETE_FLOOR_PLAN';
export const RECEIVE_GET_FLOOR_PLANS = 'RECEIVE_GET_FLOOR_PLANS';
export const RECEIVE_UPDATE_FLOOR_PLANS = 'RECEIVE_UPDATE_FLOOR_PLANS';
export const RECEIVE_DUPLICATE_FLOOR_PLANS = 'RECEIVE_DUPLICATE_FLOOR_PLANS';
// Shortcuts Guide
export const TOGGLE_SHORTCUTS_GUIDE = 'TOGGLE_SHORTCUTS_GUIDE';

// Cloud Manager
export const CLEAR_CLOUD_MANAGER_FILTERS = 'CLEAR_CLOUD_MANAGER_FILTERS';
export const SET_CLOUD_MANAGER_FILTER = 'SET_CLOUD_MANAGER_FILTER';

// Errors
export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE';

// Annotations
export const RECEIVE_ASSOCIATE_ANNOTATION_TO_SCENE = 'RECEIVE_ASSOCIATE_ANNOTATION_TO_SCENE';
export const RECEIVE_DELETE_ANNOTATION = 'RECEIVE_DELETE_ANNOTATION';
export const RECEIVE_REMOVE_ANNOTATION_FROM_SCENE = 'RECEIVE_REMOVE_ANNOTATION_FROM_SCENE';
export const RECEIVE_PATCH_ANNOTATION = 'RECEIVE_PATCH_ANNOTATION';
export const RECEIVE_POST_ANNOTATION = 'RECEIVE_POST_ANNOTATION';
export const SET_EDITOR_COPIED_ANNOTATION = 'SET_EDITOR_COPIED_ANNOTATION';

// Density
export const RECEIVE_DENSITY_COMPANIES = 'RECEIVE_DENSITY_COMPANIES';
export const RECEIVE_DENSITY_PLANS = 'RECEIVE_DENSITY_PLANS';

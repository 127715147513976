import React from 'react';
import classNames from 'classnames';
import { Select as AntSelect } from 'antd';

import { PropTypes } from 'constants';
import { getPopupContainer } from 'helpers';

import LabelInput, { INPUT_PLACEMENT } from 'components/UI/Input/LabelInput';

import IconCaretDown from 'assets/svg/IconCaretDown';
import s from './index.scss';

export default function Select(props) {
  const {
    className,
    dark,
    defaultValue,
    hasDefaultValue,
    label,
    options,
    placement,
    ...rest
  } = props;

  let defaultValueOrFirst = defaultValue;
  if (hasDefaultValue && !defaultValueOrFirst) {
    const [first] = options;
    if (first && first.value) {
      defaultValueOrFirst = first.value;
    }
  }
  const snapToSelect = () => getPopupContainer(`#${rest.id}`);

  return (
    <div
      className={classNames(s.wrapper, {
        [s.dark]: dark,
        [s.left]: placement === INPUT_PLACEMENT.LEFT,
        [s.top]: placement === INPUT_PLACEMENT.TOP,
      })}
    >
      <LabelInput
        placement={placement}
        value={label}
      />
      <AntSelect
        className={classNames(s.select, className)}
        defaultValue={defaultValueOrFirst}
        getPopupContainer={snapToSelect}
        suffixIcon={<IconCaretDown size={22} />}
        {...rest}
      >
        {options.map(({ label: labelOption, value, ...propsOption }) => (
          <AntSelect.Option key={value} {...propsOption}>
            {labelOption}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </div>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  defaultValue: PropTypes.string,
  hasDefaultValue: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.SelectOption),
  placement: PropTypes.oneOfMap(INPUT_PLACEMENT),
};

Select.defaultProps = {
  dark: false,
  hasDefaultValue: true,
  label: '',
  options: [],
  placement: INPUT_PLACEMENT.TOP,
};

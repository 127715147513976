import update from 'immutability-helper';

import { SET_CLOUD_MANAGER_FILTER, CLEAR_CLOUD_MANAGER_FILTERS } from '../actions/types';
import initialState from '../initialState';

export default (state = initialState.cloudManager, action) => {
  switch (action.type) {
    case CLEAR_CLOUD_MANAGER_FILTERS:
      return update(state, {
        filters: {
          $set: {},
        },
      });
    case SET_CLOUD_MANAGER_FILTER:
      return update(state, {
        filters: {
          $merge: {
            [action.payload.filter]: action.payload.value,
          },
        },
      });
    default:
      return state;
  }
};

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <line
      stroke="currentColor"
      x1="6"
      x2="18"
      y1="8.5"
      y2="8.5"
    />
    <line
      stroke="currentColor"
      x1="8"
      x2="16"
      y1="12.5"
      y2="12.5"
    />
    <line
      stroke="currentColor"
      x1="10"
      x2="14"
      y1="16.5"
      y2="16.5"
    />
  </Svg>
);

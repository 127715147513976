import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function Image(props) {
  const {
    alt,
    placeholder,
    src,
    ...rest
  } = props;

  const [error, setError] = useState(false);

  const onChange = () => setError(false);

  const onError = () => setError(true);

  useEffect(onChange, [src]);

  return (
    <img
      alt={alt}
      onError={onError}
      src={!src || error ? placeholder : src}
      {...rest}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  src: PropTypes.string,
};

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { colors } from 'styles/variables';
import { Modal as AntModal } from 'antd';

import { didCursorMove, isAntModal } from '../../../helpers';

import Loading from '../../common/Loading';

import s from './index.scss';

export default function Modal(props) {
  const {
    children,
    largeContainer,
    loading,
    wrapClassName,
    ...modalProps
  } = props;

  const cursorEvent = useRef();
  const refElement = useRef();

  useEffect(() => {
    const onClick = (event) => {
      if (event.target === refElement.current && didCursorMove(event, cursorEvent.current, 5)) {
        event.stopPropagation();
      }
      cursorEvent.current = undefined;
      document.removeEventListener('click', onClick, true);
    };

    const onMouseDown = (event) => {
      cursorEvent.current = event;
      refElement.current = undefined;

      let node = event.target;
      while (node !== null) {
        if (isAntModal(node)) {
          refElement.current = node;
          document.addEventListener('click', onClick, true);
          return;
        }

        node = node.parentElement;
      }
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
  }, []);

  return (
    <AntModal
      destroyOnClose
      maskClosable={false}
      wrapClassName={classNames(s.root, {
        [s.largeContainer]: largeContainer,
        [s.loading]: loading,
        [wrapClassName]: wrapClassName,
      })}
      {...modalProps}
    >
      {loading
        ? <Loading color={colors['gray-800']} />
        : children}
    </AntModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  largeContainer: PropTypes.bool,
  loading: PropTypes.bool,
  wrapClassName: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  loading: false,
  wrapClassName: '',
};

Modal.confirm = AntModal.confirm;

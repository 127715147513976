import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import Accordion from 'components/UI/Accordion';
import Body1 from 'components/UI/Typography/Body1';
import Link from 'components/common/Link';
import ModalReportIssue from 'components/UI/Modal/ReportIssue';

import { signOut } from 'actions/auth';
import { PropTypes, ROUTES } from 'constants';
import { getUser } from 'selectors/user';

import Header from '../Header';

import dinoGif from '../../../assets/dino-game.gif';

import s from './index.scss';

function ErrorPage(props) {
  const [visibleReportIssue, setVisibleReportIssue] = useState(false);

  const { t } = useTranslation();

  const onClickReportIssue = () => setVisibleReportIssue(true);

  const onClickSignOut = () => props.signOut();

  const onCloseReportIssue = () => setVisibleReportIssue(false);

  return (
    <div className={s.root}>
      <img alt="" className={s.dinoGif} src={dinoGif} />
      <Header errorPage logoTo={ROUTES.viewer.root()} />
      <div className={s.content}>
        <div className={s.title}>
          {props.title || t('error.title')}
        </div>
        {props.message ? (
          // eslint-disable-next-line react/no-danger
          <p dangerouslySetInnerHTML={{ __html: props.message }} />
        ) : (
          <>
            <div className={s.introContent}>
              {t('error.paragraph')}
            </div>
            <div className={s.introContent}>
              {t('error.paragraphBold')}
            </div>
            <Accordion
              className={s.accordion}
              panels={[
                {
                  body: (
                    <>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.browser.a.bold')}
                      </Body1>
                      <Body1 tag="p">
                        <Trans i18nKey="error.steps.browser.a.normal">
                          <Link
                            className={s.link}
                            newTab
                            simple
                            to="https://google.com/chrome/"
                          />
                        </Trans>
                      </Body1>
                    </>
                  ),
                  title: t('error.steps.browser.q'),
                },
                {
                  body: (
                    <>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.invite.a.bold')}
                      </Body1>
                      <Body1 tag="p">
                        {t('error.steps.invite.a.normal')}
                      </Body1>
                    </>
                  ),
                  title: t('error.steps.invite.q'),
                },
                props.user.email && {
                  body: (
                    <>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.account.a.bold')}
                      </Body1>
                      <Body1 tag="p">
                        <Trans i18nKey="error.steps.account.a.normal">
                          <div className={s.link} label="here" onClick={onClickSignOut} onKeyUp={onClickSignOut} role="button" tabIndex={0} />
                        </Trans>
                      </Body1>
                    </>
                  ),
                  title: t('error.steps.account.q', {
                    email: props.user.email,
                  }),
                },
                {
                  body: (
                    <>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.tabs.a.bold')}
                      </Body1>
                      <Body1 tag="p">
                        {t('error.steps.tabs.a.normal')}
                      </Body1>
                    </>
                  ),
                  title: t('error.steps.tabs.q'),
                },
                {
                  body: (
                    <>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.login.a.yes.bold')}
                      </Body1>
                      <Body1 tag="p">
                        <Trans i18nKey="error.steps.login.a.yes.normal">
                          <Link
                            className={s.link}
                            newTab
                            to={ROUTES.viewer.root()}
                          />
                        </Trans>
                      </Body1>
                      <Body1 className={s.helpSectionTitle} tag="p">
                        {t('error.steps.login.a.no.bold')}
                      </Body1>
                      <Body1 tag="p">
                        {t('error.steps.login.a.no.normal')}
                      </Body1>
                    </>
                  ),
                  title: t('error.steps.login.q'),
                },
              ].filter(Boolean)}
            />
            <div className={s.cta}>
              <Trans i18nKey="error.cta">
                <div className={s.link} label="here" onClick={onClickReportIssue} onKeyUp={onClickReportIssue} role="button" tabIndex={0} />
              </Trans>
            </div>
          </>
        )}
      </div>
      <ModalReportIssue
        onCancel={onCloseReportIssue}
        visible={visibleReportIssue}
      />
    </div>
  );
}

ErrorPage.propTypes = {
  message: PropTypes.string,
  signOut: PropTypes.func.isRequired,
  title: PropTypes.string,
  user: PropTypes.User.isRequired,
};

ErrorPage.defaultProps = {
  message: undefined,
  title: undefined,
};

export const PureTestComponent = ErrorPage;

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  signOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorPage);

import IconAssets from 'assets/svg/IconAssets';
import IconExtractor from 'assets/svg/IconExtractor';
import IconHome from 'assets/svg/IconHome';
import IconModels from 'assets/svg/IconModels';
import IconPlan from 'assets/svg/IconPlan';
import IconPointClouds from 'assets/svg/IconPointClouds';
import IconTour from 'assets/svg/IconTour';

import { ROUTES } from './routes';

export const EDITOR_APPS = (projectId) => [
  {
    icon: IconHome,
    key: 'launchManager',
    label: 'Home',
    sequence: 'shift+1',
    to: ROUTES.editor.project(projectId).settings(),
  },
  {
    icon: IconTour,
    key: 'launchTour',
    label: 'Photo Tour',
    sequence: 'shift+2',
    to: ROUTES.editor.project(projectId).tour(),
  },
  {
    icon: IconPointClouds,
    key: 'launchPointClouds',
    label: 'Cloud Manager',
    sequence: 'shift+3',
    to: ROUTES.editor.project(projectId).pointClouds(),
  },
  {
    icon: IconExtractor,
    key: 'launchExtractor',
    label: 'Extractor',
    sequence: 'shift+4',
    to: ROUTES.editor.project(projectId).extractor.root(),
  },
  {
    icon: IconAssets,
    key: 'launchAssets',
    label: 'Asset Manager',
    sequence: 'shift+5',
    to: ROUTES.editor.project(projectId).assets.root(),
  },
  {
    icon: IconModels,
    key: 'launchModels',
    label: 'Model Cards',
    sequence: 'shift+6',
    to: ROUTES.editor.project(projectId).models(),
  },
  {
    icon: IconPlan,
    key: 'launchPlan',
    label: 'Floor Plan',
    sequence: 'shift+7',
    to: ROUTES.editor.project(projectId).plan(),
  },
];

import update from 'immutability-helper';
import { normalize } from 'normalizr';
import {
  RECEIVE_CONSTELLATIONS,
  RECEIVE_CONSTELLATION,
  RECEIVE_DELETE_CONSTELLATION,
  RECEIVE_DELETE_CONSTELLATION_SCENE,
} from '../actions/types';
import initialState from '../initialState';
import { constellationSchema } from '../schemas';

export default (state = initialState.constellations, action) => {
  switch (action.type) {
    case RECEIVE_CONSTELLATIONS:
      return update(state, {
        items: {
          $set: normalize(action.payload, [constellationSchema]),
        },
      });
    case RECEIVE_DELETE_CONSTELLATION:
      return update(state, {
        items: {
          entities: {
            $unset: [action.payload.id],
          },
          result: {
            $splice: [[state.items.result.indexOf(action.payload.id), 1]],
          },
        },
      });
    case RECEIVE_DELETE_CONSTELLATION_SCENE: {
      const constellation = state.items.entities.constellations[action.payload.constellationId];
      return update(state, {
        items: {
          entities: {
            constellations: {
              [action.payload.constellationId]: {
                scenes: {
                  $splice: [[constellation.scenes.findIndex((x) => x.id === action.payload.sceneId), 1]],
                },
              },
            },
          },
        },
      });
    }
    case RECEIVE_CONSTELLATION:
      return update(state, {
        items: {
          entities: {
            constellations: {
              [action.payload.id]: {
                $set: action.payload,
              },
            },
          },
        },
      });
    default:
      return state;
  }
};

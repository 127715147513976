export const PROJECT_DATES = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
};

export const PROJECT_DISPLAY_AREA = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
};

export const PROJECT_STATUS = {
  DELIVERED: 'DELIVERED',
  IN_PROGRESS: 'IN_PROGRESS',
  SCHEDULED: 'SCHEDULED',
  UNSCANNED: 'UNSCANNED',
};

export const PROJECT_STATUS_LABELS = {
  DELIVERED: 'Delivered',
  IN_PROGRESS: 'In progress',
  SCHEDULED: 'Scheduled',
  UNSCANNED: 'Unscanned',
};

export const PROJECT_STATUS_ORDER = [
  PROJECT_STATUS.UNSCANNED,
  PROJECT_STATUS.SCHEDULED,
  PROJECT_STATUS.IN_PROGRESS,
  PROJECT_STATUS.DELIVERED,
];

export const PROJECT_STATUS_OPTIONS = PROJECT_STATUS_ORDER
  .map((value) => ({ label: PROJECT_STATUS_LABELS[value], value }));

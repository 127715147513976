import update from 'immutability-helper';
import { normalize } from 'normalizr';
import deepEqual from 'fast-deep-equal';

import {
  BULK_UPDATE_SPACE_SCENES,
  RECEIVE_ASSOCIATE_ANNOTATION_TO_SCENE,
  RECEIVE_CREATE_FLOOR_PLAN,
  RECEIVE_DELETE_ANNOTATION,
  RECEIVE_DELETE_CONSTELLATION,
  RECEIVE_DELETE_FLOOR_PLAN,
  RECEIVE_DELETE_LINK,
  RECEIVE_DELETE_SCENE,
  RECEIVE_DELETE_SPACE_SCENE,
  RECEIVE_GET_FLOOR_PLANS,
  RECEIVE_PATCH_ANNOTATION,
  RECEIVE_PATCH_LINK,
  RECEIVE_POST_ANNOTATION,
  RECEIVE_POST_AUTOLINK,
  RECEIVE_POST_LINK,
  RECEIVE_POST_PUBLISH,
  RECEIVE_POST_REDACT,
  RECEIVE_PROJECT,
  RECEIVE_PUBLICATION,
  RECEIVE_REMOVE_ANNOTATION_FROM_SCENE,
  RECEIVE_SCENE,
  RECEIVE_SCENES_FOR_SPACE,
  RECEIVE_SIGNED_URLS,
  RECEIVE_SPACE,
  RECEIVE_SPACES,
  RECEIVE_UPDATE_SPACE_SCENE,
  REQUEST_REPLACE_SCENE,
  REQUEST_SCENE_UPDATE,
  REQUEST_SIGNED_URLS,
} from 'actions/types';
import { SCENE_STATUS } from 'constants';
import { getSceneAnnotations } from 'helpers';
import initialState from 'initialState';
import { projectSchema, sceneSchema, spaceSchema } from 'schemas';

export default (state = initialState.project, action) => {
  switch (action.type) {
    case RECEIVE_DELETE_ANNOTATION:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              $apply: (scenes) => {
                const updatedScenes = {};
                action.payload.sceneIds.forEach((sceneId) => {
                  const currentScene = scenes[sceneId];
                  updatedScenes[sceneId] = {
                    ...currentScene,
                    annotations: currentScene.annotations.filter((a) => a.id !== action.payload.annotationId),
                  };
                });
                return {
                  ...scenes,
                  ...updatedScenes,
                };
              },
            },
          },
        },
      });
    case RECEIVE_REMOVE_ANNOTATION_FROM_SCENE:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.sceneId]: {
                $apply: (scene) => ({
                  ...scene,
                  annotations: getSceneAnnotations(scene)
                    .filter((annotation) => annotation.id !== action.payload.annotationId),
                }),
              },
            },
          },
        },
      });
    case RECEIVE_PATCH_ANNOTATION:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.sceneId]: {
                $apply: (scene) => ({
                  ...scene,
                  annotations: getSceneAnnotations(scene)
                    .map((annotation) => (annotation.id === action.payload.annotationId
                      ? { ...annotation, ...action.payload.data }
                      : annotation)),
                }),
              },
            },
          },
        },
      });
    case RECEIVE_POST_ANNOTATION:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.sceneId]: {
                $apply: (scene) => ({
                  ...scene,
                  annotations: [
                    ...getSceneAnnotations(scene),
                    action.payload.data,
                  ],
                }),
              },
            },
          },
        },
      });
    case RECEIVE_ASSOCIATE_ANNOTATION_TO_SCENE:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.sceneId]: {
                $apply: (scene) => ({
                  ...scene,
                  annotations: [
                    ...getSceneAnnotations(scene),
                    action.payload,
                  ],
                }),
              },
            },
          },
        },
      });
    case RECEIVE_PROJECT:
      return update(state, {
        inst: {
          $set: normalize(action.payload, projectSchema),
        },
      });
    case RECEIVE_SPACE:
      return update(state, {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                spaces: {
                  $apply: (spaces) => {
                    if (!spaces.includes(action.payload.id)) {
                      return [
                        ...spaces,
                        action.payload.id,
                      ];
                    }
                    return spaces;
                  },
                },
              },
            },
            spaces: {
              $merge: {
                [action.payload.id]: action.payload,
              },
            },
          },
        },
      });
    case RECEIVE_SPACES: {
      const { entities, result } = normalize(action.payload, [spaceSchema]);
      return update(state, {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                spaces: {
                  $set: result,
                },
              },
            },
            spaces: {
              $set: entities.spaces,
            },
          },
        },
      });
    }
    case REQUEST_REPLACE_SCENE:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.id]: {
                $merge: {
                  state: SCENE_STATUS.REPLACING,
                },
              },
            },
          },
        },
      });
    case REQUEST_SCENE_UPDATE:
      return update(state, {
        inst: {
          entities: {
            scenes: {
              [action.payload.id]: {
                $merge: action.payload.params,
              },
            },
          },
        },
      });
    case RECEIVE_SCENE:
    case RECEIVE_POST_REDACT: {
      const { entities: { scenes: { [action.payload.id]: scene } } } = normalize(action.payload, sceneSchema);
      return update(state, {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                scenes: {
                  $apply: (scenes) => {
                    if (!scenes.includes(scene.id)) {
                      return [
                        ...scenes,
                        scene.id,
                      ];
                    }
                    return scenes;
                  },
                },
              },
            },
            scenes: {
              $merge: {
                [scene.id]: scene,
              },
            },
          },
        },
      });
    }
    case RECEIVE_UPDATE_SPACE_SCENE: {
      const { scene, sceneId, spaceId } = action.payload;
      const nextSpaceId = scene.spaceId || spaceId;
      const prevScene = state.inst.entities.spaces[spaceId].scenes[sceneId];

      const prevSpace = !scene.spaceId ? {} : {
        [spaceId]: {
          scenes: {
            $apply: (entities) => {
              delete entities[sceneId];
              return entities;
            },
          },
        },
      };

      return update(state, {
        inst: {
          entities: {
            spaces: {
              [nextSpaceId]: {
                scenes: {
                  $merge: {
                    [sceneId]: {
                      ...prevScene,
                      ...scene,
                    },
                  },
                },
              },
              ...prevSpace,
            },
          },
        },
      });
    }
    case BULK_UPDATE_SPACE_SCENES: {
      const updatedSpaceScenes = action.payload.spaceScenes.reduce((payload, spaceScene) => {
        const { mapX, mapY, sceneId } = spaceScene;
        return {
          ...payload,
          [sceneId]: {
            mapX,
            mapY,
          },
        };
      }, {});

      return update(state, {
        inst: {
          entities: {
            spaces: {
              [action.payload.spaceId]: {
                scenes: {
                  $merge: updatedSpaceScenes,
                },
              },
            },
          },
        },
      });
    }
    case RECEIVE_SCENES_FOR_SPACE: {
      const { entities, result } = normalize(action.payload.scenes, [sceneSchema]);
      return update(state, {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                scenes: {
                  $apply: (sceneIds = []) => {
                    const existingSceneIds = {};

                    sceneIds.forEach((sceneId) => {
                      // Scene currently exists within space
                      if (state.inst.entities.spaces[action.payload.spaceId].scenes[sceneId]) {
                        existingSceneIds[sceneId] = true;
                      }
                    });

                    // Add as new all other scenes that haven't been marked as existing
                    return [
                      ...sceneIds,
                      ...result.filter((sceneId) => !existingSceneIds[sceneId]),
                    ];
                  },
                },
              },
            },
            scenes: {
              $apply: (scenes) => {
                const newScenes = scenes || entities.scenes;
                result.forEach((id) => {
                  if (!deepEqual(newScenes[id], entities.scenes[id])) {
                    newScenes[id] = entities.scenes[id];
                  }
                });
                return newScenes;
              },
            },
            spaces: {
              [action.payload.spaceId]: {
                scenes: {
                  $apply: (spaceScenes) => {
                    const newSpaceScenes = {};
                    result.forEach((id) => {
                      if (spaceScenes[id]) {
                        newSpaceScenes[id] = spaceScenes[id];
                      } else {
                        newSpaceScenes[id] = {};
                      }
                    });
                    if (deepEqual(spaceScenes, newSpaceScenes)) {
                      return spaceScenes;
                    }
                    return newSpaceScenes;
                  },
                },
              },
            },
          },
        },
      });
    }
    case RECEIVE_DELETE_SCENE: {
      const updates = {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                scenes: {
                  $splice: [[state.inst.entities.projects[state.inst.result].scenes.indexOf(action.payload), 1]],
                },
              },
            },
            scenes: {
              $apply: (scenes) => Object.keys(scenes)
                .reduce((acc, curr) => {
                  if (scenes[curr].linkHotspots.some((lh) => lh.target === action.payload)) {
                    acc[curr] = update(scenes[curr], {
                      linkHotspots: {
                        $apply: (linkHotspots) => linkHotspots.filter((lh) => lh.target !== action.payload),
                      },
                    });
                  } else {
                    acc[curr] = scenes[curr];
                  }
                  return acc;
                }, {}),
            },
            spaces: {
              $apply: (spaces) => Object.keys(spaces)
                .reduce((acc, curr) => {
                  if (spaces[curr].scenes[action.payload]) {
                    acc[curr] = update(spaces[curr], {
                      scenes: {
                        [action.payload]: {
                          $set: undefined,
                        },
                      },
                    });
                  } else {
                    acc[curr] = spaces[curr];
                  }
                  return acc;
                }, {}),
            },
          },
        },
      };
      return update(state, updates);
    }
    case RECEIVE_DELETE_SPACE_SCENE:
      return update(state, {
        inst: {
          entities: {
            spaces: {
              [action.payload.spaceId]: {
                scenes: {
                  $merge: {
                    [action.payload.sceneId]: undefined,
                  },
                },
              },
            },
          },
        },
      });
    case RECEIVE_DELETE_LINK: {
      const linkHotspotsReducer = (target) => (linkHotspots) => {
        const newLinkHotspots = [...linkHotspots];
        const index = linkHotspots.findIndex((lh) => lh.target === target);
        if (index === -1) {
          return linkHotspots;
        }
        newLinkHotspots.splice(index, 1);
        return newLinkHotspots;
      };
      const updates = {
        inst: {
          entities: {
            scenes: {
              [action.payload.fromId]: {
                linkHotspots: {
                  $apply: linkHotspotsReducer(action.payload.toId),
                },
              },
            },
          },
        },
      };
      if (action.payload.bidirectional) {
        updates.inst.entities.scenes[action.payload.toId] = {
          linkHotspots: {
            $apply: linkHotspotsReducer(action.payload.fromId),
          },
        };
      }
      return update(state, updates);
    }
    case RECEIVE_PATCH_LINK:
      if (state.inst.entities.scenes[action.payload.fromId]) {
        return update(state, {
          inst: {
            entities: {
              scenes: {
                [action.payload.fromId]: {
                  linkHotspots: {
                    $apply: (linkHotspots) => {
                      const { link } = action.payload;
                      const newLinkHotspots = [...linkHotspots];
                      const index = linkHotspots.findIndex((lh) => lh.target === link.toId);
                      if (index === -1) {
                        return linkHotspots;
                      }
                      newLinkHotspots[index] = {
                        ...newLinkHotspots[index],
                        pitch: link.pitch,
                        rotation: link.rotation,
                        type: link.type,
                        url: link.url,
                        yaw: link.yaw,
                      };
                      return newLinkHotspots;
                    },
                  },
                },
              },
            },
          },
        });
      }
      return state;
    case RECEIVE_POST_LINK:
    case RECEIVE_POST_AUTOLINK: {
      const updates = {};
      action.payload.forEach((link) => {
        if (state.inst.entities.scenes[link.fromId]) {
          if (!updates.inst) {
            updates.inst = {
              entities: {
                scenes: {},
              },
            };
          }
          updates.inst.entities.scenes[link.fromId] = {
            linkHotspots: {
              $apply: (linkHotspots) => {
                const newLinkHotspots = [...linkHotspots];
                const index = linkHotspots.findIndex((lh) => lh.target === link.toId);
                const { fromId, toId, ...newLh } = link;
                newLh.target = toId;
                if (index === -1) {
                  newLinkHotspots.push(newLh);
                } else {
                  newLinkHotspots.splice(index, 1, newLh);
                }
                return newLinkHotspots;
              },
            },
          };
        }
      });
      if (updates.inst) {
        return update(state, updates);
      }
      return state;
    }
    case RECEIVE_POST_PUBLISH:
    case RECEIVE_PUBLICATION:
      return update(state, {
        inst: {
          entities: {
            projects: {
              [state.inst.result]: {
                publication: {
                  $set: action.payload,
                },
              },
            },
          },
        },
      });
    case RECEIVE_CREATE_FLOOR_PLAN:
      return update(state, {
        inst: {
          entities: {
            spaces: {
              [action.payload.spaceId]: {
                floorPlansInProgress: {
                  $set: [action.payload],
                },
              },
            },
          },
        },
      });
    case RECEIVE_DELETE_FLOOR_PLAN:
      return update(state, {
        inst: {
          entities: {
            spaces: {
              [action.payload.spaceId]: {
                floorPlansInProgress: {
                  $set: [],
                },
              },
            },
          },
        },
      });
    case RECEIVE_GET_FLOOR_PLANS:
      return state.inst.entities.spaces
        ? update(state, {
          inst: {
            entities: {
              spaces: action.payload.reduce((acc, space) => {
                acc[space.id] = {
                  floorPlan: {
                    $set: space.floorPlan,
                  },
                  floorPlansComplete: {
                    $set: space.floorPlansComplete || [],
                  },
                  floorPlansInProgress: {
                    $set: space.floorPlansInProgress || [],
                  },
                };
                return acc;
              }, {}),
            },
          },
        })
        : state;
    case REQUEST_SIGNED_URLS:
      return update(state, {
        signedUrls: {
          $merge: {
            [action.payload]: {
              fetching: true,
            },
          },
        },
      });
    case RECEIVE_SIGNED_URLS:
      return update(state, {
        signedUrls: {
          $merge: {
            [action.payload.id]: action.payload.signedUrls,
          },
        },
      });
    case RECEIVE_DELETE_CONSTELLATION: {
      const updatedScenes = action.payload.scenes.reduce((acc, scene) => ({
        ...acc,
        [scene.id]: {
          ...state.inst.entities.scenes[scene.id],
          constellationId: undefined,
        },
      }), {});
      return update(state, {
        inst: {
          entities: {
            scenes: {
              $merge: updatedScenes,
            },
          },
        },
      });
    }
    default:
      return state;
  }
};

import React, { useState } from 'react';
import Loading from '.';

export default function useLoader(initialState = true) {
  const [loading, setLoading] = useState(initialState);

  const renderLoader = () => loading && <Loading />;

  return [renderLoader, setLoading, loading];
}

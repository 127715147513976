import { LOGO_RESERVED_WORDS } from '../constants';

const envs = ['dev', 'staging'];

const getNeedle = (value) => `${value}-`;

export const getClientLogo = (editor) => {
  let [namespace] = window.location.hostname.split('.');
  const match = envs.find((env) => namespace.startsWith(getNeedle(env)));
  if (match) {
    namespace = namespace.slice(getNeedle(match).length);
  }
  return editor || LOGO_RESERVED_WORDS.includes(namespace)
    ? ''
    : `https://storage.googleapis.com/helix-assets/${namespace}/logos/logo.png`;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AntAlert } from 'antd';
import classNames from 'classnames';

import s from './index.scss';

export default function Alert(props) {
  return (
    <div className={classNames(s.root, props.className)}>
      <AntAlert
        showIcon
        {...props}
      />
    </div>
  );
}

Alert.propTypes = {
  children: PropTypes.shape(),
  className: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
};

Alert.defaultProps = {
  children: undefined,
  className: undefined,
  type: 'error',
};

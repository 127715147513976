import React, { Suspense } from 'react';
import convert from 'convert-units';
import dateFormat from 'dateformat';
import filesize from 'filesize';
import GcsBrowserUploadStream from 'gcs-browser-upload-stream';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import scrollparent from 'scrollparent';
import * as d3Format from 'd3-format';

import {
  DATETIME_FORMAT,
  GENERIC_STATES,
  UPLOAD,
  ZERO_PLACEHOLDER,
} from '../constants/misc';
import { MEASUREMENT_SYSTEM } from '../constants/units';
import { identity } from './function';
import { getValue } from './immutability';
import { stringifySearch } from './url';
import { isDefined } from './validate';

import Loading from '../components/common/Loading';

export const getFocusableElements = (parent = document) => {
  const selector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  return [...parent.querySelectorAll(selector)];
};

export const getFocusableElementsSafe = (parent) => (parent
  ? getFocusableElements(parent)
  : []);

export const isAntModal = (node) => {
  const hasAntModalClass = node.classList.contains('ant-modal-wrap');
  const hasAntModalRole = node.getAttribute('role') === 'dialog';
  return hasAntModalClass && hasAntModalRole;
};

export const makeMarkerGrid = (count, markerSize) => {
  const cols = Math.ceil(Math.sqrt(count));
  const rows = Math.ceil(count / cols);

  const grid = [];

  for (let i = 0; i < count; i += 1) {
    grid.push({
      x: ((i % cols) * (markerSize * 2)),
      y: (Math.floor(i / cols) * (markerSize * 2)),
    });
  }

  return {
    grid,
    gridHeight: markerSize * rows * 2,
    gridWidth: markerSize * cols * 2,
  };
};

export const joinPath = (base, path) => `${base.charAt(base.length - 1) === '/'
  ? base.slice(0, -1) : base}${path}`;

export const createKeyMap = (keys) => keys.reduce((keyMap, action) => {
  keyMap.keyMap[action.key] = action.sequence;
  if (action.tooltip) {
    const replacement = action.sequence.toUpperCase();
    keyMap[action.key] = action.tooltip.replace('%seq', replacement);
  }
  return keyMap;
}, { keyMap: {} });

// eslint-disable-next-line camelcase
export const getUrlWithAccessToken = (url, access_token) => {
  if (!url) return '';
  const join = url.includes('?') ? '&' : '?';
  return url + join + stringifySearch({ access_token });
};

export const createUploadStream = (options) => {
  const {
    attachment,
    file,
    onComplete,
    onProgress,
    resumable,
    validateChecksum,
  } = options;

  const stream = new GcsBrowserUploadStream.Upload({
    chunkSize: UPLOAD.chunkSize,
    file,
    id: attachment.id,
    onProgress: (info) => {
      const progress = info.uploadedBytes / info.totalBytes;

      if (onProgress) {
        onProgress(attachment.id, progress);
      }

      if (progress === 1 && onComplete) {
        onComplete(attachment.id);
      }
    },
    resumable: resumable || UPLOAD.resumable,
    storage: UPLOAD.storage,
    url: attachment.directUploadUrl,
    validateChecksum,
  });

  return stream;
};

export const createUploadStreamWithDispatch = (options) => (dispatch, getState) => {
  const {
    attachment,
    file,
    onComplete,
    onProgress,
    resumable,
    validateChecksum,
  } = options;
  const { user: { idToken } } = getState();
  const url = getUrlWithAccessToken(attachment.downloadLink, idToken);

  const stream = new GcsBrowserUploadStream.Upload({
    chunkSize: UPLOAD.chunkSize,
    file,
    id: attachment.id,
    onProgress: (info) => {
      const progress = info.uploadedBytes / info.totalBytes;

      if (onProgress) {
        onProgress(attachment.id, progress);
      }

      if (progress === 1 && onComplete) {
        onComplete(attachment.id);
      }
    },
    resumable: resumable || UPLOAD.resumable,
    storage: UPLOAD.storage,
    url,
    validateChecksum,
  });

  return stream;
};

export const formatTransformationMatrix = (matrix = '', lineRegex) => matrix
  .trim()
  .replace(/\s+/g, ' ')
  .split(lineRegex)
  .map((x) => x.replace(/,/g, '').trim())
  .filter(Boolean)
  .join('\n');

export const getFileType = (file) => file.type || 'application/octet-stream';

export const getFormat = (originalFileName = '') => {
  const parts = originalFileName.split('.');
  return parts.length > 1 ? parts[parts.length - 1].toLocaleUpperCase() : '';
};

export const converFormatToMIME = (originalFileName = '') => {
  const parts = originalFileName.split('.');
  const fileFormat = parts.length > 1 ? parts[parts.length - 1].toLocaleUpperCase() : '';
  if (fileFormat === 'JPG') return 'image/jpeg';
  if (fileFormat === 'PNG') return 'image/png';
  if (fileFormat === 'SVG') return 'image/svg+xml';
  return null;
};

export const isSentryErrorFromForgeOrPotreeViewer = (event) => event.exception.values
  .some(({ stacktrace }) => {
    if (!stacktrace || !stacktrace.frames) {
      return false;
    }
    return stacktrace.frames
      .some((frame) => {
        const file = frame.filename.toLowerCase();
        return file.includes('autodesk') || file.includes('potree');
      });
  });

export const lazyLoad = (dynamicImportFunc, Fallback = Loading) => {
  const Component = React.lazy(dynamicImportFunc);
  return (props) => (
    <Suspense fallback={Fallback ? <Fallback /> : Fallback}>
      <Component {...props} />
    </Suspense>
  );
};

export const maxDecimalPlaces = (num, max) => Number.parseFloat(num.toFixed(max));

export const sortAttachments = (items) => {
  const OK = GENERIC_STATES.COMPLETE;
  return items.sort((a, b) => {
    if (a.status !== OK && b.status === OK) return -1;
    if (b.status !== OK && a.status === OK) return 1;
    return (b.updatedAt || 0) - (a.updatedAt || 0);
  });
};

export const sortByAttachment = (items) => {
  const OK = GENERIC_STATES.COMPLETE;
  return items.sort((a, b) => {
    if (a.state !== OK && b.state === OK) return -1;
    if (b.state !== OK && a.state === OK) return 1;
    if (!a.attachment) return 1;
    if (!b.attachment) return -1;
    return b.attachment.createdAt - a.attachment.createdAt;
  });
};

export const areAssetViewsEqual = (a, b) => {
  const keys = ['cutPlanes', 'initialCameraPosition', 'vectorCutPlanes'];
  return !keys.some((key) => !isEqual(a[key], b[key]));
};

export const scrollToElement = (params) => {
  const {
    behavior = 'smooth',
    containerHeight,
    element,
    scrollPaddingBottom = 0,
    scrollPaddingTop = 0,
  } = params;

  const scrollParent = scrollparent(element);
  const scrollParentOffset = scrollParent.scrollHeight - containerHeight;
  const scrollParentHeight = scrollParent.clientHeight;
  const scrollParentTop = scrollParent.scrollTop - scrollParentOffset;
  const elTop = element.offsetTop;
  const elBottom = elTop + element.clientHeight;
  let newScrollTop;

  if (elTop < scrollParentTop) {
    newScrollTop = (elTop + scrollParentOffset) - scrollPaddingTop;
  } else {
    newScrollTop = (elBottom - scrollParentHeight) + scrollParentOffset + scrollPaddingBottom;
  }

  if (typeof Element.prototype.scroll === 'function') {
    scrollParent.scroll({ behavior, top: newScrollTop });
  } else {
    scrollParent.scrollTop = newScrollTop;
  }
};

// Dates helpers. Should be used only to 100% determine whether a condition
// is true. Do not use them for else statements. For example, just because
// a date is not in the future, does not mean it is in the past. We might
// be passing invalid date values.
export const isValidDate = (value) => isDefined(value);
const unixFromDate = (value) => new Date(value).getTime();

export const isDateInTheFuture = (value) => isValidDate(value)
  && unixFromDate(value) - Date.now() > 0;

// Date formatting for Project and Spaces.

// This converter is used to send timestamps to server. It should be sending
// 13-digit timestamps.
export const dateToUnix = (date) => moment(date).format('x');

// This converter is used to display timestamps to the client. It should be
// showing date in the user's local time (no UTC).
export const unixToDate = (unix) => moment(unix).format('YYYY-MM-DD');

const formatFunc = (func) => (value, options) => {
  if (value === undefined || value === '') {
    return ZERO_PLACEHOLDER;
  }
  return func(value, options);
};

export const formatDate = formatFunc((value, customFunc) => (customFunc
  ? dateFormat(value, customFunc.format || DATETIME_FORMAT)
  : new Date(value)
    .toLocaleDateString('default', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })));

export const formatSize = formatFunc(filesize);

export const formatSIString = (input) => {
  const d3f = formatFunc(d3Format.format('.2s'));
  return d3f(input).replace(/G/, 'B');
};

export const formatString = formatFunc((value) => value);

// GPS Coordinates formatting.
export const formatGPSCoordinate = (coor) => maxDecimalPlaces(coor, 6);

export const hasGPSLocation = (project) => {
  if (!project || !project.config) return false;
  const { latitude, longitude } = project.config;
  return typeof latitude === 'number' && typeof longitude === 'number';
};

// Area formatting.
export const formatArea = (units, options = {}) => (value) => {
  const decimalPlaces = options.maxDecimalPlaces !== undefined
    ? options.maxDecimalPlaces
    : 2;
  const format = options.format !== undefined
    ? options.format
    : false;

  if (!value && ['string', 'undefined'].includes(typeof value)) {
    return format ? ZERO_PLACEHOLDER : value;
  }
  const num = units === MEASUREMENT_SYSTEM.IMPERIAL
    ? convert(value).from('m2').to('ft2')
    : Number.parseFloat(value);
  const trimmed = maxDecimalPlaces(num, decimalPlaces);
  if (format) {
    return trimmed ? trimmed.toLocaleString() : ZERO_PLACEHOLDER;
  }
  return trimmed;
};

export const onChangeAreaReduxForm = (displayUnits, change) => (rawValue, nextValue, prevValue, name) => {
  let value = rawValue;
  if (rawValue || typeof rawValue === 'number') {
    value = displayUnits === MEASUREMENT_SYSTEM.IMPERIAL
      ? convert(rawValue).from('ft2').to('m2')
      : Number.parseFloat(rawValue);
  }
  change(name, value);
  return value;
};

export const parseMinPointSpacing = (value) => (typeof value === 'string'
  ? value.replace(/[^\d.]/g, '')
  : value);

export const formatMinPointSpacing = (value) => (value === ''
  ? value
  : `${parseMinPointSpacing(value)}mm`);

// Redux Form.

// Redux form updates internal Redux state on every event, such as focus, blur,
// or text typing. In certain cases, for example when formatting units on the
// Field level just before they get rendered, recalculations on focus or blur
// events will cause the field to show incorrect values. This is because Redux
// form does not know it already formatted the value. In such instances, we
// prevent Redux form from updating the Redux state to avoid this type of bugs.
export const reduxFormPreventUpdate = (event) => event.preventDefault();

export const deepValue = (obj, key, defaultValue) => {
  const reducer = (o, prop) => (o ? o[prop] : o);
  const value = key.split('.').reduce(reducer, obj);
  return getValue(value, defaultValue);
};

// Array sorters.
const getSorterValue = (value, key, defaultValue) => (key.startsWith('shallow')
  ? value
  : deepValue(value, key, defaultValue));

const sorterValues = (a, b, key, defaultValue, beforeSort = identity) => [
  beforeSort(getSorterValue(a, key, defaultValue)),
  beforeSort(getSorterValue(b, key, defaultValue)),
];

export const sorterByBoolean = (key, defaultValue, beforeSort) => (a, b) => {
  const [valueA, valueB] = sorterValues(a, b, key, defaultValue, beforeSort);
  return !!valueA - !!valueB;
};

export const sorterByNumber = (key, defaultValue, beforeSort) => (a, b) => {
  const [valueA, valueB] = sorterValues(a, b, key, defaultValue, beforeSort);
  if (valueA === undefined) return 1;
  if (valueB === undefined) return -1;
  return valueB - valueA;
};

export const sorterByStatus = (key, order, defaultValue, beforeSort) => (a, b) => {
  const [valueA, valueB] = sorterValues(a, b, key, defaultValue, beforeSort);
  return order.indexOf(valueB) - order.indexOf(valueA);
};

export const sorterByString = (key, defaultValue = '', beforeSort) => (a, b) => {
  const [valueA, valueB] = sorterValues(a, b, key, defaultValue, beforeSort);
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

// Images.
export const createImagePreviewURL = (file) => {
  const previewTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ];

  return previewTypes.includes(getFileType(file)) && URL.createObjectURL(file);
};

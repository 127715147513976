import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// TODO: Move resource loader to webpack configuration, see: https://webpack.js.org/concepts/loaders/#configuration
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax, import/extensions
import resBundle from 'i18next-resource-store-loader!./locales';

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React handles XSS by default
    },
    resources: resBundle,
  });

/* istanbul ignore file */
import { optional, required } from 'helpers/routes';

const assets = 'assets';
const constellation = 'c';
const editor = 'editor';
const extractor = 'extractor';
const globe = 'home';
const model = 'm';
const models = 'models';
const place = 'place';
const plan = 'floor-plan';
const pointClouds = 'point-clouds';
const scene = 's';
const settings = 'settings';
const space = 's';
const tour = 'tour';

export const ROUTES_NS = {
  assets,
  constellation,
  editor,
  extractor,
  globe,
  model,
  models,
  place,
  plan,
  pointClouds,
  scene,
  settings,
  space,
  tour,
};

export const ROUTES = {
  editor: required(`/${editor}`, {
    project: optional(':projectId', {
      assets: required(assets, {
        one: optional(':assetId', {
          add: () => 'new',
          view: (assetViewId = ':assetViewId') => assetViewId,
        }),
      }),
      extractor: required(extractor, {
        pointCloud: (pointCloudId = ':pointCloudId') => pointCloudId,
      }),
      models: () => models,
      plan: () => plan,
      pointClouds: () => pointClouds,
      settings: () => settings,
      tour: () => tour,
    }),
  }),
  viewer: required('/', {
    place: (projectId = ':projectId') => `${place}/${projectId}`,
    project: optional(':projectId', {
      space: required(space, optional(':spaceId', {
        model: required(model, optional(':modelId')),
        scene: required(scene, optional(':sceneId')),
      })),
    }),
  }),
};

export const ROUTE_REGISTER = '/register';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_VIEWER = '/';
export const ROUTE_WEBSITE = 'https://helix.re/';
export const ROUTE_WEBSITE_SUPPORT = 'https://support.density.io';
export const ROUTE_WEBSITE_PRIVACY = 'https://helix.re/privacy';

import update from 'immutability-helper';
import { normalize } from 'normalizr';

import {
  DELETE_ATTACHMENT_UPLOAD_COMPLETED,
  RECEIVE_ATTACHMENT,
  RECEIVE_ATTACHMENTS,
  RECEIVE_DELETE_ASSET_VIEW,
  RECEIVE_DELETE_ATTACHMENT,
  RECEIVE_PATCH_ASSET_VIEW,
  RECEIVE_PATCH_ATTACHMENT,
  RECEIVE_POST_ASSET_VIEW,
  RECEIVE_POST_ATTACHMENT,
  RECEIVE_POST_POINT_CLOUD,
  UPDATE_ATTACHMENT_UPLOAD,
  RECEIVE_RESUME_ATTACHMENT,
  UPDATE_ATTACHMENT_UPLOAD_FAILED,
  UPDATE_ATTACHMENT_UPLOAD_PROGRESS,
  UPLOAD_NEW_ATTACHMENT,
} from '../actions/types';
import { getValue } from '../helpers';
import initialState from '../initialState';
import { attachmentSchema } from '../schemas';

const updateStateWithNewAttachment = (state, payload) => update(state, {
  items: {
    entities: state.items.entities.attachments
      ? {
        attachments: {
          [payload.id]: {
            $set: payload,
          },
        },
      }
      : {
        $set: {
          attachments: {
            [payload.id]: payload,
          },
        },
      },
    result: {
      $unshift: [payload.id],
    },
  },
});

export default (state = initialState.attachments, action) => {
  switch (action.type) {
    case RECEIVE_ATTACHMENTS:
      return update(state, {
        items: {
          $set: normalize(action.payload, [attachmentSchema]),
        },
      });
    case RECEIVE_POST_ATTACHMENT:
      return updateStateWithNewAttachment(state, action.payload);
    case RECEIVE_POST_POINT_CLOUD:
      return updateStateWithNewAttachment(state, action.payload.attachment);
    case RECEIVE_ATTACHMENT:
    case RECEIVE_PATCH_ATTACHMENT:
      return update(state, {
        items: {
          entities: {
            attachments: {
              [action.payload.id]: {
                $set: action.payload,
              },
            },
          },
        },
      });
    case RECEIVE_RESUME_ATTACHMENT:
      return update(state, {
        items: {
          entities: {
            attachments: {
              [action.payload.id]: {
                $merge: action.payload,
              },
            },
          },
        },
      });
    case UPLOAD_NEW_ATTACHMENT:
      return update(state, {
        uploads: {
          entities: {
            [action.payload.id]: {
              $set: action.payload,
            },
          },
          result: {
            $unshift: [action.payload.id],
          },
        },
      });
    // TODO: It would be good if this was handled on the server so we do not
    // have to mock the progress as the format might change and break this.
    case UPDATE_ATTACHMENT_UPLOAD_PROGRESS: {
      const attachmentId = action.payload.id;
      const attachment = state.uploads.entities[attachmentId];
      if (!attachment) {
        return state;
      }
      const nextState = {
        $set: {
          ...attachment,
          progress: {
            ...getValue(attachment.progress, {}),
            percentageProgress: action.payload.progress,
          },
        },
      };
      return update(state, {
        items: {
          entities: {
            attachments: {
              [attachmentId]: nextState,
            },
          },
        },
        // TODO: Is it possible to get rid of state.attachments.uploads?
        uploads: {
          entities: {
            [attachmentId]: nextState,
          },
        },
      });
    }
    case RECEIVE_DELETE_ATTACHMENT: {
      const obj = {
        items: {
          entities: {
            $unset: [action.payload.id],
          },
          result: {
            $splice: [[state.items.result.indexOf(action.payload.id), 1]],
          },
        },
      };
      const index = state.uploads.result.indexOf(action.payload.id);
      if (index !== -1) {
        obj.uploads = {
          entities: {
            $unset: [action.payload.id],
          },
          result: {
            $splice: [[index, 1]],
          },
        };
      }

      return update(state, obj);
    }
    case DELETE_ATTACHMENT_UPLOAD_COMPLETED:
      return update(state, {
        uploads: {
          entities: {
            $unset: [action.payload.id],
          },
          result: {
            $splice: [[state.uploads.result.indexOf(action.payload.id), 1]],
          },
        },
      });
    case UPDATE_ATTACHMENT_UPLOAD_FAILED:
      if (!state.uploads.entities[action.payload.id]) {
        return state;
      }
      return update(state, {
        uploads: {
          entities: {
            [action.payload.id]: {
              failed: {
                $set: true,
              },
            },
          },
        },
      });
    case RECEIVE_PATCH_ASSET_VIEW:
      return update(state, {
        items: {
          entities: {
            attachments: {
              [action.payload.attachmentId]: {
                assetViews: {
                  $apply: (views) => {
                    const assetViewIndex = views.findIndex((a) => a.id === action.payload.assetView.id);
                    const head = views.slice(0, assetViewIndex);
                    const tail = views.slice(assetViewIndex + 1);

                    return [
                      ...head,
                      action.payload.assetView,
                      ...tail,
                    ];
                  },
                },
              },
            },
          },
        },
      });
    case RECEIVE_POST_ASSET_VIEW:
      return update(state, {
        items: {
          entities: {
            attachments: {
              [action.payload.attachmentId]: {
                assetViews: {
                  $push: [action.payload.assetView],
                },
              },
            },
          },
        },
      });
    case RECEIVE_DELETE_ASSET_VIEW:
      return update(state, {
        items: {
          entities: {
            attachments: {
              [action.payload.attachmentId]: {
                assetViews: {
                  $apply: (views) => views.filter((v) => v.id !== action.payload.assetViewId),
                },
              },
            },
          },
        },
      });
    case UPDATE_ATTACHMENT_UPLOAD: {
      const attachmentId = action.payload.id;
      const attachment = state.items.entities.attachments[attachmentId];
      if (!attachment) {
        return state;
      }
      const nextState = {
        $set: {
          ...attachment,
          progress: {
            ...getValue(attachment.progress, {}),
            percentageProgress: action.payload.progress,
          },
          status: action.payload.progress,
        },
      };
      return update(state, {
        items: {
          entities: {
            attachments: {
              [attachmentId]: nextState,
            },
          },
        },
        uploads: {
          entities: {
            [attachmentId]: nextState,
          },
        },
      });
    }
    default:
      return state;
  }
};

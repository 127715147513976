import { schema } from 'normalizr';

import { SPACE_SCENE_PARAMS } from 'constants';

export const memberSchema = new schema.Entity('members', undefined, {
  idAttribute: 'userId',
});

export const attachmentSchema = new schema.Entity('attachments');

export const constellationSchema = new schema.Entity('constellations');

export const extractionSchema = new schema.Entity('extractions');

export const modelSchema = new schema.Entity('models');

export const ownerSchema = new schema.Entity('owners');

export const pointCloudSignatureSchema = new schema.Entity('pointCloudSignatures');

export const pointCloudDerivatives = new schema.Entity('pointCloudDerivatives');

export const pointCloudSchema = new schema.Entity('pointClouds', {
  derivatives: [pointCloudDerivatives],
  signatures: [pointCloudSignatureSchema],
});

export const projectsSchema = new schema.Entity('projects');

export const sceneSchema = new schema.Entity('scenes', {}, {
  processStrategy: (value) => {
    const {
      spaceId,
      ...rest
    } = value;
    SPACE_SCENE_PARAMS.forEach((param) => {
      delete rest[param];
    });
    return rest;
  },
});

export const spaceSchema = new schema.Entity('spaces', {}, {
  processStrategy: (value, parent) => {
    if (!value.scenes) {
      return {
        ...value,
        scenes: parent.scenes.filter((scene) => scene.spaceId === value.id).reduce((acc, curr) => {
          const spaceScene = {};
          SPACE_SCENE_PARAMS.forEach((param) => {
            spaceScene[param] = curr[param];
          });
          acc[curr.id] = spaceScene;
          return acc;
        }, {}),
      };
    }
    return value;
  },
});

export const projectSchema = new schema.Entity('projects', {
  models: [modelSchema],
  spaces: [spaceSchema],
  // eslint-disable-next-line sort-keys
  scenes: [sceneSchema],
});

export const uploadSchema = new schema.Entity('uploads');

import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const getSelectorParams = (...args) => {
  const resultFunc = args.pop();
  const [selectors] = args;

  const numberOfArgs = selectors.pop();
  const argSelectors = [...Array(numberOfArgs)]
    .map((_, index) => (...values) => values[index + 1]);

  return [
    [...selectors, ...argSelectors],
    resultFunc,
  ];
};

export const createCachedSelectorWithArgs = (...args) => createCachedSelector(
  ...getSelectorParams(...args),
);

export const createSelectorWithArgs = (...args) => createSelector(
  ...getSelectorParams(...args),
);

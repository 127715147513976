import React from 'react';

import IconFilter from 'assets/svg/IconFilter';

import s from './index.scss';

export default function Space() {
  return (
    <button
      className={s.button}
      type="button"
    >
      <IconFilter
        className={s.icon}
        color="currentColor"
      />
    </button>
  );
}

import { createSelector } from 'reselect';
import { createSelectorWithArgs } from 'helpers';
import { getSceneEntities, getSpaceIds } from './project';
import { getEditorFilters } from './editor';

export const getConstellationEntities = (state) => state.constellations.items.entities.constellations;
export const getConstellationIds = (state) => state.constellations.items.result;

const filterConstellation = (constellation, filters) => {
  if (filters.mapped === false && constellation.isPlaced) {
    return false;
  }
  if (filters.mapped === true && !constellation.isPlaced) {
    return false;
  }
  return true;
};

export const getConstellations = createSelector(
  getConstellationIds,
  getConstellationEntities,
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getConstellationsBySpaceId = createSelector(
  getSpaceIds,
  getConstellations,
  (spaceIds, constellations) => spaceIds.reduce((acc, spaceId) => {
    acc[spaceId] = constellations.filter((constellation) => constellation.spaceId === spaceId);
    return acc;
  }, { all: constellations }),
);

export const getConstellationById = createSelectorWithArgs(
  [getConstellationEntities, 1],
  (entities, id) => entities && entities[id],
);

export const getConstellationScenes = createSelectorWithArgs(
  [getSceneEntities, 1],
  (sceneEntities, constellation) => constellation.scenes.map((scene) => sceneEntities[scene.id]),
);

export const getFilteredConstellationsBySpaceId = createSelector(
  getSpaceIds,
  getConstellations,
  getEditorFilters,
  (spaceIds, constellations, filters) => spaceIds.reduce((acc, spaceId) => {
    acc[spaceId] = constellations.filter((constellation) => constellation.spaceId === spaceId && filterConstellation(constellation, filters));
    return acc;
  }, { all: constellations.filter((constellation) => filterConstellation(constellation, filters)) }),
);

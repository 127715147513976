export const getRectangleCorners = (center, a, b) => {
  const aHalf = a / 2;
  const bHalf = b / 2;
  return [{
    x: center.x - aHalf,
    y: center.y - bHalf,
  }, {
    x: center.x + aHalf,
    y: center.y - bHalf,
  }, {
    x: center.x + aHalf,
    y: center.y + bHalf,
  }, {
    x: center.x - aHalf,
    y: center.y + bHalf,
  }];
};

export const metersToMillimeters = (value) => value * 1000;

export const millimetersToMeters = (value) => value / 1000;

export const radToDeg = (value) => value * (180 / Math.PI);

export const rotate2DPoint = (point, origin, angle) => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const translated = {
    x: point.x - origin.x,
    y: point.y - origin.y,
  };
  const rotated = {
    x: (translated.x * cos) - (translated.y * sin),
    y: (translated.y * cos) + (translated.x * sin),
  };
  return {
    x: rotated.x + origin.x,
    y: rotated.y + origin.y,
  };
};

export const rgbToHex = (red, green, blue) => {
  // eslint-disable-next-line no-bitwise
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return `#${(0x1000000 + rgb).toString(16).slice(1)}`;
};

export const splitArrayIntoChunksOfLen = (arr, len) => {
  const chunks = [];
  let i = 0;
  const n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
};

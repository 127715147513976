import { replace } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import {
  getGoogleAuthProvider,
  getMicrosoftAuthProvider,
  getSAMLAuthProvider,
  getRedirectResult,
  getSignInMethodsForEmail,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithEmailLink,
  signInWithPassword,
  signInWithRedirect,
  signOut,
} from 'actions/auth';
import { setPathDemoProject } from 'actions/router';
import { EMAIL_REGEX, PropTypes, ROUTE_VIEWER } from 'constants';
import { getValue, isUserLoggedIn, parseSearch } from 'helpers';
import { getUser } from 'selectors/user';

import ModalReportIssue from 'components/UI/Modal/ReportIssue';
import VideoGuide from 'components/common/VideoGuide';

import { LoginLayout, PAGE, PROVIDER } from './LoginLayout';

import loginVideo from '../../../assets/login-video.mp4';

import s from '../auth.scss';

const URL_KEY_EMAIL = 'akey';

function Login(props) {
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState();
  const [provider, setProvider] = useState();
  const [visibleContact, setVisibleContact] = useState(false);
  const [visibleGuide, setVisibleGuide] = useState(false);

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setError,
  } = useForm();

  const google = 'google.com';
  const microsoft = 'microsoft.com';

  const isExternalProvider = (method) => [google, microsoft].includes(method);

  const goToProvider = (method, emailAddress) => {
    if (isExternalProvider(method)) {
      setEmail(emailAddress);
      setPage(PAGE.PROVIDER);

      if (method === google) {
        setProvider(PROVIDER.google);
      } else {
        setProvider(PROVIDER.microsoft);
      }
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(window.location.href)) {
      const encodedEmail = parseSearch(window.location.search)[URL_KEY_EMAIL];
      const urlEmail = atob(getValue(encodedEmail, ''));

      if (urlEmail) {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(urlEmail, window.location.href)
          .then(() => {
            // Remove the sign-in link parameters.
            props.replace(ROUTE_VIEWER);
            setPage();
          })
          .catch((error) => {
            message.error(error.message);
            // eslint-disable-next-line no-console
            console.error(error);
          });
      }
    } else if (!isUserLoggedIn(props.user)) {
      signInAnonymously();
    }

    getRedirectResult()
      .catch((error) => {
        setLoading();

        if (error.code === 'auth/account-exists-with-different-credential') {
          getSignInMethodsForEmail(error.email)
            .then((methods) => {
              goToProvider(methods[0], error.email);
            });
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    clearError();

    const onError = (error) => {
      setLoading(false);
      if (error.code === 'auth/user-not-found') {
        setError('email', 'invalid', t('form.email.invalid'));
        setError('password', 'invalid', t('form.password.invalid'));
      } else if (error.code === 'auth/wrong-password') {
        setError('password', 'invalid', t('form.password.invalid'));
      } else {
        message.error(error.message);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (data.password) {
      setLoading(PROVIDER.emailLink);
      return signInWithPassword(email, data.password)
        .catch((error) => {
          onError(error);
        });
    }

    if (!EMAIL_REGEX.test(data.email)) {
      setError('email', 'invalid', t('auth.emailIsNotValid'));
      return undefined;
    }

    return getSignInMethodsForEmail(data.email)
      .then(async (methods) => {
        const [method] = methods;

        if (!isExternalProvider(method)) {
          setLoading(PROVIDER.emailLink);

          try {
            if (method === PROVIDER.emailLink) {
              const urlEmail = btoa(data.email);
              const url = `${window.location.href}?${URL_KEY_EMAIL}=${urlEmail}`;

              await sendSignInLinkToEmail(data.email, url);
              setEmail(data.email);
              setPage(PAGE.SENT);
              setLoading(false);
            } else {
              setEmail(data.email);
              setPage(PAGE.PASSWORD);
              setLoading(false);
            }
          } catch (error) {
            onError(error);
          }
        } else {
          goToProvider(method, data.email);
        }
      });
  };

  const onCancelContact = () => setVisibleContact(false);

  const onCancelGuide = () => setVisibleGuide(false);

  const onClickBack = () => {
    setEmail();
    setPage();
    setLoading();
  };

  const onClickContact = () => setVisibleContact(true);

  const onClickGoogle = () => props.signOut(true)
    .then(() => {
      setLoading(PROVIDER.google);
      signInWithRedirect(getGoogleAuthProvider());
    });

  const onClickHelp = () => {
    setPage(PAGE.HELP);
  };

  const onClickMicrosoft = () => props.signOut(true)
    .then(() => {
      setLoading(PROVIDER.microsoft);
      signInWithRedirect(getMicrosoftAuthProvider());
    });

  const onClickSAML = () => props.signOut(true).then(() => {
    setLoading(PROVIDER.saml);
    signInWithRedirect(getSAMLAuthProvider());
  });

  const onClickWatchGuide = () => setVisibleGuide(true);

  // We are trying to load a public twin, don't show Login Form
  if (props.isLoadingPublicTwin) {
    return null;
  }

  return (
    <div className={s.layout}>
      <div className={s.layoutLeft}>
        <LoginLayout
          email={email}
          errors={errors}
          loading={loading}
          onBack={onClickBack}
          onContact={onClickContact}
          onGoogle={onClickGoogle}
          onGuide={onClickWatchGuide}
          onHelp={onClickHelp}
          onMicrosoft={onClickMicrosoft}
          onSAML={onClickSAML}
          onSubmit={handleSubmit(onSubmit)}
          page={page}
          provider={provider}
          register={register}
          setPathDemoProject={props.setPathDemoProject}
        />
        <ModalReportIssue
          onCancel={onCancelContact}
          visible={visibleContact}
        />
        <VideoGuide
          onCancel={onCancelGuide}
          visible={visibleGuide}
        />
      </div>
      <div className={s.layoutRight}>
        <video autoPlay className={s.loginVideo} loop muted>
          <source src={loginVideo} type="video/mp4" />
          <track kind="captions" srcLang="en" />
        </video>
      </div>
    </div>
  );
}

Login.propTypes = {
  isLoadingPublicTwin: PropTypes.bool,
  replace: PropTypes.func.isRequired,
  setPathDemoProject: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  user: PropTypes.User.isRequired,
};

export const PureTestComponent = Login;

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  replace,
  setPathDemoProject,
  signOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import s from './index.scss';

export default function Scrollbar(props) {
  const {
    className,
    disableHorizontal,
    ...rest
  } = props;

  const overrideStyles = !disableHorizontal ? {} : {
    overflowX: 'hidden',
  };

  const thumbStyles = {
    backgroundColor: '#626262',
    borderRadius: '3px',
  };

  return (
    <div className={classNames(s.root, className)}>
      <Scrollbars
        renderThumbVertical={({ style }) => (
          <div style={{ ...style, ...thumbStyles }} />
        )}
        renderView={(scrollbarProps) => (
          <div
            {...scrollbarProps}
            style={{
              ...scrollbarProps.style,
              ...overrideStyles,
            }}
          />
        )}
        {...rest}
      />
    </div>
  );
}

Scrollbar.propTypes = {
  className: PropTypes.string,
  disableHorizontal: PropTypes.bool,
};

Scrollbar.defaultProps = {
  className: undefined,
  disableHorizontal: false,
};

import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { PropTypes } from 'constants';
import { getValue } from 'helpers';

import IconCaretRight from 'assets/svg/IconCaretRight';

import LabelError from './LabelError';
import LabelInput, { INPUT_PLACEMENT } from './LabelInput';

import s from './index.scss';

const INPUT_TYPE = {
  EMAIL: 'email',
  LABEL: 'label',
  PASSWORD: 'password',
  SELECT: 'select',
  TEL: 'tel',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

export default function Input(props, ref) {
  const {
    className,
    dark,
    disabled,
    error,
    fill,
    id,
    label,
    options,
    placement,
    type,
    ...rest
  } = props;

  const isSelect = type === INPUT_TYPE.SELECT;

  const inputProps = {
    className: s.input,
    disabled: isSelect && !options.length ? true : disabled,
    id,
    ref,
    ...rest,
  };

  const getComponent = () => {
    if (type === INPUT_TYPE.LABEL) {
      return (
        <div className={s.labelWrapper}>
          {getValue(rest.value, rest.placeholder)}
        </div>
      );
    }

    if (isSelect) {
      return (
        <div className={s.selectWrapper}>
          <select {...inputProps}>
            {options.map((option, index) => (
              <option
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
          <IconCaretRight className={s.arrow} />
        </div>
      );
    }

    return type === INPUT_TYPE.TEXTAREA
      ? <textarea {...inputProps} />
      : <input type={type} {...inputProps} />;
  };

  return (
    <label
      className={classNames(s.label, className, {
        [s.dark]: dark,
        [s.error]: error,
        [s.fill]: fill,
        [s.left]: placement === INPUT_PLACEMENT.LEFT,
        [s.light]: !dark,
      })}
      htmlFor={id}
    >
      <LabelInput
        placement={placement}
        value={label}
      />
      {getComponent()}
      <LabelError value={error} />
    </label>
  );
}

// eslint-disable-next-line no-func-assign
Input = forwardRef(Input);

Input.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fill: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.SelectOption),
  placeholder: PropTypes.string,
  placement: PropTypes.oneOfMap(INPUT_PLACEMENT),
  type: PropTypes.oneOfMap(INPUT_TYPE),
  value: PropTypes.string,
};

Input.defaultProps = {
  className: undefined,
  dark: false,
  disabled: false,
  error: undefined,
  fill: false,
  id: undefined,
  label: undefined,
  options: [],
  placeholder: undefined,
  placement: INPUT_PLACEMENT.TOP,
  type: INPUT_TYPE.TEXT,
  value: undefined,
};

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon, Popover as AntPopover } from 'antd';

import { PropTypes } from 'constants';
import { getPopupContainer, noop, stopPropagation } from 'helpers';
import useKeyNavigation from 'components/UI/hooks/useKeyNavigation';

import Menu from 'components/UI/Menu';
import Tooltip from 'components/UI/Tooltip';

import s from './index.scss';

export default function Popover(props) {
  const {
    arrow,
    buttonClassName,
    children,
    content,
    menu,
    onChangeVisible,
    overlayClassName,
    refButton,
    snapToTableRowId,
    title,
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);

  const refDefaultButton = useRef();
  const refPopup = useRef();

  const onVisibleChange = (nextState) => {
    setVisible(nextState);
    onChangeVisible(nextState);
  };

  useKeyNavigation(visible, /* istanbul ignore next */ () => refPopup.current && refPopup.current.getPopupDomNode(), {
    onClose: () => onVisibleChange(false),
  });

  const onClick = (event) => {
    // Prevent triggering the `onRow` form method.
    event.domEvent.stopPropagation();

    setVisible(false);
  };

  const snapToElement = () => {
    const ref = refButton || refDefaultButton;

    return ref.current
      || getPopupContainer(`[data-row-key="${snapToTableRowId}"]`);
  };

  const body = children || (
    <button
      className={classNames(s.button, buttonClassName, visible ? s.active : '')}
      ref={refDefaultButton}
      type="button"
    >
      <Icon type="ellipsis" />
    </button>
  );

  const contentPopover = menu
    ? (
      <Menu
        items={menu}
        onClick={onClick}
      />
    )
    : content;

  const maybeTooltipBody = title
    ? (
      <Tooltip title={title}>
        {body}
      </Tooltip>
    )
    : body;

  return (
    <AntPopover
      content={contentPopover}
      destroyTooltipOnHide
      getPopupContainer={snapToElement}
      onClick={stopPropagation}
      onVisibleChange={onVisibleChange}
      overlayClassName={classNames(s.popover, overlayClassName, {
        [s.arrow]: arrow,
      })}
      ref={refPopup}
      trigger="click"
      visible={visible}
      {...rest}
    >
      {maybeTooltipBody}
    </AntPopover>
  );
}

Popover.propTypes = {
  arrow: PropTypes.bool,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  menu: PropTypes.MenuItems,
  onChangeVisible: PropTypes.func,
  overlayClassName: PropTypes.string,
  refButton: PropTypes.Ref,
  snapToTableRowId: PropTypes.string,
  title: PropTypes.string,
};

Popover.defaultProps = {
  arrow: false,
  onChangeVisible: noop,
};

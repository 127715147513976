import { SPACE_STATUS, SPACE_STATUS_LABELS } from '../constants';
import { getUrlWithAccessToken } from './misc';

export const getSelectOptionsFromSpaceIds = (spaceIds, spaceEntities, excludeSpaceId) => spaceIds
  .filter((id) => id !== excludeSpaceId)
  .map((id) => ({
    label: spaceEntities[id].name,
    value: id,
  }));

export const getSpaceFloorPlanUrl = (space, idToken) => {
  let url = '';
  const { floorPlan } = space;
  if (floorPlan) {
    url = floorPlan.attachmentData.downloadLink;
  }
  return getUrlWithAccessToken(url, idToken);
};

export const getSpaceStatus = (space) => {
  const value = space.deliveryStatus;
  const defaultValue = SPACE_STATUS.UNSCANNED;
  return value || defaultValue;
};

export const getSpaceStatusLabel = (space, viewer = false) => {
  let status = getSpaceStatus(space);
  if (viewer && status === SPACE_STATUS.IN_QA) {
    status = SPACE_STATUS.IN_PROGRESS;
  }
  return SPACE_STATUS_LABELS[status];
};

export const isDifferentSpace = (currentSpaceIds = [], pathSpaceid) => !currentSpaceIds.includes(pathSpaceid);

export const isSpaceDelivered = (space) => {
  const status = getSpaceStatus(space);
  return status === SPACE_STATUS.DELIVERED;
};

export const isSpaceUnscanned = (space) => {
  const status = getSpaceStatus(space);
  return status === SPACE_STATUS.UNSCANNED;
};

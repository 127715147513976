import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { RECEIVE_PROJECTS } from '../actions/types';
import initialState from '../initialState';
import { projectsSchema } from '../schemas';

export default (state = initialState.projects, action) => {
  switch (action.type) {
    case RECEIVE_PROJECTS: {
      return update(state, {
        items: {
          $set: normalize(action.payload, [projectsSchema]),
        },
      });
    }
    default:
      return state;
  }
};

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <path
      d="M12.6359 23.8393H8.94096C8.66482 23.8393 8.44096 23.6155 8.44096 23.3393V14.6585C8.44096 14.3824 8.2171 14.1585 7.94096 14.1585H7.41647C6.94485 14.1585 6.73546 13.5655 7.10254 13.2694L14.6862 7.15168C14.8694 7.0039 15.1309 7.0039 15.3141 7.15168L22.8978 13.2694C23.2648 13.5655 23.0555 14.1585 22.5838 14.1585H22.0592C21.7831 14.1585 21.5592 14.3824 21.5592 14.6585V23.3393C21.5592 23.6155 21.3354 23.8393 21.0592 23.8393H17.3646M12.6359 23.8393V18.5154C12.6359 18.2393 12.8597 18.0154 13.1359 18.0154H16.8646C17.1407 18.0154 17.3646 18.2393 17.3646 18.5154V23.8393M12.6359 23.8393H17.3646"
      stroke="currentColor"
    />
  </Svg>
);

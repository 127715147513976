import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    size={21}
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      d="M1 1h9v9H1z"
      fill="#f25022"
    />
    <path
      d="M1 11h9v9H1z"
      fill="#00a4ef"
    />
    <path
      d="M11 1h9v9h-9z"
      fill="#7fba00"
    />
    <path
      d="M11 11h9v9h-9z"
      fill="#ffb900"
    />
  </Svg>
);

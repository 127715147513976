import {
  ADD_TO_EDITOR_SELECTED_LINKS,
  ADD_TO_EDITOR_SELECTED_SCENE_IDS,
  CLOSE_EDITOR_PANELS,
  CLOSE_LAUNCHER,
  OPEN_EDITOR_PANELS,
  REMOVE_FROM_EDITOR_SELECTED_LINKS,
  REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS,
  SET_EDITOR_FILTER,
  SET_EDITOR_FILTERS,
  SET_EDITOR_IS_VISIBLE_SIDEBAR,
  SET_EDITOR_PREVIEW,
  SET_EDITOR_SELECTED_SCENE_CURRENT_TIME,
  SET_EDITOR_SELECTED_SCENE_DURATION,
  SET_EDITOR_SELECTED_LINKS,
  SET_EDITOR_SELECTED_MODEL_CARD,
  SET_EDITOR_SELECTED_SCENE_IDS,
  SET_EDITOR_SELECTED_SCENE_IDS_TIME,
  SET_SHOULD_FOCUS_SCENE_SETTINGS,
  SET_TOUR_EDITOR_FOCUSED_CONSTELLATION,
  TOGGLE_LAUNCHER,
} from 'actions/types';

export const setEditorFilter = (filter, value) => ({
  payload: {
    filter,
    value,
  },
  type: SET_EDITOR_FILTER,
});

export const setEditorFilters = (payload) => ({
  payload,
  type: SET_EDITOR_FILTERS,
});

export const setEditorPreview = (payload) => ({
  payload,
  type: SET_EDITOR_PREVIEW,
});

export const setEditorIsVisibleSidebar = (payload) => ({
  payload,
  type: SET_EDITOR_IS_VISIBLE_SIDEBAR,
});

export const addToEditorSelectedLinks = (id, otherId) => ({
  payload: {
    id,
    otherId,
  },
  type: ADD_TO_EDITOR_SELECTED_LINKS,
});

export const removeFromEditorSelectedLinks = (id, otherId) => ({
  payload: {
    id,
    otherId,
  },
  type: REMOVE_FROM_EDITOR_SELECTED_LINKS,
});

export const setEditorSelectedLinks = (payload) => ({
  payload,
  type: SET_EDITOR_SELECTED_LINKS,
});

export const addToEditorSelectedSceneIds = (payload) => ({
  payload,
  type: ADD_TO_EDITOR_SELECTED_SCENE_IDS,
});

export const removeFromEditorSelectedSceneIds = (payload) => ({
  payload,
  type: REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS,
});

export const setEditorSelectedSceneIds = (payload) => ({
  payload,
  type: SET_EDITOR_SELECTED_SCENE_IDS,
});

export const setEditorSelectedSceneIdsTime = (payload) => ({
  payload,
  type: SET_EDITOR_SELECTED_SCENE_IDS_TIME,
});

export const setEditorSelectedSceneCurrentTime = (payload) => ({
  payload,
  type: SET_EDITOR_SELECTED_SCENE_CURRENT_TIME,
});

export const setEditorSelectedSceneDuration = (payload) => ({
  payload,
  type: SET_EDITOR_SELECTED_SCENE_DURATION,
});

export const openEditorPanels = (payload) => ({
  payload,
  type: OPEN_EDITOR_PANELS,
});

export const closeEditorPanels = (payload) => ({
  payload,
  type: CLOSE_EDITOR_PANELS,
});

export const setShouldFocusSceneSettings = (payload) => ({
  payload,
  type: SET_SHOULD_FOCUS_SCENE_SETTINGS,
});

export const setEditorSelectedModelCard = (modelId, assetViewId) => ({
  payload: {
    assetViewId,
    modelId,
  },
  type: SET_EDITOR_SELECTED_MODEL_CARD,
});

export const closeLauncher = () => ({
  type: CLOSE_LAUNCHER,
});

export const toggleLauncher = () => ({
  type: TOGGLE_LAUNCHER,
});

export const focusConstellation = (constellationId, sceneId) => ({
  payload: {
    constellationId,
    sceneId,
  },
  type: SET_TOUR_EDITOR_FOCUSED_CONSTELLATION,
});

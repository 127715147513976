import { createSelector } from 'reselect';

import { createCachedSelectorWithArgs } from 'helpers';

export const getEditorFilters = (state) => state.editor.filters;

export const getEditorSelectedSceneIds = (state) => state.editor.selectedSceneIds;
export const getEditorSelectedLinks = (state) => state.editor.selectedLinks;
export const getEditorSelectedModelCard = (state) => state.editor.selectedModelCard;
export const getEditorCopiedAnnotation = (state) => state.editor.copiedAnnotation;
export const getEditorSelectedSceneTime = (state) => state.editor.selectedSceneIdsTime;
export const getEditorSelectedSceneCurrentTime = (state) => state.editor.selectedSceneCurrentTime;
export const getEditorSelectedSceneDuration = (state) => state.editor.selectedSceneDuration;

export const getEditorSelectedSceneIdObject = createSelector(
  getEditorSelectedSceneIds,
  (ids) => ids.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {}),
);

export const getEditorSelectedLinksEitherDirection = createSelector(
  getEditorSelectedLinks,
  (links) => {
    const linksEitherDirection = {};
    Object.keys(links).forEach((k) => {
      Object.keys(links[k]).forEach((otherK) => {
        if (links[k][otherK]) {
          linksEitherDirection[k] = {
            ...linksEitherDirection[k],
            [otherK]: true,
          };
          linksEitherDirection[otherK] = {
            ...linksEitherDirection[otherK],
            [k]: true,
          };
        }
      });
    });
    return linksEitherDirection;
  },
);

export const getEditorSelectedSceneIdsForSceneMenu = createCachedSelectorWithArgs(
  [getEditorSelectedSceneIds, 1],
  (ids, sceneId) => (ids.includes(sceneId)
    ? ids
    : [sceneId]),
)((_, sceneId) => sceneId);

export const getEditorIsPreview = (state) => state.editor.isPreview;

export const getEditorIsVisibleSidebar = (state) => state.editor.isVisibleSidebar;

export const getOpenPanels = /* istanbul ignore next */ (state) => state.editor.openPanels;

export const getFocusedConstellation = /* istanbul ignore next */ (state) => state.editor.focusedConstellation;

import React from 'react';
import useForm from 'react-hook-form';
import { connect } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { reportIssue } from '../../../../actions/support';
import { PropTypes } from '../../../../constants';
import { getFormError } from '../../../../helpers';
import { getUser } from '../../../../selectors/user';

import Button from '../../Button';
import Input from '../../Input';
import Modal from '..';

import s from './index.scss';

function ModalReportIssue(props) {
  const { t } = useTranslation();

  const {
    errors,
    formState,
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      email: props.user.email,
    },
  });

  const onSubmit = (data) => {
    const params = {
      contactEmail: data.email,
      subject: data.subject,
    };
    if (data.phone) {
      params.phoneNumber = data.phone;
    }
    if (data.message) {
      params.description = data.message;
    }

    return props.reportIssue(params)
      .then(() => {
        message.success(t('modal.reportIssue.success'));
        props.onCancel();
      })
      .catch(() => {
        message.error(t('modal.reportIssue.error'));
      });
  };

  return (
    <Modal
      footer={(
        <div className={s.footerActions}>
          <Button
            className={s.button}
            dark
            onClick={props.onCancel}
            secondary
          >
            {t('words.cancel')}
          </Button>
          <Button
            className={s.button}
            dark
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('words.send')}
          </Button>
        </div>
      )}
      onCancel={props.onCancel}
      title={t('modal.reportIssue.title')}
      visible={props.visible}
      width={600}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          {t('modal.reportIssue.text')}
        </p>
        <Input
          className={s.input}
          dark
          error={getFormError(errors, 'email')}
          label={t('form.email.contactLabel')}
          name="email"
          placeholder={t('form.email.placeholder')}
          ref={register({ required: t('form.email.validate') })}
          type="email"
        />
        <Input
          className={s.input}
          dark
          error={getFormError(errors, 'subject')}
          label={t('form.subject.label')}
          name="subject"
          placeholder={t('form.subject.placeholder')}
          ref={register({ required: t('form.subject.validate') })}
          type="text"
        />
        <Input
          className={s.input}
          dark
          error={getFormError(errors, 'phone')}
          label={t('form.phone.label')}
          name="phone"
          placeholder={t('form.phone.placeholder')}
          ref={register}
          type="tel"
        />
        <Input
          className={s.textarea}
          dark
          error={getFormError(errors, 'message')}
          label={t('modal.reportIssue.body')}
          name="message"
          ref={register}
          type="textarea"
        />
      </form>
    </Modal>
  );
}

ModalReportIssue.propTypes = {
  onCancel: PropTypes.func.isRequired,
  reportIssue: PropTypes.func.isRequired,
  user: PropTypes.User.isRequired,
  visible: PropTypes.bool.isRequired,
};

export const PureTestComponent = ModalReportIssue;

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  reportIssue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalReportIssue);

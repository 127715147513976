import { useEffect, useState } from 'react';

import { getFirstAndLastEntry, getFocusableElementsSafe } from '../../../helpers';

export default function useFocusFirstElement(enabled, getNode, delay = 0) {
  const [savedElement, setSavedElement] = useState();

  const focusFirstElement = () => {
    setSavedElement(document.activeElement);

    const elements = getFocusableElementsSafe(getNode());
    const [first] = getFirstAndLastEntry(elements);
    if (first) {
      first.focus();
    }
  };

  const focusSavedElement = () => {
    if (savedElement) {
      savedElement.focus();
    }
    setSavedElement();
  };

  useEffect(() => {
    if (!enabled) {
      focusSavedElement();
      return;
    }

    setTimeout(focusFirstElement, delay);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
}

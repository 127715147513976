import { PUBLISH_STATUS } from 'constants';

const getStatus = (project) => project && project.publication
  && project.publication.status;

export const getPublishStatus = (project) => {
  const value = getStatus(project);
  const defaultValue = PUBLISH_STATUS.UNPUBLISHED;
  return value || defaultValue;
};

export const isPublishInProgress = (project) => {
  const status = getPublishStatus(project);
  return status === PUBLISH_STATUS.IN_PROGRESS;
};

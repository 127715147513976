export const SCENE_HOTSPOT_ACTION = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  MOVE: 'MOVE',
  VIEW: 'VIEW',
};

export const SCENE_HOTSPOT_TYPE = {
  ANNOTATION: 'ANNOTATION',
};

export const SCENE_STATUS = {
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  REDACTING: 'REDACTING',
  REPLACING: 'REPLACING',
  SUCCESS: 'SUCCESS',
};

export const SCENE_TYPE = {
  SCENE_2D: 'SCENE_2D',
  SCENE_360: 'SCENE_360',
  SCENE_VIDEO: 'SCENE_VIDEO',
};

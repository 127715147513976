import React from 'react';
import Svg from '.';

const outerRectStyle = {
  fill: 'none',
  stroke: '#FFF',
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter',
  strokeMiterlimit: 10,
  strokeOpacity: 1,
  strokeWidth: 8,
};

const innerRectStyle = {
  fill: '#FFF',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: '#FFF',
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter',
  strokeMiterlimit: 10,
  strokeOpacity: 1,
  strokeWidth: 8,
};

export default /* istanbul ignore next */ (props) => (
  <Svg
    height={16}
    viewBox="0 0 13 16"
    width={13}
    {...props}
  >
    <g id="surface1">
      <path d="M 4.002521 4.012248 L 139.227479 4.012248 L 139.227479 171.687752 L 4.002521 171.687752 Z M 4.002521 4.012248" style={outerRectStyle} transform="matrix(0.0907631,0,0,0.0907631,0,0.0264609)" />
      <path d="M 4.002521 145.004279 L 139.227479 145.004279 L 139.227479 171.687752 L 4.002521 171.687752 Z M 4.002521 145.004279" style={innerRectStyle} transform="matrix(0.0907631,0,0,0.0907631,0,0.0264609)" />
    </g>
  </Svg>
);

import { message } from 'antd';

import { ACCEPT_FLOOR_PLANS_SIZE_LIMIT } from 'constants';

export const isDefined = (value) => ![null, undefined].includes(value);

export const isValidFloorPlan = (file, t) => {
  if (file.size > ACCEPT_FLOOR_PLANS_SIZE_LIMIT) {
    message.error({
      content: t('editor.tour.plan.upload.error'),
      icon: '😢',
    });
    return false;
  }
  return true;
};

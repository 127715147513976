import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path clipRule="evenodd" d="M6.49964 4.41591C5.49284 4.91782 4.50132 5.8181 3.84285 6.84975L2.15698 5.77372C2.99852 4.45524 4.257 3.29916 5.60734 2.62599C6.93549 1.96389 8.56636 1.67778 9.94881 2.5201C11.0443 3.1876 11.5692 4.15336 11.5723 5.25545C11.5751 6.25931 11.1449 7.26648 10.6302 8.14805C9.84473 9.49355 8.64302 10.888 7.7243 11.9541C7.44622 12.2768 7.19407 12.5694 6.98724 12.8214C6.7163 13.1516 6.35506 13.6212 6.06625 14.1465C5.76968 14.6859 5.6017 15.1911 5.60726 15.6089C5.61192 15.9599 5.73476 16.3165 6.21964 16.6689C6.75776 17.06 7.76113 17.4529 9.54199 17.6564C10.5303 17.7693 11.4217 17.749 12.2238 17.6244C11.7406 16.9692 11.3563 16.2417 11.1213 15.4473C10.702 14.0296 10.7798 12.4683 11.5186 10.8478C12.8824 7.85644 14.7529 6.4263 16.6659 6.38516C18.5295 6.34509 20.0326 7.65742 20.4645 9.2362C20.7011 10.1012 20.6392 11.2398 20.3464 12.385C20.0476 13.5539 19.4837 14.829 18.6093 15.9863C17.9199 16.8986 17.0421 17.7303 15.9641 18.3769C17.5717 19.4119 19.4972 20 20.9999 20V22C18.7366 22 15.9217 21.0018 13.8443 19.3066C12.5407 19.6939 11.0347 19.8399 9.31499 19.6435C7.38156 19.4226 5.98324 18.9695 5.0438 18.2867C4.05112 17.5652 3.62053 16.6201 3.60743 15.6354C3.59523 14.7176 3.94515 13.8532 4.31369 13.1829C4.68999 12.4985 5.14105 11.9184 5.44117 11.5527C5.70584 11.2302 6.00096 10.8873 6.30899 10.5295C7.20944 9.48351 8.22018 8.30937 8.90304 7.1397C9.35824 6.35998 9.57363 5.72866 9.5723 5.26114C9.57126 4.89186 9.44208 4.55335 8.90817 4.22804C8.36205 3.8953 7.52863 3.90295 6.49964 4.41591ZM14.2999 17.0002C15.462 16.4557 16.3512 15.6572 17.0136 14.7806C17.7155 13.8516 18.1703 12.8223 18.4087 11.8896C18.6532 10.9334 18.6474 10.1734 18.5354 9.7639C18.3244 8.99254 17.5775 8.36602 16.7089 8.3847C15.8898 8.40231 14.546 9.02854 13.3384 11.6775C12.7915 12.8771 12.7621 13.9431 13.0392 14.88C13.2628 15.6359 13.6995 16.3509 14.2999 17.0002Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

/* eslint-disable react/prop-types */
import React from 'react';

import s from './index.scss';

export function Divider({ label }) {
  return (
    <div className={s.container}>
      <div className={s.label}>
        {label || 'or use'}
      </div>
    </div>
  );
}

import { useEffect } from 'react';

import { KEY_CODES } from '../../../constants';
import {
  getFirstAndLastEntry,
  getFocusableElementsSafe,
  getValue,
  noop,
} from '../../../helpers';
import useFocusFirstElement from './useFocusFirstElement';

export default function useKeyNavigation(enabled, getNode, options = {}) {
  const focusDelay = getValue(options.focusFirstDelay, 200);
  const focusFirst = getValue(options.focusFirstElement, true);
  const onClose = getValue(options.onClose, noop);

  const onArrow = (event, elements) => {
    const [first, last] = getFirstAndLastEntry(elements);
    const index = elements.indexOf(event.target);

    if (index === -1) {
      return null;
    }

    if (KEY_CODES.ARROW_UP === event.which) {
      return first === event.target
        ? last
        : elements[index - 1];
    }

    return last === event.target
      ? first
      : elements[index + 1];
  };

  const onEsc = () => onClose();

  const onTab = (event, elements) => {
    const [first, last] = getFirstAndLastEntry(elements);
    const backwards = event.shiftKey;

    if (first === event.target && backwards) {
      return last;
    }
    if (last === event.target && !backwards) {
      return first;
    }
    return null;
  };

  const getKeyHandler = (event) => {
    const key = event.which;
    switch (key) {
      case KEY_CODES.ARROW_DOWN:
      case KEY_CODES.ARROW_UP:
        return onArrow;
      case KEY_CODES.ESCAPE:
        return onEsc;
      case KEY_CODES.TAB:
        return onTab;
      default:
        return noop;
    }
  };

  const getNextElement = (event) => {
    const elements = getFocusableElementsSafe(getNode());
    const handler = getKeyHandler(event);
    return handler(event, elements);
  };

  const onKeyDown = (event) => {
    const nextElement = getNextElement(event);
    if (nextElement) {
      event.preventDefault();
      nextElement.focus();
    }
  };

  const enabledFocusFirst = focusFirst ? enabled : false;
  useFocusFirstElement(enabledFocusFirst, getNode, focusDelay);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
}

export const FETCH_AUTHORIZATION = {
  HEADERS: 'headers',
  URL: 'url',
};

export const FETCH_PARSER = {
  BLOB: 'blob',
  JSON: 'json',
};

export const formHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
};

export const jsonHeaders = {
  'Content-Type': 'application/json',
};

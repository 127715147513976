import { PROJECT_STATUS, PROJECT_STATUS_LABELS, PROJECT_STATUS_ORDER } from './project';

export const SPACE_STATUS = {
  ...PROJECT_STATUS,
  IN_QA: 'IN_QA',
};

export const SPACE_STATUS_LABELS = {
  ...PROJECT_STATUS_LABELS,
  IN_QA: 'In QA',
};

export const SPACE_STATUS_ORDER = [
  ...PROJECT_STATUS_ORDER.slice(0, 3),
  SPACE_STATUS.IN_QA,
  ...PROJECT_STATUS_ORDER.slice(3),
];

export const SPACE_STATUS_OPTIONS = SPACE_STATUS_ORDER
  .map((value) => ({ label: SPACE_STATUS_LABELS[value], value }));

import update from 'immutability-helper';
import { normalize } from 'normalizr';

import { RECEIVE_OWNERS, SET_OWNER_DENSITY_ORG } from '../actions/types';
import initialState from '../initialState';
import { ownerSchema } from '../schemas';

export default (state = initialState.owners, action) => {
  switch (action.type) {
    case RECEIVE_OWNERS:
      return update(state, {
        items: {
          $set: normalize(action.payload, [ownerSchema]),
        },
      });
    case SET_OWNER_DENSITY_ORG:
      return update(state, {
        items: {
          $set: normalize(action.payload, [ownerSchema]),
        },
      });
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchMembers,
  inviteMember,
  removeMember,
  updateMember,
} from 'actions/members';
import { setPathRoot } from 'actions/router';
import { ACL_ROLES, PropTypes } from 'constants';
import { CY_PROJECT_SHARE_MODAL } from 'constants/cypress';
import {
  capitalize,
  isMemberAdmin,
  isMemberEditor,
  sorterByString,
} from 'helpers';
import { getIndividualMembers, getOrganizationMembers } from 'selectors/members';
import { isEditor } from 'selectors/router';
import { getUser, getUserMemberRole } from 'selectors/user';

import Button from 'components/UI/Button';
import Modal from 'components/UI/Modal';

import IndividualAccess from './IndividualAccess';
import OrganizationAccess from './OrganizationAccess';

import s from './index.scss';

function Share(props) {
  const { t } = useTranslation();

  const [invited, setInvited] = useState([]);
  const [dataSource, setDataSource] = useState(false);

  const hasData = props.project && dataSource === props.project.slug;

  const user = {
    ...props.user,
    role: props.userRole,
  };
  const isUserAdmin = isMemberAdmin(user);
  const isUserEditor = isMemberEditor(user);

  useEffect(() => {
    if (props.visible) {
      props.fetchMembers(props.project.slug)
        .then(() => {
          setInvited([]);
          setDataSource(props.project.slug);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project, props.visible]);

  const tryRedirectUser = (email, role) => {
    // User removes themselves from the project or changes role to non-Editor.
    if (email === props.user.email
      && (role === undefined || (props.editor && role !== ACL_ROLES.EDITOR))) {
      props.setPathRoot();
    }
  };

  const onChange = (email, role) => props.updateMember(props.project.slug, email, { role })
    .then(() => props.fetchMembers(props.project.slug))
    .then(() => {
      tryRedirectUser(email, role);
    });

  const onInvite = (values) => props.inviteMember(props.project.slug, values)
    .then(() => {
      setInvited([
        ...invited,
        values.emails || values.domain,
      ]);
    });

  const onRemove = (value) => props.removeMember(props.project.slug, {
    email: value,
  })
    .then(() => props.fetchMembers(props.project.slug))
    .then(() => {
      setInvited(invited.filter((x) => x !== value));
      tryRedirectUser(value);
    });

  const sortMembers = (members) => {
    let curr = [];
    const prev = members
      .filter((member) => {
        const value = member.userId;
        if (invited.includes(value)) {
          curr = [...curr, member];
          return false;
        }
        // Admins can see everyone
        return isUserAdmin
          // Editors can see everyone
          || isUserEditor
          // Viewers can see only admins
          || isMemberAdmin(member)
          // Viewers can see themselves
          || member.userId === props.user.email;
      });

    return [
      ...curr,
      ...prev.sort(sorterByString('userId')),
    ];
  };

  if (!props.project) {
    return null;
  }

  return (
    <Modal
      footer={(
        <div className={s.modalFooterActions}>
          <Button
            dark
            data-cy="close"
            onClick={props.onCancel}
          >
            {capitalize(t('words.done'))}
          </Button>
        </div>
      )}
      height={520}
      loading={!hasData}
      onCancel={props.onCancel}
      title={t('share.title', {
        name: props.project.name || props.project.config.name,
      })}
      visible={props.visible}
      width={726}
    >
      <div data-cy={CY_PROJECT_SHARE_MODAL}>
        <OrganizationAccess
          members={sortMembers(props.organizationMembers)}
          onInvite={onInvite}
          onRemove={onRemove}
          user={user}
        />
        <IndividualAccess
          members={sortMembers(props.individualMembers)}
          onChange={onChange}
          onInvite={onInvite}
          onRemove={onRemove}
          user={user}
        />
      </div>
    </Modal>
  );
}

Share.propTypes = {
  editor: PropTypes.bool.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  individualMembers: PropTypes.arrayOf(PropTypes.Member).isRequired,
  inviteMember: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationMembers: PropTypes.arrayOf(PropTypes.Member).isRequired,
  project: PropTypes.Project,
  removeMember: PropTypes.func.isRequired,
  setPathRoot: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
  user: PropTypes.User.isRequired,
  userRole: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export const PureTestComponent = Share;

const mapStateToProps = (state) => ({
  editor: isEditor(state),
  individualMembers: getIndividualMembers(state),
  organizationMembers: getOrganizationMembers(state),
  user: getUser(state),
  userRole: getUserMemberRole(state),
});

const mapDispatchToProps = {
  fetchMembers,
  inviteMember,
  removeMember,
  setPathRoot,
  updateMember,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Share);

import React from 'react';
import { Link } from 'react-router-dom';

import { PropTypes } from 'constants';

function LinkComponent(props) {
  const {
    children,
    className,
    errorPage,
    to,
  } = props;

  if (errorPage) {
    return (
      <a className={className} href={to}>
        {children}
      </a>
    );
  }

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
}

LinkComponent.propTypes = {
  children: PropTypes.Node.isRequired,
  className: PropTypes.string,
  errorPage: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

LinkComponent.defaultProps = {
  className: '',
  errorPage: false,
};

export default LinkComponent;

import update from 'immutability-helper';
import { normalize } from 'normalizr';

import { RECEIVE_MEMBERS } from 'actions/types';
import initialState from 'initialState';
import { memberSchema } from 'schemas';

export default (state = initialState.members, action) => {
  switch (action.type) {
    case RECEIVE_MEMBERS: {
      return update(state, {
        items: {
          $set: normalize(action.payload || [], [memberSchema]),
        },
      });
    }
    default:
      return state;
  }
};

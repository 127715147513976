import { SET_MINIMAP_CENTER } from '../actions/types';
import initialState from '../initialState';

export default (state = initialState.minimapCenter, action) => {
  switch (action.type) {
    case SET_MINIMAP_CENTER:
      return action.payload;
    default:
      return state;
  }
};

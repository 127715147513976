export const preventEventContextMenu = (callback) => (event) => {
  if (event.ctrlKey || event.button === 2) {
    event.preventDefault();
    return;
  }

  callback(event);
};

export const stopPropagation = (event) => event
  && typeof event.stopPropagation === 'function'
  && event.stopPropagation();

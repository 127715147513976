import { normalize } from 'normalizr';
import {
  attachmentSchema,
  constellationSchema,
  extractionSchema,
  memberSchema,
  ownerSchema,
  pointCloudSchema,
  projectSchema,
  projectsSchema,
  uploadSchema,
} from 'schemas';

export default {
  attachments: {
    items: normalize({}, [attachmentSchema]),
    uploads: normalize({}, [uploadSchema]),
  },
  cloudManager: {
    filters: {},
  },
  constellations: {
    items: normalize({}, [constellationSchema]),
  },
  controlsEnabled: true,
  editor: {
    copiedAnnotation: null,
    filters: {},
    focusedConstellationId: {},
    isPreview: false,
    isVisibleSidebar: false,
    launcherOpen: false,
    openPanels: {
      map: true,
      scenes: true,
      viewer: true,
    },
    selectedLinks: {},
    selectedModelCard: {
      assetViewId: null,
      modelId: null,
    },
    selectedSceneCurrentTime: -1,
    selectedSceneDuration: null,
    selectedSceneIds: [],
    selectedSceneIdsTime: -1,
    shouldFocusSceneSettings: false,
  },
  error: null,
  extractions: {
    fileSizeEstimate: -1,
    items: normalize({}, [extractionSchema]),
  },
  mapDimensions: {},
  members: {
    items: normalize({}, [memberSchema]),
  },
  minimapCenter: {},
  owners: {
    items: normalize({}, [ownerSchema]),
  },
  panelWidths: null,
  pointClouds: {
    items: normalize({}, [pointCloudSchema]),
  },
  project: {
    inst: normalize({}, projectSchema),
    signedUrls: {},
  },
  projects: {
    items: normalize({}, [projectsSchema]),
  },
  shortcutsGuide: false,
  user: {},
  viewers: {
    forge: {
      token: null,
      tokenReceivedAt: 0,
    },
    potree: {},
  },
  yaw: 0,
};

import React, {
  useEffect, useRef, Suspense, lazy,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { joinUsConsoleMessage } from '@helix-re/styles';
import { Route, Switch } from 'react-router';
import { GlobalHotKeys } from 'react-hotkeys';
import { message } from 'antd';
import { hot } from 'react-hot-loader/root';

import { toggleLauncher } from 'actions/editor';
import { toggleShortcutsGuide } from 'actions/shortcutsGuide';
import { ROUTES } from 'constants';
import { ROUTE_REGISTER, ROUTE_RESET_PASSWORD } from 'constants/routes';
import { hotkeysGeneral } from 'constants/hotkeys';
import { createKeyMap } from 'helpers';

import ErrorPage from 'components/common/ErrorPage';

import HotkeysList from './HotkeysList';
import PrivateRoute from './PrivateRoute';

const Editor = lazy(/* istanbul ignore next */ () => import(/* webpackChunkName: "editor" */ 'components/Editor'));
const Register = lazy(/* istanbul ignore next */ () => import(/* webpackChunkName: "register" */ 'components/App/Register'));
const ResetPassword = lazy(/* istanbul ignore next */ () => import(/* webpackChunkName: "reset-password" */ 'components/App/ResetPassword'));
const Viewer = lazy(/* istanbul ignore next */ () => import(/* webpackChunkName: "viewer" */ 'components/Viewer'));

const keyMap = createKeyMap(hotkeysGeneral.keys);

const onBeforeUnload = (event) => {
  const { uploads } = window;
  let text;
  if (Object.keys(uploads).length) {
    text = 'Uploading did not finish yet. Are you sure you want to leave this page and cancel upload?';
  }
  if (text) {
    event.preventDefault();
    event.returnValue = text;
  }
  return text;
};

function App(props) {
  const { t } = useTranslation();

  const refMessage = useRef();

  const handlers = {
    appLauncher: props.toggleLauncher,
    hotkeys: props.toggleShortcutsGuide,
  };

  useEffect(() => {
    const onChangeStatus = () => {
      if (!window.navigator.onLine) {
        refMessage.current = message.warning({
          content: t('offline.message'),
          duration: 0,
        });
      } else if (refMessage.current) {
        refMessage.current();
        refMessage.current = undefined;
      }
    };

    window.addEventListener('online', onChangeStatus);
    window.addEventListener('offline', onChangeStatus);
    onChangeStatus();

    return () => {
      window.removeEventListener('online', onChangeStatus);
      window.removeEventListener('offline', onChangeStatus);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!window.uploads) {
      window.uploads = {};
    }

    window.onbeforeunload = onBeforeUnload;

    joinUsConsoleMessage();
  }, []);

  const renderAuthPath = (Component, isPrivate) => (rest) => (
    <PrivateRoute
      component={Component}
      isPrivate={isPrivate}
      {...rest}
    />
  );

  return (
    <GlobalHotKeys
      handlers={handlers}
      keyMap={keyMap.keyMap}
    >
      {props.error ? (
        <ErrorPage
          message={props.error.message}
          title={props.error.title}
        />
      ) : (
        <Suspense fallback={<h1>Loading…</h1>}>
          <Switch>
            <Route
              path={ROUTE_REGISTER}
              render={renderAuthPath(Register, false)}
            />
            <Route
              path={ROUTE_RESET_PASSWORD}
              render={renderAuthPath(ResetPassword, false)}
            />
            <Route
              path={ROUTES.editor.root()}
              render={renderAuthPath(Editor, true)}
            />
            <Route
              path={ROUTES.viewer.root()}
              render={renderAuthPath(Viewer, true)}
            />
            <Route component={ErrorPage} />
          </Switch>
        </Suspense>
      )}
      <HotkeysList
        onClose={props.toggleShortcutsGuide}
        open={props.shortcutsGuide}
      />
    </GlobalHotKeys>
  );
}

App.propTypes = {
  error: PropTypes.shape(),
  shortcutsGuide: PropTypes.bool.isRequired,
  toggleLauncher: PropTypes.func.isRequired,
  toggleShortcutsGuide: PropTypes.func.isRequired,
};

export const PureTestComponent = App;

const mapStateToProps = (state) => ({
  error: state.error,
  shortcutsGuide: state.shortcutsGuide,
});

const mapDispatchToProps = {
  toggleLauncher,
  toggleShortcutsGuide,
};

export const ConnectTestComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default hot(ConnectTestComponent);

import React from 'react';
import PropTypes from 'prop-types';
import { ICON_PROPS, ICON_SIZE } from 'constants';
import Svg from '.';

export default function IconProgress(props) {
  const { color, progress, ...rest } = props;
  const radius = ICON_SIZE / 2;
  const circumference = ICON_SIZE * Math.PI;

  return (
    <Svg
      {...ICON_PROPS}
      {...rest}
    >
      <svg
        height={ICON_SIZE}
        width={ICON_SIZE}
      >
        <circle
          cx={radius}
          cy={radius}
          fill={color}
          r={ICON_SIZE}
        />
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={radius}
          stroke={color}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth={ICON_SIZE}
          style={{
            strokeDashoffset: circumference - (progress * circumference),
            transform: 'rotateZ(-90deg)',
            transformOrigin: 'center',
            transition: 'stroke-dashoffset .35s',
          }}
        />
      </svg>
    </Svg>
  );
}

IconProgress.propTypes = {
  color: PropTypes.string,
  progress: PropTypes.number,
};

IconProgress.defaultProps = {
  color: 'currentColor',
  progress: 0,
};

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path clipRule="evenodd" d="M5.25 7H3V5L9 5V3L15 3V5L21 5V7L18.75 7L17 21L7 21L5.25 7ZM7.26556 7L8.76556 19H15.2344L16.7344 7L7.26556 7Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

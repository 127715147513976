import { DISABLE_CONTROLS, ENABLE_CONTROLS } from 'actions/types';
import initialState from 'initialState';

export default (state = initialState.controlsEnabled, action) => {
  switch (action.type) {
    case DISABLE_CONTROLS:
      return false;
    case ENABLE_CONTROLS:
      return true;
    default:
      return state;
  }
};

import { EDITOR_APPS } from './launcher';

export const hotkeysMapEditor = {
  group: 'Scene Editing Controls',
  keys: [
    {
      description: 'Move hotspots',
      key: 'move',
      sequence: 'v',
      tooltip: 'Move (%seq)',
    },
    {
      description: 'Link hotspots',
      key: 'link',
      sequence: 'l',
      tooltip: 'Link hotspots (%seq)',
    },
    {
      description: 'Multi-select hotspots',
      key: 'marquee',
      sequence: 'm',
      tooltip: 'Marquee tool (%seq)',
    },
    {
      hidden: true,
      key: 'deleteSelectedLinks',
      sequence: ['backspace', 'del'],
    },
    {
      description: 'Update orientation',
      key: 'orientation',
      sequence: 'shift+O',
    },
    {
      description: 'Update favorite view',
      key: 'favorite',
      sequence: 'shift+F',
    },
  ],
};

export const hotkeysGeneral = {
  group: 'General',
  keys: [
    {
      description: 'App launcher',
      key: 'appLauncher',
      sequence: 'shift+\\',
    },
    {
      description: 'Keyboard shortcuts',
      key: 'hotkeys',
      sequence: 'shift+?',
    },
  ],
};

export const hotkeysGlobe = {
  group: 'Globe',
  keys: [
    {
      key: 'fitToView',
      sequence: 'shift+0',
    },
    {
      key: 'zoomIn',
      sequence: ['+', '='],
    },
    {
      key: 'zoomOut',
      sequence: '-',
    },
  ],
};

export const hotkeysLauncher = {
  group: 'App Launcher',
  keys: EDITOR_APPS().map((app) => ({
    description: app.label,
    key: app.key,
    sequence: app.sequence,
  })),
};

export const hotkeysPanelPalette = {
  group: 'Workspace',
  keys: [
    {
      description: 'Toggle Scenes panel',
      key: 'scenes',
      sequence: '1',
      tooltip: 'Photos (%seq)',
    },
    {
      description: 'Toggle Plan panel',
      key: 'map',
      sequence: '2',
      tooltip: 'Map (%seq)',
    },
    {
      description: 'Toggle Viewer panel',
      key: 'viewer',
      sequence: '3',
      tooltip: 'Viewer (%seq)',
    },
    {
      description: 'Toggle preview mode',
      key: 'preview',
      sequence: 'p',
    },
  ],
};

export const hotkeysViewerGlobe = {
  keys: [
    {
      description: 'Toggle list',
      key: 'viewerList',
      sequence: 'l',
    },
    {
      description: 'Go back in list hierarchy',
      key: 'viewerBack',
      sequence: 'esc',
    },
  ],
};

export const hotkeysViewerPanel = {
  group: 'Scene View Controls',
  keys: [
    {
      description: 'Rotate camera left',
      key: 'left',
      sequence: ['left', 'a'],
    },
    {
      description: 'Rotate camera right',
      key: 'right',
      sequence: ['right', 'd'],
    },
    {
      description: 'Go to next scene',
      key: 'up',
      sequence: ['up', 'w'],
    },
    {
      description: 'Go to previous scene',
      key: 'down',
      sequence: ['down', 's'],
    },
  ],
};

export const appHotkeys = [
  hotkeysGeneral,
  hotkeysPanelPalette,
  hotkeysViewerPanel,
  hotkeysMapEditor,
  hotkeysLauncher,
];

import firebase from 'firebase/app';
import 'firebase/remote-config';

import {
  FIREBASE_REMOTE_CONFIG_FETCH_TIMEOUT,
  FIREBASE_REMOTE_CONFIG_MIN_FETCH_INTERVAL,
} from 'constants';
import * as flags from 'constants/flags';

export const initRemoteConfig = async (app) => {
  const config = firebase.remoteConfig(app);

  config.defaultConfig = Object.values(flags)
    .reduce((acc, flag) => ({
      ...acc,
      [flag.name]: flag.defaultValue,
    }), {});

  config.settings = {
    fetchTimeoutMillis: FIREBASE_REMOTE_CONFIG_FETCH_TIMEOUT,
    minimumFetchIntervalMillis: FIREBASE_REMOTE_CONFIG_MIN_FETCH_INTERVAL,
  };

  try {
    await config.ensureInitialized();
    await config.activate();
    await config.fetch();
  } catch {
    // Do nothing.
  }
};

export const isFeatureEnabled = (featureFlag) => {
  const config = firebase.remoteConfig();
  return config.getValue(featureFlag.name).asBoolean();
};

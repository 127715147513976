import { createSelector } from 'reselect';

import { getCurrentUser } from '../actions/auth';
import { ACL_ROLES } from '../constants/misc';
import { getMemberEntities } from './members';

export const getIdToken = (state) => state.user.idToken;

export const getUser = createSelector(
  getCurrentUser,
  (user) => ({
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    uid: user.uid,
  }),
);

export const getUserMemberRole = createSelector(
  getUser,
  getMemberEntities,
  (user, entities) => {
    let role;
    if (user.uid && entities && entities[user.email]) {
      ({ role } = entities[user.email]);
    }
    return role || ACL_ROLES.USER;
  },
);

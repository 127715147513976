import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkInternal } from 'react-router-dom';

import s from './index.scss';

const isExternal = (to) => {
  const link = typeof to === 'object' ? to.href : to;
  return !link.startsWith('/');
};

export default function Link(props) {
  const {
    children,
    newTab,
    to,
    ...rest
  } = props;

  if (!to) {
    return (
      <span className={s.noLink}>
        {children}
      </span>
    );
  }

  const internal = !newTab && !isExternal(to);

  const openNewTabProps = internal ? {} : {
    rel: 'noopener noreferrer',
    target: '_blank',
  };

  const linkProps = { ...openNewTabProps, ...rest };

  return !internal
    ? (
      <a
        href={to}
        {...linkProps}
      >
        {children}
      </a>
    )
    : (
      <LinkInternal
        to={to}
        {...linkProps}
      >
        {children}
      </LinkInternal>
    );
}

Link.propTypes = {
  children: PropTypes.node,
  newTab: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      href: PropTypes.string,
    }),
  ]),
};

Link.defaultProps = {
  children: undefined,
  newTab: false,
  to: undefined,
};

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path clipRule="evenodd" d="M21 3L21 22L3 22L3 3.38197L11 7.382V3L21 3ZM19 5L13 5L13 10.618L5 6.618L5 20L15 20L15 16H17L17 20H19L19 5ZM9 16L9 18H7L7 16L9 16ZM13 16V18H11V16H13ZM13 12V14H11V12H13ZM9 12V14L7 14V12L9 12ZM17 12V14H15V12H17ZM17 8V10L15 10L15 8L17 8Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

import { getValue } from './immutability';

export const addToObject = (obj, key, value = true) => ({
  ...obj,
  [key]: value,
});

export const removeFromObject = (obj, key) => {
  const { [key]: _, ...rest } = obj;
  return { ...rest };
};

export const setTree = (options) => {
  const res = {
    changed: [],
    rows: [],
    value: undefined,
  };

  const setChanged = (nextState) => {
    res.changed = [...res.changed, ...nextState];
  };

  res.rows = options.rows.map((row) => {
    let { value } = options;

    const setValue = (nextState) => {
      if (row[options.key] !== nextState) {
        setChanged([row.id]);
        row[options.key] = nextState;
      }

      res.value = nextState;
      value = nextState;
    };

    if (options.value !== undefined || row.id === options.id) {
      setValue(getValue(options.value, !row[options.key]));
    }

    if (row.children) {
      const obj = setTree({
        ...options,
        rows: row.children,
        value,
      });

      setChanged(obj.changed);
      row.children = obj.rows;

      if (!options.stopPropagation && obj.value !== undefined && (obj.value
        || row.children.every((x) => x[options.key] === obj.value))) {
        setValue(obj.value);
      }
    }

    return row;
  });

  return res;
};

export const addOrRemoveFromObject = (obj, key, value) => (obj[key] !== undefined
  ? removeFromObject(obj, key)
  : addToObject(obj, key, value));

import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal';

import s from './index.scss';

export default function VideoGuide(props) {
  return (
    <Modal
      footer={null}
      onCancel={props.onCancel}
      title="Video Guide"
      visible={props.visible}
      width={620}
      wrapClassName={s.root}
    >
      <div className={s.iframeContainer}>
        <iframe
          allow="autoplay; encrypted-media"
          allowFullScreen
          className={s.iframe}
          frameBorder="0"
          height="315"
          src="https://youtube.com/embed/1V_OYITiFm0"
          title="Video Guide"
          width="560"
        />
      </div>
    </Modal>
  );
}

VideoGuide.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    size={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M9 3.5H3.5V16.5H9M9 3.5H16.5V16.5H9M9 3.5C9 3.5 8.5 8 9 8.5C9.5 9 13.5 7 13.5 10C13.5 13 9.5 11.5 9 12C8.5 12.5 9 16.5 9 16.5"
      stroke="currentColor"
    />
  </Svg>
);

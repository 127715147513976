import React from 'react';

import { ICON_SIZE } from 'constants/misc';
import { PropTypes } from 'constants/propTypes';

export default function SvgIcon(props) {
  const {
    children,
    height,
    size,
    width,
    ...rest
  } = props;
  const h = height !== undefined ? height : size;
  const w = width !== undefined ? width : size;

  return (
    <svg
      fill="none"
      height={h}
      version="1.1"
      width={w}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </svg>
  );
}

SvgIcon.propTypes = {
  children: PropTypes.Node.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  viewBox: PropTypes.string,
  width: PropTypes.number,
};

SvgIcon.defaultProps = {
  color: 'currentColor',
  size: ICON_SIZE,
  viewBox: `0 0 ${ICON_SIZE} ${ICON_SIZE}`,
};

export const SORT_DIR = {
  ASC: 'Asc',
  DESC: 'Desc',
};

export const SORT_KEYS = {
  date: 'date',
  format: 'format',
  name: 'name',
  size: 'size',
};

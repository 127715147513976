import React, { useState } from 'react';
import { connect } from 'react-redux';

import { signOut } from 'actions/auth';
import { toggleShortcutsGuide } from 'actions/shortcutsGuide';
import { PropTypes } from 'constants';
import { logEvent } from 'helpers';
import { getPathProjectId, isEditor, isGlobeView } from 'selectors/router';
import { getUser, getUserMemberRole } from 'selectors/user';

import ModalReportIssue from 'components/UI/Modal/ReportIssue';

import Layout from './Layout';

function UserMenu(props) {
  const [visibleReportIssue, setVisibleReportIssue] = useState(false);

  const user = {
    ...props.user,
    role: props.userRole,
  };

  const track = (event) => {
    if (!props.editing) {
      logEvent('menu_click', {
        name: event,
        source: 'sidebar',
      });
    }
  };

  const onClickHelp = () => {
    track('help');
    setVisibleReportIssue(true);
  };

  const onClickKeyboardShortcuts = () => {
    track('keyboard_shortcuts');

    if (!props.shortcutsGuide) {
      props.toggleShortcutsGuide();
    }
  };

  const onClickSignOut = () => {
    track('sign_out');
    props.signOut();
  };

  const onClickVideoGuide = () => {
    track('video_guide');
    props.onClickVideoGuide();
  };

  const onCloseReportIssue = () => setVisibleReportIssue(false);

  return (
    <>
      <Layout
        editing={props.editing}
        isGlobeView={props.isGlobeView}
        onChangeVisible={props.onVisibleChange}
        onClickHelp={onClickHelp}
        onClickKeyboardShortcuts={onClickKeyboardShortcuts}
        onClickSignOut={onClickSignOut}
        onClickVideoGuide={onClickVideoGuide}
        projectId={props.projectId}
        user={user}
      />
      <ModalReportIssue
        onCancel={onCloseReportIssue}
        visible={visibleReportIssue}
      />
    </>
  );
}

UserMenu.propTypes = {
  editing: PropTypes.bool.isRequired,
  isGlobeView: PropTypes.bool.isRequired,
  onClickVideoGuide: PropTypes.func,
  onVisibleChange: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  shortcutsGuide: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  toggleShortcutsGuide: PropTypes.func.isRequired,
  user: PropTypes.User.isRequired,
  userRole: PropTypes.string.isRequired,
};

export const PureTestComponent = UserMenu;

const mapStateToProps = (state) => ({
  editing: isEditor(state),
  isGlobeView: isGlobeView(state),
  projectId: getPathProjectId(state),
  shortcutsGuide: state.shortcutsGuide,
  user: getUser(state),
  userRole: getUserMemberRole(state),
});

const mapDispatchToProps = {
  signOut,
  toggleShortcutsGuide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserMenu);

import firebase from 'firebase/app';

import { initAnalytics, initRemoteConfig } from 'helpers';

const initApp = () => {
  const options = {
    apiKey: process.env.FIREBASE_API_KEY,
    appId: process.env.FIREBASE_CONFIG_WEB_APP_ID,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  };
  const app = firebase.initializeApp(options);
  return app;
};

export const initFirebase = () => {
  const app = initApp();
  initRemoteConfig(app);
  initAnalytics(app);
};

import React from 'react';

import Typography from '..';

import s from './index.scss';

export default /* istanbul ignore next */ function Label(props) {
  return (
    <Typography
      baseClass={s.root}
      {...props}
    />
  );
}

/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFormError } from 'helpers';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { ROUTE_RESET_PASSWORD } from 'constants';

import Logo from 'components/common/Logo';
import { ButtonGoogle } from '../ButtonGoogle';
import { ButtonMicrosoft } from '../ButtonMicrosoft';
import { Divider } from '../Divider';

import s from '../auth.scss';

export const PAGE = {
  HELP: 'HELP',
  PASSWORD: 'PASSWORD',
  PROVIDER: 'PROVIDER',
  SENT: 'SENT',
};

export const PROVIDER = {
  emailLink: 'emailLink',
  google: 'google',
  microsoft: 'microsoft',
  saml: 'saml',
};

// TODO: Add to Storybook
export function LoginLayout({
  email,
  errors,
  loading,
  onBack,
  onContact,
  onGoogle,
  onGuide,
  onHelp,
  onMicrosoft,
  onSAML,
  onSubmit,
  page,
  provider,
  register,
  // setPathDemoProject,
}) {
  const { t } = useTranslation();

  const buttonState = (value) => ({
    disabled: loading && loading !== value,
    loading: loading === value,
  });

  const buttonGoogle = (
    <ButtonGoogle
      label={t('auth.signInWith', { provider: t('auth.provider.google') })}
      onClick={onGoogle}
      {...buttonState(PROVIDER.google)}
    />
  );

  const buttonMicrosoft = (
    <ButtonMicrosoft
      className={s.buttonMicrosoft}
      label={t('auth.signInWith', { provider: t('auth.provider.microsoft') })}
      onClick={onMicrosoft}
      {...buttonState(PROVIDER.microsoft)}
    />
  );

  const emailLink = (
    <>
      <div className={s.lead}>
        {t('auth.completeSignIn')}
      </div>
      <div className={s.form}>
        <p>
          {t('auth.emailedInstructions', { email })}
        </p>
        <p>
          {t('auth.checkEmail')}
        </p>
        <div className={s.links}>
          <Button
            className={s.buttonLink}
            onClick={onHelp}
            simple
          >
            {t('auth.troubleGettingEmail')}
          </Button>
          <Button
            className={s.buttonLink}
            onClick={onBack}
            simple
          >
            {t('auth.returnToSignIn')}
          </Button>
        </div>
      </div>
    </>
  );

  const form = (
    <>
      <div className={s.lead}>
        {t('auth.signInToYourAccount')}
      </div>
      <div className={s.form}>
        <form onSubmit={onSubmit}>
          <Input
            className={s.input}
            dark
            error={getFormError(errors, 'email')}
            name="email"
            placeholder={t('form.email.placeholder')}
            ref={register({
              required: t('form.email.validate'),
            })}
          />
          <Button
            className={s.buttonSignIn}
            primary
            type="submit"
            {...buttonState(PROVIDER.emailLink)}
          >
            {t('login.submit')}
          </Button>
        </form>
        <Button
          className={classNames(s.buttonLink, s.buttonReset)}
          simple
          to={ROUTE_RESET_PASSWORD}
        >
          {t('register.error.resetButton')}
        </Button>
        <Divider label={t('words.or')} />
        {buttonGoogle}
        {buttonMicrosoft}
        <Button
          className={classNames(s.buttonSignIn, s.buttonSAML)}
          dark
          onClick={onSAML}
          {...buttonState(PROVIDER.saml)}
        >
          Sign in with Amazon
        </Button>
      </div>
    </>
  );

  const help = (
    <>
      <div className={s.lead}>
        {t('auth.troubleGettingEmail')}
      </div>
      <div className={s.form}>
        <b>{t('auth.confirmFollowing')}</b>
        <ul className={s.list}>
          <li>{t('auth.emailSpelledCorrectly')}</li>
          <li>{t('auth.emailInSpam')}</li>
          <li>{t('auth.inboxNotAvailableSpace')}</li>
          <li>{t('auth.connectedToInternet')}</li>
        </ul>
        <b className={s.beforeButton}>
          {t('auth.stillHavingTrouble')}
        </b>
        <Button
          className={s.buttonLink}
          onClick={onBack}
          simple
        >
          {t('auth.trySigningInAgain')}
        </Button>
      </div>
    </>
  );

  const password = (
    <>
      <div className={s.lead}>
        {t('auth.completeSignIn')}
      </div>
      <div className={s.form}>
        <form onSubmit={onSubmit}>
          <Input
            autoComplete="password"
            className={s.input}
            error={getFormError(errors, 'password')}
            name="password"
            placeholder={t('form.password.placeholder')}
            ref={register({
              required: t('form.password.validate'),
            })}
            type="password"
          />
          <Button
            className={s.buttonSignIn}
            sidebar
            type="submit"
            {...buttonState(PROVIDER.emailLink)}
          >
            {t('login.submit')}
          </Button>
        </form>
        <Button
          className={classNames(s.buttonLink, s.buttonReset)}
          onClick={onBack}
          simple
        >
          {t('register.error.signInButton')}
        </Button>
      </div>
    </>
  );

  const providerContent = (
    <>
      <div className={s.lead}>
        {t('auth.signInWith', {
          provider: t(`auth.provider.${provider}`),
        })}
      </div>
      <div className={s.form}>
        <p className={s.signInWithDifferentProvider}>
          {t('auth.useOtherOption', {
            email,
            provider: t(`auth.provider.${provider}`),
          })}
        </p>
        {provider === PROVIDER.google && buttonGoogle}
        {provider === PROVIDER.microsoft && buttonMicrosoft}
        <Button
          className={s.buttonBack}
          onClick={onBack}
          simple
        >
          {t('auth.returnToSignIn')}
        </Button>
      </div>
    </>
  );

  const renderContent = () => {
    switch (page) {
      case PAGE.HELP:
        return help;
      case PAGE.PASSWORD:
        return password;
      case PAGE.PROVIDER:
        return providerContent;
      case PAGE.SENT:
        return emailLink;
      default:
        return form;
    }
  };

  return (
    <>
      <div className={s.header}>
        <Logo />
      </div>

      {renderContent()}
      <>
        <div className={s.help}>
          {t('auth.needHelp')}
          <Button
            dark
            onClick={onContact}
            simple
          >
            {t('auth.contactUs')}
          </Button>
        </div>
        <div className={s.demoActions}>
          <div className={s.demoHeader}>
            Become an expert
          </div>
          <div className={s.demoSummary}>
            Learn the basics by watching a quick demo covering the new Density Maps.
          </div>
          <Button
            className={s.buttonDemo}
            dark
            onClick={onGuide}
          >
            {t('auth.watchVideo')}
          </Button>
        </div>
      </>
    </>
  );
}

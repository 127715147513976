import { INTERNAL_INDEX } from '../constants';

export const addToArray = (arr, key) => [...arr, key];

export const castToArray = (value) => (Array.isArray(value) ? value : [value]);

export const filterInternalIndexEntries = (arr) => arr
  .filter((x) => typeof x !== 'string' || !x.startsWith(INTERNAL_INDEX));

export const getFirstAndLastEntry = (arr) => {
  const [first] = arr;
  const last = arr[arr.length - 1];
  return [first, last];
};

export const removeFromArray = (arr, key) => {
  const i = arr.indexOf(key);
  return [
    ...arr.slice(0, i),
    ...arr.slice(i + 1),
  ];
};

export const addOrRemoveFromArray = (arr, key) => (arr.includes(key)
  ? removeFromArray(arr, key)
  : addToArray(arr, key));

import Utif from 'utif';

import { refreshToken } from 'actions/auth';
import { setMapDimensions } from 'actions/mapDimensions';
import { setPathRoot } from 'actions/router';
import { setIdToken } from 'actions/user';
import { FETCH_AUTHORIZATION, FETCH_PARSER, REQUEST_RETRY_LIMIT } from 'constants';
import { ensureSlash, getUrlWithAccessToken } from 'helpers';
import { getProject, getSignedUrls } from 'selectors/project';
import { getProjectsUrl, isEditor } from 'selectors/router';
import { getIdToken } from 'selectors/user';
import { RECEIVE_ATTACHMENT } from './types';
import { API } from '../constants';
import { sendErrorToTracker } from './errors';

// Track consecutive failed requests. The request do not have to be made to the
// same URL address.
let retryCount = -1;

// Flag to prevent us from making further authorised requests as we will know
// in advance that they would fail. These requests will be queued and executed
// once this flag is unset.
let fetchingToken = false;

let ignoreDuplicateRequests = {};
let ignoreReduxActions = {};

const removeIgnoreActionFlag = (type) => {
  const { [type]: isIgnored, ...nextIgnoreReduxActions } = ignoreReduxActions;

  if (isIgnored) {
    ignoreReduxActions = nextIgnoreReduxActions;
  }

  return Boolean(isIgnored);
};

const dispatchAction = (type, payload) => (dispatch) => {
  if (!type || removeIgnoreActionFlag(type)) {
    return;
  }

  dispatch({
    payload,
    type,
  });
};

const getDuplicateRequestHash = (config) => {
  const hash = config.method + config.url + config.body;
  return hash;
};

const getFetchArgs = (url, opts, token, authorization) => {
  if (authorization === FETCH_AUTHORIZATION.HEADERS) {
    return [url, {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${token}`,
      },
    }];
  }

  if (authorization === FETCH_AUTHORIZATION.URL) {
    return [getUrlWithAccessToken(url, token), opts];
  }

  return [url, opts];
};

// TODO: Add TTL, maybe 300 seconds?
const getToken = () => (dispatch, getState) => new Promise((resolve) => {
  const resolveWhenTokenFetched = () => {
    if (!fetchingToken) {
      const token = getIdToken(getState());
      resolve(token);
      return;
    }
    setTimeout(resolveWhenTokenFetched, 50);
  };
  return resolveWhenTokenFetched();
});

const attachmentUpdateHandler = (dispatch) => (event) => {
  const attachment = JSON.parse(event.data);
  dispatch({
    payload: attachment,
    type: RECEIVE_ATTACHMENT,
  });
};

export const newEventSource = (projectId) => (dispatch) => {
  const url = API.events.notifications.editor.project(projectId);
  dispatch(getToken())
    .then((token) => {
      const createEventSource = () => {
        let eventSource;
        try {
          eventSource = new EventSource(`${url}?access_token=${token}`, {
            withCredentials: true,
          });
        } catch (e) {
          sendErrorToTracker(e, {
            url,
          });
        }
        return eventSource;
      };

      const eventSource = createEventSource();

      eventSource.addEventListener('ATTACHMENT_UPDATE', attachmentUpdateHandler(dispatch), false);
      eventSource.onerror = (err) => {
        sendErrorToTracker(err);
      };

      window.addEventListener('beforeunload', () => {
        if (eventSource) eventSource.close();
      });
    });
};

const fetchWithAuthorisation = (
  url,
  opts,
  parser = FETCH_PARSER.JSON,
  authorization = FETCH_AUTHORIZATION.HEADERS,
) => (dispatch) => dispatch(getToken())
  .then((token) => {
    const fetchArgs = getFetchArgs(url, opts, token, authorization);
    // eslint-disable-next-line no-use-before-define
    return dispatch(fetchWrapper(...fetchArgs, parser, authorization));
  });
const isIgnoredDuplicateRequest = (config) => {
  const hash = getDuplicateRequestHash(config);
  return Boolean(ignoreDuplicateRequests[hash]);
};

const maybeIgnoreAction = (config) => {
  const type = config.ignoreTypeOnce;

  if (!type) {
    return;
  }

  ignoreReduxActions = {
    ...ignoreReduxActions,
    [type]: true,
  };
};

const shouldIgnoreDuplicateRequests = (config) => config.ignoreDuplicates
  && config.method === 'GET';

const maybeIgnoreDuplicateRequest = (config) => {
  if (!shouldIgnoreDuplicateRequests(config)) {
    return '';
  }

  const hash = getDuplicateRequestHash(config);
  ignoreDuplicateRequests = {
    ...ignoreDuplicateRequests,
    [hash]: true,
  };
  return hash;
};

const removeIgnoreDuplicateRequestFlag = (hash) => {
  const { [hash]: isIgnored, ...nextIgnoreDuplicateRequests } = ignoreDuplicateRequests;

  if (isIgnored) {
    ignoreDuplicateRequests = nextIgnoreDuplicateRequests;
  }

  return Boolean(isIgnored);
};

const retryFetch = (...args) => (dispatch) => {
  // Queue up the retry. We allow only one consecutive request to pass through.
  // Everything else will be queued up until that request comes back.
  if (fetchingToken) {
    return dispatch(fetchWithAuthorisation(...args));
  }

  retryCount += 1;

  // We increase retry count above whenever fetching is not in progress. This
  // may result in multiple requests increasing the count by more than 1 step.
  if (retryCount >= REQUEST_RETRY_LIMIT) {
    return Promise.reject();
  }

  fetchingToken = true;

  return refreshToken()
    .then((token) => {
      dispatch(setIdToken(token));
      fetchingToken = false;

      return dispatch(fetchWithAuthorisation(...args));
    });
};

// TODO: Do not export this wrapper, components should call `fetchAction()`
export const fetchWrapper = (url, opts, parser, authorization) => (dispatch) => fetch(url, opts)
  .then((response) => {
    if (response.status === 400) {
      return new Error('400');
    }
    if (response.status === 401) {
      throw new TypeError();
    }
    if (response.status === 403) {
      return dispatch(setPathRoot());
    }
    if (response.status === 404) {
      throw new Error('404');
    }
    // Clear the global retry count since we know the authorisation token is
    // valid at the moment.
    retryCount = -1;
    return response;
  })
  .then((response) => {
    if (parser === FETCH_PARSER.JSON) {
      return response.json();
    }
    if (parser === FETCH_PARSER.BLOB) {
      return response.blob();
    }
    return response;
  })
  .then((response) => {
    // This is a server error message.
    if (typeof response === 'object' && !Array.isArray(response) && response.status === 500) {
      return Promise.reject(response);
    }
    return response;
  })
  .catch((error) => {
    if (error instanceof TypeError) {
      // we likely got here because CORS headers were absent
      if (url.startsWith(process.env.CDN_URL)) {
        // CDN returns 403 with no CORS - treat error like a 403
        return dispatch(setPathRoot());
      }

      return dispatch(retryFetch(url, opts, parser, authorization));
    }
    if (error.message === '404') {
      return null;
    }
    throw error;
  });

export const fetchAction = (config) => (dispatch) => {
  config.method = config.method || 'GET';

  if (isIgnoredDuplicateRequest(config)) {
    // Ignoring duplicate requests requires handlers to not expect
    // any resolved value. This might lead to bugs otherwise.
    return Promise.resolve();
  }

  maybeIgnoreAction(config);
  const hash = maybeIgnoreDuplicateRequest(config);

  const getPayload = (data) => {
    if (config.payload) {
      return typeof config.payload === 'function'
        ? config.payload(data)
        : config.payload;
    }

    return data;
  };

  return dispatch(fetchWithAuthorisation(config.url, {
    body: config.body,
    headers: config.headers,
    method: config.method,
  }, config.parser, config.authorization))
    .then((data) => {
      const payload = getPayload(data);
      removeIgnoreDuplicateRequestFlag(hash);
      dispatch(dispatchAction(config.type, payload));
      return payload;
    });
};

export const getDownloadLink = (source) => (dispatch, getState) => {
  const findDownloadLink = (obj) => {
    if (obj.downloadLink) {
      return obj.downloadLink;
    }
    if (obj.attachment) {
      return obj.attachment;
    }
    if (obj.attachmentData) {
      return obj.attachmentData;
    }
    return undefined;
  };

  let link = source;

  while (typeof link === 'object' && link) {
    link = findDownloadLink(link);
  }

  const state = getState();
  const idToken = getIdToken(state);

  let url = link;
  if (isEditor(state)) {
    url = getUrlWithAccessToken(link, idToken);
  }

  return url;
};

// Scene tiles.
const isTilePreview = (tile) => tile.z === 0;

const tileToUrlSuffix = (tile) => {
  if (!tile || isTilePreview(tile)) {
    return 'preview.jpg';
  }
  return `${tile.z}/${tile.face}/${tile.y}/${tile.x}.jpg`;
};

const getTileUrl = (url, projectId, sceneId, suffix) => {
  const baseUrl = ensureSlash(url);
  return `${baseUrl}${projectId}/tiles/${sceneId}/${suffix}`;
};

const getUrlWithSignature = (baseUrl, signedUrl, tile, urlSuffix) => {
  if (!signedUrl || !signedUrl.urls) {
    return undefined;
  }
  const { expires, keyname, urls } = signedUrl;
  const signature = urls[urlSuffix];
  const query = `Expires=${expires}&KeyName=${keyname}&Signature=${signature}`;
  return `${baseUrl}?${query}`;
};

export const getSceneTileUrl = (args = {}) => (dispatch, getState) => {
  const {
    projectId,
    sceneId,
    thumbnailPath,
    tilePreview,
  } = args;

  let { tile } = args;

  if (!tile && !tilePreview) {
    tile = {
      face: 'f',
      x: 0,
      y: 0,
      z: 1,
    };
  }

  const state = getState();

  const urlSuffix = tileToUrlSuffix(tile);

  const getSceneTileUrlArgs = [
    projectId || (getProject(state) || {}).id,
    sceneId,
    urlSuffix,
  ];

  if (isEditor(state)) {
    let url;
    if (thumbnailPath) {
      url = `${ensureSlash(getProjectsUrl(state))}${thumbnailPath}`;
    } else {
      url = thumbnailPath || getTileUrl(getProjectsUrl(state), ...getSceneTileUrlArgs);
    }
    return getUrlWithAccessToken(url, getIdToken(state));
  }

  if (thumbnailPath) {
    return thumbnailPath;
  }

  const signedUrls = getSignedUrls(state);
  const signedUrl = signedUrls[sceneId];
  const url = getTileUrl(process.env.CDN_URL, ...getSceneTileUrlArgs);
  return getUrlWithSignature(url, signedUrl, tile, urlSuffix);
};

// Minimap.
const getMinimapUnsignedUrl = (floorPlan, space) => (dispatch, getState) => {
  const state = getState();

  const editing = isEditor(state);

  if (floorPlan) {
    return floorPlan.attachmentData.downloadLink;
  }
  if (editing && space.floorPlan) {
    return space.floorPlan.attachmentData.downloadLink;
  }
  return '';
};

const getMinimapUrl = (floorPlan, space) => (dispatch, getState) => {
  const idToken = getIdToken(getState());
  const unsignedUrl = dispatch(getMinimapUnsignedUrl(floorPlan, space));
  return getUrlWithAccessToken(unsignedUrl, idToken) || space.mapUrl || '';
};

const isMinimapTiffFile = (url) => /.tiff?/i.test(url);

const loadTiffFile = (url, fn) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'arraybuffer';
  xhr.open('GET', url);
  xhr.onload = () => {
    const [ifd] = Utif.decode(xhr.response);
    Utif.decodeImage(xhr.response, ifd);
    return fn(ifd);
  };
  xhr.send();
};

export const getMinimapUrlAsync = (floorPlan, space) => (dispatch) => new Promise((resolve) => {
  const url = dispatch(getMinimapUrl(floorPlan, space));

  return isMinimapTiffFile(url)
    ? loadTiffFile(url, (ifd) => {
      const canvas = document.createElement('canvas');
      canvas.height = ifd.height;
      canvas.width = ifd.width;
      const ctx = canvas.getContext('2d');

      const imageData = ctx.createImageData(canvas.width, canvas.height);
      Utif.toRGBA8(ifd)
        .forEach((pixel, i) => {
          imageData.data[i] = pixel;
        });
      ctx.putImageData(imageData, 0, 0);

      return resolve(canvas.toDataURL('image/png'));
    })
    : resolve(url);
});

export const setMinimapDimensions = (floorPlan, space) => (dispatch) => {
  const url = dispatch(getMinimapUrl(floorPlan, space));

  const callback = (image) => dispatch(setMapDimensions(space.id, {
    height: image.height,
    width: image.width,
  }));

  if (isMinimapTiffFile(url)) {
    loadTiffFile(url, callback);
  } else {
    const image = new Image();
    image.onload = () => callback(image);
    image.src = url;
  }
};

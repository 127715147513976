import update from 'immutability-helper';

export const getValue = (value, defaultValue) => (value === undefined
  ? defaultValue
  : value);

export const mergeNested = (mergeState) => (prevState) => Object
  .keys(mergeState)
  .reduce((nextState, key) => ({
    ...nextState,
    [key]: {
      ...prevState[key],
      ...mergeState[key],
    },
  }), {});

export const updateDefault = (defaultValue, spec) => (value) => update(
  getValue(value, defaultValue),
  spec,
);

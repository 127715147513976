import initialState from '../initialState';
import { TOGGLE_SHORTCUTS_GUIDE } from '../actions/types';

export default (state = initialState.shortcutsGuide, action) => {
  switch (action.type) {
    case TOGGLE_SHORTCUTS_GUIDE:
      return !state;
    default:
      return state;
  }
};

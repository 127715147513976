/* eslint-disable react/prop-types */
import React from 'react';

import Button from 'components/UI/Button';
import IconGoogle from 'assets/svg/IconGoogle';

import authStyles from '../auth.scss';

export function ButtonGoogle({
  disabled,
  label,
  loading,
  onClick,
}) {
  return (
    <Button
      className={authStyles.buttonSignIn}
      dark
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      <IconGoogle />
      {label}
    </Button>
  );
}

import $ from 'jquery';
import $script from 'scriptjs';

// Forge's Three.js is locked to version 71.
const FORGE_THREE_VERSION = '71';

export const ensureForgeCorrectThreeVersion = () => {
  if (window.THREE) {
    if (window.forgeViewerTHREE && window.THREE !== window.forgeViewerTHREE) {
      window.THREE = window.forgeViewerTHREE;
    } else if (window.THREE.REVISION !== FORGE_THREE_VERSION) {
      // unset THREE so forge version doesn't mutate old THREE
      window.THREE = undefined;
    }
  }
};

const ensurePotreeCorrectThreeVersion = () => {
  if (window.potreeViewerTHREE) {
    window.THREE = window.potreeViewerTHREE;
  } else if (window.THREE) {
    // unset THREE so potree version doesn't mutate old THREE
    window.THREE = undefined;
  }
};

const storeForgeThreeVersion = () => {
  // store Autodesk's version of THREE so it can be restored later
  if (window.THREE && window.THREE.REVISION === FORGE_THREE_VERSION) {
    window.forgeViewerTHREE = window.THREE;
  }
};

const storePotreeThreeVersion = () => {
  window.potreeViewerTHREE = window.THREE;
};

export const importPotreeDependencies = () => new Promise((resolve) => {
  global.$ = $;

  storeForgeThreeVersion();
  ensurePotreeCorrectThreeVersion();

  const callback = () => {
    storePotreeThreeVersion();
    resolve();
  };

  if (global.Potree) {
    callback();
  } else {
    // TODO: Require Three.js as a dependency.
    $script([
      '/pointcloud-viewer/libs/three.js/build/three.min.js',
      '/pointcloud-viewer/libs/other/BinaryHeap.js',
      '/pointcloud-viewer/libs/tween/tween.min.js',
      '/pointcloud-viewer/libs/proj4/proj4.js',
      '/pointcloud-viewer/libs/plasio/js/laslaz.js',
    ], callback);
  }
});

import * as THREE from '../../public/libs/three.js/build/three.module';

export function get3dPointFrom2dCoords(coords, camera, renderer) {
  const fixedCoords = {
    x: (coords.x / renderer.domElement.clientWidth) * 2 - 1,
    y: -(coords.y / renderer.domElement.clientHeight) * 2 + 1,
  };

  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(fixedCoords, camera);
  return raycaster.ray.origin;
}

export function get2dCoordsFrom3dPoint(vector, camera, renderer) {
  vector.project(camera);

  vector.x = Math.round(((vector.x + 1) * renderer.domElement.clientWidth) / 2);
  vector.y = Math.round(((-vector.y + 1) * renderer.domElement.clientHeight) / 2);
  vector.z = 0;

  return { x: vector.x, y: vector.y };
}

import { createSelector } from 'reselect';
import {
  match,
  optional,
  required,
  select,
  stripTrailingChars,
} from '@helix-re/url-pattern';
import { API, ROUTES, ROUTES_NS } from '../constants';
import { getBoolean, getValue } from '../regex-lego-reselect';

const {
  assets,
  constellation,
  editor,
  extractor,
  model,
  models,
  place,
  plan,
  scene,
  space,
  tour,
} = ROUTES_NS;

const editorApp = (app) => match(editor) + (app ? (required() + match(app)) : '');
const requiredEditorApp = (app) => editorApp(app) + stripTrailingChars;

export const getPathname = (state) => state.router && state.router.location.pathname;

export const getSearch = (state) => state.router.location.search;

export const getPathAssetId = createSelector(
  getPathname,
  getValue(editorApp(assets) + select()),
);

export const getPathAssetViewId = createSelector(
  getPathname,
  getValue(editorApp(assets) + required() + select()),
);

export const getPathConstellationId = createSelector(
  getPathname,
  getValue(editorApp(tour) + required(space) + select(constellation)),
);

export const getPathModelId = createSelector(
  getPathname,
  getValue(optional(editor) + required() + optional(models) + required(space) + select(model)),
);

export const getPathPointCloudId = createSelector(
  getPathname,
  getValue(editorApp(extractor) + select()),
);

export const getPathProjectId = createSelector(
  getPathname,
  getValue(optional(editor) + optional(place) + select()),
);

export const getPathSceneId = createSelector(
  getPathname,
  getValue(optional(editor) + required() + optional(tour) + required(space) + select(scene)),
);

export const getPathSpaceId = createSelector(
  getPathname,
  getValue(optional(editor) + required() + optional([plan, models, tour]) + select(space)),
);

export const isEditor = createSelector(
  getPathname,
  getBoolean(requiredEditorApp()),
);

export const isFloorPlan = createSelector(
  getPathname,
  getBoolean(requiredEditorApp(plan)),
);

export const isGlobeView = createSelector(
  getPathname,
  (pathname) => {
    const isPlace = getBoolean(match(place) + stripTrailingChars)(pathname);
    const isRoot = pathname === ROUTES.viewer.root();
    return isPlace || isRoot;
  },
);

export const isModels = createSelector(
  getPathname,
  getBoolean(requiredEditorApp(models)),
);

export const isTour = createSelector(
  getPathname,
  getBoolean(requiredEditorApp(tour)),
);

export const getProjectsUrl = createSelector(
  isEditor,
  (editing) => (editing ? API.editor.projects.root() : API.editor.scans.root()),
);

import { API } from '../constants';

import { formBody } from '../helpers';
import { fetchAction } from './helpers';
import { RECEIVE_MEMBERS } from './types';

export const fetchMembers = (projectId) => fetchAction({
  type: RECEIVE_MEMBERS,
  url: API.editor.projects.one(projectId).members(),
});

export const inviteMember = (projectId, member) => (dispatch) => dispatch(fetchAction({
  ...formBody(member),
  method: 'POST',
  parser: false,
  url: API.editor.projects.one(projectId).members(),
}))
  .then(() => dispatch(fetchMembers(projectId)));

export const removeMember = (projectId, values) => fetchAction({
  ...formBody(values),
  method: 'DELETE',
  parser: false,
  url: API.editor.projects.one(projectId).members(),
});

export const updateMember = (projectId, memberId, member) => fetchAction({
  ...formBody({
    ...member,
    email: memberId,
  }),
  method: 'PATCH',
  parser: false,
  url: API.editor.projects.one(projectId).members(),
});

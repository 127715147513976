import { API } from '../constants/api';
import { fetchAction } from './helpers';
import { createAssetFormData, objectToFormData } from '../helpers';
import { getPathAssetId, getPathProjectId } from '../selectors/router';
import {
  DELETE_ATTACHMENT_UPLOAD_COMPLETED,
  RECEIVE_ATTACHMENT,
  RECEIVE_ATTACHMENTS,
  RECEIVE_DELETE_ASSET_VIEW,
  RECEIVE_DELETE_ATTACHMENT,
  RECEIVE_PATCH_ASSET_VIEW,
  UPDATE_ATTACHMENT_UPLOAD,
  RECEIVE_POST_ASSET_VIEW,
  RECEIVE_POST_ATTACHMENT,
  RECEIVE_RESUME_ATTACHMENT,
  UPDATE_ATTACHMENT_UPLOAD_FAILED,
  UPDATE_ATTACHMENT_UPLOAD_PROGRESS,
  UPLOAD_NEW_ATTACHMENT,
} from './types';

export const removeCompletedUpload = (uploadId) => (dispatch) => {
  delete window.uploads[uploadId];

  setTimeout(() => {
    dispatch({
      payload: { id: uploadId },
      type: DELETE_ATTACHMENT_UPLOAD_COMPLETED,
    });
  }, 5000);
};

export const updateAttachmentUploadFailed = (id) => ({
  payload: { id },
  type: UPDATE_ATTACHMENT_UPLOAD_FAILED,
});

export const updateAttachmentUploadProgress = (id, progress) => ({
  payload: { id, progress },
  type: UPDATE_ATTACHMENT_UPLOAD_PROGRESS,
});

export const updateAttachmentUpload = (id, progress) => ({
  payload: { id, progress },
  type: UPDATE_ATTACHMENT_UPLOAD,
});

export const uploadNewAttachment = (attachment) => ({
  payload: attachment,
  type: UPLOAD_NEW_ATTACHMENT,
});

// Fetch actions.

export const createAssetView = (params) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());
  const attachmentId = getPathAssetId(getState());

  return dispatch(fetchAction({
    body: createAssetFormData(params),
    method: 'POST',
    payload: (data) => ({
      assetView: data,
      attachmentId,
    }),
    type: RECEIVE_POST_ASSET_VIEW,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).views.root(),
  }));
};

export const createAttachment = (params) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  return dispatch(fetchAction({
    body: objectToFormData(params),
    method: 'POST',
    type: RECEIVE_POST_ATTACHMENT,
    url: API.editor.projects.one(projectId).attachments.root(),
  }));
};

export const deleteAnnotationAttachment = (projectId, annotationId, attachmentId) => fetchAction({
  method: 'DELETE',
  parser: false,
  url: API.editor.projects.one(projectId).annotations.one(annotationId).attachments.one(attachmentId).root(),
});

export const fetchAttachmentById = (attachmentId) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  return dispatch(fetchAction({
    type: RECEIVE_ATTACHMENT,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).root(),
  }));
};

export const fetchAttachments = (projectId) => (dispatch, getState) => {
  if (!projectId) {
    // eslint-disable-next-line no-console
    console.warn('Deprecated: `projectId` argument should be explicitly passed to `fetchAttachments()`');
    projectId = getPathProjectId(getState());
  }

  return dispatch(fetchAction({
    ignoreDuplicates: true,
    type: RECEIVE_ATTACHMENTS,
    url: API.editor.projects.one(projectId).attachments.root(),
  }));
};

export const postAnnotationsAttachments = (projectId, annotationId, params) => (dispatch) => dispatch(fetchAction({
  body: objectToFormData(params),
  method: 'POST',
  url: API.editor.projects.one(projectId).annotations.one(annotationId).attachments.root(),
}));

export const removeAssetView = (attachmentId, assetViewId) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  return dispatch(fetchAction({
    method: 'DELETE',
    parser: false,
    payload: {
      assetViewId,
      attachmentId,
    },
    type: RECEIVE_DELETE_ASSET_VIEW,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).views.one(assetViewId).root(),
  }));
};

export const removeAttachment = (attachmentId) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  if (window.uploads[attachmentId]) {
    delete window.uploads[attachmentId];
  }

  return dispatch(fetchAction({
    method: 'DELETE',
    parser: false,
    payload: {
      id: attachmentId,
    },
    type: RECEIVE_DELETE_ATTACHMENT,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).root(),
  }));
};

export const updateAssetView = (attachmentId, assetViewId, params) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  return dispatch(fetchAction({
    body: createAssetFormData(params),
    method: 'PATCH',
    payload: (data) => ({
      assetView: data,
      attachmentId,
    }),
    type: RECEIVE_PATCH_ASSET_VIEW,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).views.one(assetViewId).root(),
  }));
};

export const updateAttachment = (attachmentId, params) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());
  return dispatch(fetchAction({
    body: objectToFormData(params),
    method: 'PATCH',
    type: UPDATE_ATTACHMENT_UPLOAD,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).root(),
  }));
};

export const resumeAttachmentUpload = (attachmentId, params) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());

  return dispatch(fetchAction({
    body: objectToFormData(params),
    method: 'POST',
    type: RECEIVE_RESUME_ATTACHMENT,
    url: API.editor.projects.one(projectId).attachments.one(attachmentId).resume.root(),
  }));
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PropTypes, ROUTES } from 'constants';
import {
  capitalize,
  noop,
  QueryDesktop,
  QueryMobile,
} from 'helpers';
import { getProject } from 'selectors/project';
import { getPathProjectId, isEditor } from 'selectors/router';

import IconShare from 'assets/svg/IconShare';
import Logo from 'components/common/Logo';
import Share from 'components/common/Share';
import Tooltip from 'components/UI/Tooltip';

import LinkComponent from './LinkComponent';
import Notifications from './Notifications';
import Project from './Project';
import Space from './Space';
import UserMenu from './UserMenu';

import s from './index.scss';

function Header(props) {
  const { t } = useTranslation();

  const [shareVisible, setShareVisible] = useState(false);

  const editorProject = props.editor && props.projectId;

  const onCancelShare = () => setShareVisible(false);

  const onClickShare = () => setShareVisible(!shareVisible);

  const canRenderContent = Boolean(props.project || !props.projectRequired);

  return (
    <header className={classNames(s.root, { [s.editor]: editorProject })}>
      <div className={s.header}>
        {editorProject
          ? <Project />
          : (
            <h1 className={s.heading}>
              <LinkComponent
                className={s.logo}
                errorPage={props.errorPage}
                to={props.logoTo}
              >
                <Logo />
              </LinkComponent>
            </h1>
          )}
        {props.leftContent && (
        <QueryDesktop>
          <div className={s.content}>
            {props.leftContent}
          </div>
        </QueryDesktop>
        )}
      </div>
      <div className={s.header}>
        {props.rightContent && canRenderContent && (
        <QueryDesktop>
          <div className={s.content}>
            {props.rightContent}
          </div>
        </QueryDesktop>
        )}
        {editorProject && canRenderContent && (
          <>
            <QueryMobile>
              <Space />
            </QueryMobile>
            <Notifications />
            <Tooltip title={capitalize(t('words.share'))}>
              <button
                className={s.button}
                onClick={onClickShare}
                type="button"
              >
                <IconShare />
              </button>
            </Tooltip>
          </>
        )}
        <UserMenu onClickVideoGuide={props.onClickVideoGuide} />
        <Share
          onCancel={onCancelShare}
          project={props.project}
          visible={shareVisible}
        />
      </div>
    </header>
  );
}

Header.propTypes = {
  editor: PropTypes.bool.isRequired,
  errorPage: PropTypes.bool,
  leftContent: PropTypes.Node,
  logoTo: PropTypes.string,
  onClickVideoGuide: PropTypes.func,
  project: PropTypes.Project,
  projectId: PropTypes.string.isRequired,
  projectRequired: PropTypes.bool,
  rightContent: PropTypes.Node,
};

Header.defaultProps = {
  logoTo: ROUTES.editor.root(),
  onClickVideoGuide: noop,
  projectRequired: true,
};

export const PureTestComponent = Header;

const mapStateToProps = (state) => ({
  editor: isEditor(state),
  project: getProject(state),
  projectId: getPathProjectId(state),
});

export default connect(
  mapStateToProps,
)(Header);

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <mask fill="white" id="plan-mask">
      <path d="M6.80664 10.8125L12.2689 9.0166L17.7311 10.8125L23.1934 9.0166V21.4082L17.7311 23.2041L12.2689 21.4082L6.80664 23.2041V10.8125Z" />
    </mask>
    <path
      d="M18.2311 23.2041V10.8125H17.2311V23.2041H18.2311ZM11.7689 9.0166V21.4082H12.7689V9.0166H11.7689ZM6.80664 10.8125L6.4943 9.86256L5.80664 10.0887V10.8125H6.80664ZM12.2689 9.0166L12.5812 8.06663L12.2689 7.96394L11.9565 8.06663L12.2689 9.0166ZM6.80664 23.2041H5.80664V24.5856L7.11898 24.1541L6.80664 23.2041ZM17.7311 23.2041L17.4188 24.1541L17.7311 24.2568L18.0435 24.1541L17.7311 23.2041ZM17.7311 10.8125L17.4188 11.7625L17.7311 11.8652L18.0435 11.7625L17.7311 10.8125ZM23.1934 9.0166H24.1934V7.63515L22.881 8.06663L23.1934 9.0166ZM23.1934 21.4082L23.5057 22.3581L24.1934 22.132V21.4082H23.1934ZM12.2689 21.4082L12.5812 20.4582L12.2689 20.3555L11.9565 20.4582L12.2689 21.4082ZM7.11898 11.7625L12.5812 9.96657L11.9565 8.06663L6.4943 9.86256L7.11898 11.7625ZM7.80664 23.2041V10.8125H5.80664V23.2041H7.80664ZM18.0435 9.86256L12.5812 8.06663L11.9565 9.96657L17.4188 11.7625L18.0435 9.86256ZM18.0435 11.7625L23.5057 9.96657L22.881 8.06663L17.4188 9.86256L18.0435 11.7625ZM22.1934 9.0166V21.4082H24.1934V9.0166H22.1934ZM22.881 20.4582L17.4188 22.2541L18.0435 24.1541L23.5057 22.3581L22.881 20.4582ZM11.9565 22.3581L17.4188 24.1541L18.0435 22.2541L12.5812 20.4582L11.9565 22.3581ZM11.9565 20.4582L6.4943 22.2541L7.11898 24.1541L12.5812 22.3581L11.9565 20.4582Z"
      fill="currentColor"
      mask="url(#plan-mask)"
    />
  </Svg>
);

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import { fetchAction } from 'actions/helpers';
import { API, ROUTES } from 'constants';
import { isEditor } from 'selectors/router';

// Persist auth even after closing browser
const LOCAL_PERSISTENCE = firebase.auth.Auth.Persistence.LOCAL;
// Valid for current tab/session only
const SESSION_PERSISTENCE = firebase.auth.Auth.Persistence.SESSION;

// Helper to set firebase auth persistence.
// https://firebase.google.com/docs/auth/web/auth-state-persistence
const setPersistence = (persistence = LOCAL_PERSISTENCE) => firebase.auth()
  .setPersistence(persistence);

export const getCurrentUser = () => firebase.auth().currentUser || {
  getIdToken: () => Promise.resolve(),
};

export const getGoogleAuthProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('email');
  return provider;
};

export const getMicrosoftAuthProvider = () => new firebase.auth
  .OAuthProvider('microsoft.com');

export const getSAMLAuthProvider = () => new firebase.auth
  // FIXME: what should this provider id value be set to?
  .SAMLAuthProvider('saml.amazon');

export const getRedirectResult = () => firebase.auth().getRedirectResult();

export const getSignInMethodsForEmail = (email) => firebase.auth()
  .fetchSignInMethodsForEmail(email);

export const isSignInWithEmailLink = (url) => firebase.auth()
  .isSignInWithEmailLink(url);

export const onChangeUser = (callback) => firebase.auth()
  .onAuthStateChanged(callback);

export const refreshToken = () => getCurrentUser().getIdToken(true);

export const sendResetPasswordToEmail = (email) => firebase.auth().sendPasswordResetEmail(email, {
  url: window.location.origin,
});

export const sendSignInLinkToEmail = (email, url) => firebase.auth()
  .sendSignInLinkToEmail(email, {
    // Always true for email link sign-in.
    handleCodeInApp: true,
    // Additional state showPromo=1234 can be retrieved from URL on
    // sign-in completion in signInSuccess callback by checking
    // window.location.href.
    url,
  });

export const sendVerifyEmail = () => (dispatch) => dispatch(fetchAction({
  method: 'POST',
  url: API.functions.verifyEmail(),
}));

export const signInAnonymously = () => setPersistence(SESSION_PERSISTENCE)
  .then(() => firebase.auth().signInAnonymously());

export const signInWithEmailLink = (email, url) => setPersistence()
  .then(() => firebase.auth().signInWithEmailLink(email, url));

export const signInWithPassword = (email, password) => setPersistence()
  .then(() => firebase.auth().signInWithEmailAndPassword(email, password));

export const signInWithRedirect = (provider) => setPersistence()
  .then(() => firebase.auth().signInWithRedirect(provider));

export const signOut = (noPageReload) => (_, getState) => firebase.auth()
  .signOut()
  .then(() => {
    if (!noPageReload) {
      const state = getState();
      const url = isEditor(state)
        ? ROUTES.editor.root()
        : ROUTES.viewer.root();
      window.location.assign(url);
    }
  });

export const signUpWithPassword = (email, password, name) => setPersistence()
  .then(() => firebase.auth().createUserWithEmailAndPassword(email, password))
  .then((userCredential) => userCredential.user.updateProfile({ displayName: name }));

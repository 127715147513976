import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <path
      d="M12.0723 14.5271H23.8863L22.3815 21.0195H8.11328V9.07031H12.0723L13.0523 10.4031H18.8145H22.3815V12.4958"
      stroke="currentColor"
    />
  </Svg>
);

/* istanbul ignore file */
import Types from 'prop-types';

import { FLOOR_PLAN_STATE } from './floorPlan';
import { MAPS_MODE } from './maps';
import { ACL_ROLES, SCANNERS } from './misc';
import { POINT_CLOUD_STATE, POINT_CLOUD_TYPE } from './pointCloud';
import { MEASUREMENT_SYSTEM } from './units';

// Private.
const ServerDate = Types.oneOfType([
  Types.number,
  Types.string,
]);

// Exports.
const oneOfMap = (map) => Types.oneOf(Object.values(map));

const Position2D = Types.shape({
  x: Types.number,
  y: Types.number,
});

const AttachmentView = Types.shape({
  attachmentId: Types.string,
  clearedToPublish: Types.bool,
  downloadEnabled: Types.bool,
  id: Types.string,
  name: Types.string,
  previewEnabled: Types.bool,
  showModelCard: Types.bool,
  spaceIds: Types.arrayOf(Types.string),
});

const Annotation = Types.shape({
  id: Types.string,
});

const Attachment = Types.shape({
  assetViews: Types.arrayOf(AttachmentView),
  cardTitle: Types.string,
  createdAt: Types.number,
  downloadable: Types.bool,
  downloadLink: Types.string,
  forgeDocumentId: Types.string,
  id: Types.string,
  originalFileName: Types.string,
  previewable: Types.bool,
  previewType: Types.string,
  progress: Types.shape({
    percentageProgress: Types.number,
    remainingTime: Types.number,
    stageIndex: Types.number,
    stages: Types.arrayOf(Types.string),
  }),
  requesterId: Types.string,
  size: Types.number,
  source: Types.string, // TODO: List of valid sources
  spaceIds: Types.arrayOf(Types.string),
  status: Types.string, // TODO: List of valid statuses
  type: Types.string, // TODO: List of valid types
  updatedAt: Types.number,
});

const Constellation = Types.shape({
  id: Types.string,
  isPlaced: Types.bool,
  scenes: Types.arrayOf(Types.shape({
    id: Types.string,
    r: Types.number,
    x: Types.number,
    y: Types.number,
  })),
  spaceId: Types.string,
});

const Event = Types.shape({
  clientX: Types.number,
  clientY: Types.number,
});

const Extraction = Types.shape({
  attachment: Attachment,
  comment: Types.string,
  coords: Types.arrayOf(Position2D),
  crop: Types.arrayOf(Types.arrayOf(Types.number)),
  format: Types.string,
  id: Types.oneOfType([
    Types.number,
    Types.string,
  ]),
  minPointSpacing: Types.number,
  name: Types.string,
  state: oneOfMap(POINT_CLOUD_STATE),
  zBounds: Types.arrayOf(Types.number),
  zbounds: Types.arrayOf(Types.number),
});

const FloorPlan = Types.oneOfType([
  Types.shape({
    attachmentData: Attachment,
    createdAt: Types.number,
    folioId: Types.string,
    id: Types.string,
    spaceId: Types.string,
    status: oneOfMap(FLOOR_PLAN_STATE),
    updatedAt: Types.number,
  }),
  Types.string,
]);

const History = Types.shape({
  push: Types.func,
});

const Hotspot = Types.shape({
  bidirectional: Types.bool,
  target: Types.string,
  type: Types.string,
  url: Types.string,
});

const HTMLElementType = Types.instanceOf(HTMLElement);

const MapMode = oneOfMap(MAPS_MODE);

const MarzipanoHotspot = Types.shape({
  // ...Marzipano internal properties and methods
  params: Types.shape(), // custom data we pass through hotspots
});

const MeasureUnits = Types.oneOf([
  MEASUREMENT_SYSTEM.IMPERIAL,
  MEASUREMENT_SYSTEM.METRIC,
  '',
]);

const Member = Types.shape({
  resourceId: Types.string,
  resourceType: Types.oneOf([
    'SCAN',
  ]),
  role: oneOfMap(ACL_ROLES),
  status: Types.oneOf([
    'GRANTED',
    'PENDING',
  ]),
  userId: Types.string,
});

const menuItem = {
  className: Types.string,
  content: Types.node,
  danger: Types.bool,
  disabled: Types.bool,
  key: Types.string,
};

const MenuItems = Types.arrayOf(Types.shape({
  ...menuItem,
  items: Types.arrayOf(Types.shape(menuItem)),
}));

const Node = Types.oneOfType([
  Types.func,
  Types.node,
  Types.object,
  Types.string,
]);

const PointCloud = Types.shape({
  attachment: Attachment,
  categories: Types.arrayOf(Types.shape({
    children: Types.arrayOf(Types.shape({
      code: Types.number,
      color: Types.string,
      name: Types.string,
      pointRatio: Types.number,
      type: Types.string,
    })),
    color: Types.string,
    name: Types.string,
    type: Types.string,
  })),
  categorizePoints: Types.bool,
  id: Types.string,
  minPointSpacing: Types.number,
  name: Types.string,
  pointCount: Types.number,
  scanner: oneOfMap(SCANNERS),
  spaceIds: Types.arrayOf(Types.string),
  state: oneOfMap(POINT_CLOUD_STATE),
  thumbnails: Types.shape({
    fullres: Types.string,
    large: Types.string,
    medium: Types.string,
    original: Types.string,
    small: Types.string,
  }),
  transformationMatrix: Types.string,
  type: oneOfMap(POINT_CLOUD_TYPE),
});

const Project = Types.shape({
  config: Types.shape({
    address: Types.string,
    displayUnits: Types.string,
    name: Types.string,
  }),
  deliveryDate: ServerDate,
  name: Types.string,
  scanDate: ServerDate,
});

const Ref = Types.shape({
  current: Types.oneOfType([
    Node,
    Types.object,
  ]),
});

const Scene = Types.shape({
  angle: Types.oneOfType([
    Types.number,
    Types.string,
  ]),
  annotations: Types.arrayOf(Annotation),
  cleared: Types.bool,
  faceSize: Types.number,
  id: Types.string,
  initialViewParameters: Types.shape({
    fov: Types.number,
    pitch: Types.number,
    roll: Types.number,
    yaw: Types.number,
  }),
  levels: Types.arrayOf(Types.shape({
    fallbackOnly: Types.bool,
    size: Types.number,
    tileSize: Types.number,
  })),
  linkHotspots: Types.arrayOf(Types.shape({
    pitch: Types.number,
    rotation: Types.number,
    target: Types.string,
    type: Types.string,
    yaw: Types.number,
  })),
  name: Types.string,
  redacted: Types.bool,
  sensitive: Types.bool,
  state: Types.string,
});

const SegmentationCategory = Types.shape({
  children: Types.arrayOf(Types.shape(this)),
  color: Types.string,
  id: Types.string,
  label: Types.string,
  open: Types.bool,
  visible: Types.bool,
});

const SelectOption = Types.shape({
  label: Types.string,
  value: Types.oneOfType([
    Types.bool,
    Types.string,
  ]),
});

const Space = Types.shape({
  active: Types.bool,
  deliveryDate: ServerDate,
  deliveryStatus: Types.string,
  estimatedArea: Types.number,
  floorPlan: FloorPlan,
  id: Types.string,
  mapAsset: Types.string,
  name: Types.string,
  scanDate: ServerDate,
  scannedArea: Types.number,
  scenes: Types.shape(),
});

const Upload = Types.shape({
  createdAt: Types.number,
  id: Types.string,
  name: Types.string,
  progress: Types.number,
  size: Types.number,
  type: Types.oneOf([
    'asset',
    'pointCloud',
    'scene',
  ]),
});

const User = Types.shape({
  displayName: Types.string,
  email: Types.string,
  emailVerified: Types.bool,
  isAnonymous: Types.bool,
  uid: Types.string,
});

const ViewerScene = Types.shape({
  data: Scene,
  scene: Types.shape(), // Marzipano Scene interface
  view: Types.shape(), // Marzipano View interface
});

const ViewParameters = Types.shape({
  fov: Types.number,
  pitch: Types.number,
  roll: Types.number,
  yaw: Types.number,
});

const PotreeMeasuringTool = Types.shape({
  startInsertion: Types.func,
});

const PotreeLengthUnit = Types.shape({
  code: Types.string,
  unitspermmeter: Types.number,
});

export const PropTypes = {
  ...Types,
  Annotation,
  Attachment,
  AttachmentView,
  Constellation,
  Event,
  Extraction,
  FloorPlan,
  History,
  Hotspot,
  HTMLElement: HTMLElementType,
  MapMode,
  MarzipanoHotspot,
  MeasureUnits,
  Member,
  MenuItems,
  Node,
  oneOfMap,
  PointCloud,
  Position2D,
  PotreeLengthUnit,
  PotreeMeasuringTool,
  Project,
  Ref,
  Scene,
  SegmentationCategory,
  SelectOption,
  Space,
  Upload,
  User,
  ViewerScene,
  ViewParameters,
};

import addNotification from 'react-push-notification';
import IconDanger from 'assets/svg/IconDanger';
import {
  getAttachmentProgress,
  isAttachmentFailed,
  isAttachmentGeneratingExtraction,
  isAttachmentUploading,
} from './attachment';

import IconProcessing from '../assets/svg/IconProcessing';
import IconProgress from '../assets/svg/IconProgress';

export const getNotificationFromAttachment = (attachment) => {
  const notification = {
    canceli18nKey: 'editor.notifications.deleteFile',
    Icon: IconProcessing,
    iconProps: {},
    statusi18nKey: 'editor.notifications.processing',
  };

  if (isAttachmentFailed(attachment)) {
    notification.Icon = IconDanger;
    notification.statusi18nKey = 'editor.notifications.failed';
  } else if (isAttachmentUploading(attachment)) {
    notification.canceli18nKey = 'editor.notifications.cancelUpload';
    notification.Icon = IconProgress;
    notification.iconProps.progress = getAttachmentProgress(attachment);
    notification.statusi18nKey = 'editor.notifications.uploading';
    if (window.data === undefined) {
      window.data = [];
    }
    if (window.data.indexOf(attachment.id) < 0) {
      window.data.push(attachment.id);
    }
  } else if (isAttachmentGeneratingExtraction(attachment)) {
    notification.statusi18nKey = 'editor.notifications.generatingExtraction';
  } else if (window.data !== undefined && window.data.indexOf(attachment.id) >= 0) {
    addNotification({
      duration: 10000,
      message: attachment.cardTitle + ': Upload completed. Processing file.', // eslint-disable-line prefer-template
      native: true, // when using native, your OS will handle theming.
      subtitle: attachment.cardTitle + 'Processing', // eslint-disable-line prefer-template
      theme: 'darkblue',
      title: 'Density, Inc',
    });
    const pos = (window.data.indexOf(attachment.id));
    window.data.splice(pos, 1);
    return notification;
  }
  if (window.dataProcessing === undefined) {
    window.dataProcessing = [];
  }
  if (window.dataProcessing.indexOf(attachment.id) < 0) {
    window.dataProcessing.push(attachment.id);
  }
  return notification;
};

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <path
      clipRule="evenodd"
      d="M20.9681 19.4422L8.12769 19.442C7.01632 19.442 6.11539 18.5411 6.11539 17.4297C6.11539 16.3297 6.99809 15.4358 8.09386 15.4177C8.1782 15.4235 8.24084 15.4273 8.28868 15.43C8.31927 15.4398 8.35068 15.4476 8.38268 15.4533C8.95523 15.5549 9.47369 15.813 9.89225 16.1818C10.1203 16.3828 10.4639 16.4264 10.7016 16.237C10.9394 16.0476 10.9808 15.6983 10.7621 15.4871C10.4465 15.1824 10.0831 14.9263 9.6842 14.7312L9.66422 14.2966C9.66035 14.2125 9.65839 14.1279 9.65839 14.0428C9.65839 11.0612 12.0761 8.64371 15.0589 8.64371C17.6071 8.64371 19.7454 10.4091 20.3122 12.7842L20.4935 13.5441L21.2705 13.6237C22.7387 13.7743 23.8848 15.0166 23.8848 16.5253C23.8848 18.1363 22.5789 19.4422 20.9681 19.4422ZM8.56461 14.3471L8.56464 14.3471C8.56001 14.2463 8.55765 14.1448 8.55765 14.0428C8.55765 13.772 8.57422 13.505 8.60641 13.2429C9.00075 10.0305 11.7393 7.54297 15.0589 7.54297C18.128 7.54297 20.7005 9.66924 21.3828 12.5287C23.4068 12.7363 24.9855 14.4464 24.9855 16.5253C24.9855 18.7442 23.1869 20.543 20.9681 20.543L8.12768 20.5428C6.40838 20.5428 5.01465 19.149 5.01465 17.4297C5.01465 15.938 6.06386 14.6914 7.4646 14.3875C7.67827 14.3411 7.90012 14.3167 8.12768 14.3167C8.34617 14.3319 8.41972 14.3345 8.45649 14.3358C8.46893 14.3363 8.47716 14.3365 8.48537 14.3371C8.50143 14.3383 8.5174 14.3405 8.56461 14.3471Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

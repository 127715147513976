import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path d="M18.866 4.1077L17.134 3.1077L15.634 5.70577L17.366 6.70577L18.866 4.1077Z" fill="currentColor" />
    <path d="M8.36602 5.70577L6.86602 3.1077L5.13397 4.1077L6.63397 6.70577L8.36602 5.70577Z" fill="currentColor" />
    <path d="M13 5V2L11 2V5H13Z" fill="currentColor" />
    <path clipRule="evenodd" d="M19 18V14C19 10.134 15.866 7 12 7C8.13401 7 5 10.134 5 14L5 18H3L3 20H11V22H13V20L21 20V18L19 18ZM7 14L7 18H17V14C17 11.2386 14.7614 9 12 9C9.23858 9 7 11.2386 7 14Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

/* eslint-disable sort-keys */
import { GENERIC_STATES } from './misc';
import { SORT_KEYS } from './sort';

export const POINT_CLOUD_MODEL_VIEW = {
  PLAN: 'plan',
  SECTION: 'section',
};

export const POINT_CLOUD_POINT_COLOR_TYPE = {
  CLASSIFICATION: 'CLASSES',
  RGB: 'RGB',
  WHITE: 'WHITE',
};

export const POINT_CLOUD_SORT_KEYS = {
  [SORT_KEYS.date]: 'attachment.createdAt',
  [SORT_KEYS.format]: 'format',
  [SORT_KEYS.name]: 'name',
  [SORT_KEYS.size]: 'attachment.size',
};

export const POINT_CLOUD_SORT_ORDER = [
  SORT_KEYS.date,
  SORT_KEYS.name,
  SORT_KEYS.format,
  SORT_KEYS.size,
];

export const POINT_CLOUD_STATE = {
  ...GENERIC_STATES,
  INDEXING: 'INDEXING',
  MERGING: 'MERGING',
  PROCESSING: 'PROCESSING',
};

export const POINT_CLOUD_THUMBNAILS = {
  fullres: 'fullres',
  large: 'large',
  medium: 'medium',
  original: 'original',
  small: 'small',
};

export const POINT_CLOUD_TYPE = {
  COMBINED: 'COMBINED',
  EXTRACTION: 'EXTRACTION',
  GEOTIFF: 'GEOTIFF',
  RAW: 'RAW',
};

export const POINT_CLOUDS_VIEW_MODES = {
  COMPACT: 'COMPACT',
  EXPANDED: 'EXPANDED',
  GRID: 'GRID',
};

export const POINT_CLOUD_VIEWER_PERSPECTIVE = {
  THREE: '3D',
  TWO: '2D',
};

export const POINT_CLOUD_BUDGET_VALUE = {
  FIFTY: '50000000',
  HUNDRED: '100000000',
  TWENTY: '20000000',
  TWOHUNDRED: '200000000',
};
export const POINT_CLOUD_BUDGET_LABEL = {
  FIFTY: '50 Million',
  HUNDRED: '100 Million',
  TWENTY: '20 Million',
  TWOHUNDRED: '200 Million',
};

export const POINT_CLOUD_CLASSIFICATION_COLORS_RGB = [
  [242, 31, 46], [95, 155, 134],
  [73, 125, 108], [100, 158, 139], // 4 = Ceiling
  [74, 181, 184], [74, 180, 184], // 6 = Doors
  [212, 127, 96], [212, 127, 96], // 8 = Fixed Object
  [51, 96, 82], [255, 228, 141], // 9 = Floor
  [255, 228, 141], [255, 154, 114], // 11 = Lights
  [124, 158, 205], [124, 158, 205], // 14 = Noise
  [73, 125, 108], [73, 125, 108],
  [73, 125, 108], [23, 94, 96], // 17 = Stairs
  [4, 99, 102], [0, 0, 0], // 19 = WALLS
];

export const POINT_CLOUD_SET_VIEW = {
  TOP: 'Top',
  SIDE: 'Side',
  BOTTOM: 'Bottom',
  MIDDLE: 'Middle',
};

export const POINT_CLOUD_EXPORT_TYPE = {
  JSON: 'JSON',
  DXF: 'DXF',
};

export const DENSITY_PLANS_TOKEN = '';

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    size={21}
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      d="M5.1543 15.2474H16.1543V16.2474H5.1543V15.2474Z"
      fill="currentColor"
    />
    <path
      d="M6.36816 8.10678L10.6705 3.7533L14.9402 8.10819L14.2261 8.80828L10.6677 5.1788L7.07943 8.8097L6.36816 8.10678Z"
      fill="currentColor"
    />
    <path
      d="M11.1567 4.79417L11.1567 13.2473L10.1567 13.2473L10.1567 4.79417L11.1567 4.79417Z"
      fill="currentColor"
    />
  </Svg>
);

import React from 'react';
import classNames from 'classnames';
import { Collapse } from 'antd';

import IconCaretRight from 'assets/svg/IconCaretRight';
import IconCaretDown from 'assets/svg/IconCaretDown';
import { PropTypes } from '../../../constants';

import s from './index.scss';

export default function Accordion(props) {
  const { className, panels, ...rest } = props;

  return (
    <Collapse
      accordion
      className={classNames(s.root, className)}
      expandIcon={({ isActive }) => (isActive ? <IconCaretDown /> : <IconCaretRight />)}
      // expandIcon={isActive ? <IconCaretDown size={16} /> : <IconCaretRight size={16} />}
      {...rest}
    >
      {panels.map((panel, index) => (
        <Collapse.Panel
          header={panel.title}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {panel.body}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}

Accordion.propTypes = {
  className: PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.Node,
    title: PropTypes.string,
  })).isRequired,
};

Accordion.defaultProps = {
  className: undefined,
};

import { API } from '../constants';
import { fetchAction } from './helpers';
import { objectToFormData } from '../helpers';

export const reportIssue = (params) => fetchAction({
  body: objectToFormData(params),
  method: 'POST',
  parser: false,
  url: API.editor.support.issues(),
});

export const requestScan = (params) => fetchAction({
  body: objectToFormData(params),
  method: 'POST',
  parser: false,
  url: API.editor.support.scanRequests(),
});

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import s from '../index.scss';

const transformKey = (key, plain) => {
  switch (key) {
    case 'cmd':
      return '\u2318';
    case 'down':
      return '\u2193';
    case 'left':
      return '\u2190';
    case 'right':
      return '\u2192';
    case 'shift':
      return 'Shift';
    case 'tab':
      return 'Tab';
    case 'up':
      return '\u2191';
    default:
      return plain ? key : key.toUpperCase();
  }
};

export default function Sequence(props) {
  const { keys, plain } = props;

  const renderSequence = (sequence) => {
    const sequenceKeys = sequence.split('+');
    return sequenceKeys.map((key, index) => (
      <React.Fragment key={key}>
        {index > 0 && '+'}
        <span
          className={classNames(s.key, {
            [s.plain]: plain,
            [s.styled]: !plain,
          })}
        >
          {transformKey(key, plain)}
        </span>
      </React.Fragment>
    ));
  };

  const sequences = Array.isArray(keys) ? keys : [keys];
  return sequences.map((sequence, index) => (
    <React.Fragment key={sequence}>
      {index > 0 && 'or'}
      {renderSequence(sequence)}
    </React.Fragment>
  ));
}

Sequence.propTypes = {
  keys: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  plain: PropTypes.bool,
};

Sequence.defaultProps = {
  plain: false,
};

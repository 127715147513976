import { ASSET_PREVIEW_TYPES, POINT_CLOUD_MODEL_VIEW } from '../../constants';
import { getRectangleCorners, rotate2DPoint } from '../math';
import { get3dPointFrom2dCoords } from '../pointCoordConversion';
import * as THREE from '../../../public/libs/three.js/build/three.module';

export const hidePointCloudCrop = (PotreeViewer) => {
  PotreeViewer.scene.removeAllClipVolumes();
};

export const invertPotreeIndex = (index) => {
  if (index === 0) return 1;
  if (index === 1) return 0;
  return -1;
};

export const isPointCloudViewable = (pointCloud) => {
  const viewableFormats = [ASSET_PREVIEW_TYPES.entwine, ASSET_PREVIEW_TYPES.potree];
  return viewableFormats.includes(pointCloud.previewType);
};

export const pointCloudViewToPotreeIndex = (view) => {
  if (view === POINT_CLOUD_MODEL_VIEW.PLAN) return 0;
  if (view === POINT_CLOUD_MODEL_VIEW.SECTION) return 1;
  return -1;
};

export const restorePointCloudDefaultCameras = (PotreeViewer) => {
  if (!PotreeViewer.defaultCameras) return;

  PotreeViewer.scene.views.forEach((view, index) => {
    const camera = PotreeViewer.defaultCameras[index];
    if (!camera) return;

    view.pitch = camera.pitch;
    view.position = new THREE.Vector3(
      camera.position.x,
      camera.position.y,
      camera.position.z,
    );
    view.radius = camera.radius;
    view.yaw = camera.yaw;
  });
};

export const setExtraction3dPoints = (Potree, viewer, crop, extraction) => {
  if (viewer) {
    if (crop) {
      const corners = getRectangleCorners(crop.position, crop.scale.x, crop.scale.y);
      extraction.crop = corners.map((corner) => {
        const rotated = rotate2DPoint(corner, crop.position, crop.rotation.z);
        return [rotated.x, rotated.y];
      });

      const scaleZHalf = crop.scale.z / 2;
      const upperZBounds = crop.position.z + scaleZHalf;
      const lowerZBounds = crop.position.z - scaleZHalf;
      extraction.zbounds = [upperZBounds, lowerZBounds];
    } else {
      extraction.crop = extraction.coords.map((coord) => {
        const { x, y } = get3dPointFrom2dCoords(
          new THREE.Vector2(coord.x, coord.y),
          viewer.scene.cameraO,
          viewer.renderer,
        );
        return [x, y];
      });

      extraction.zbounds = extraction.zBounds.map((z) => {
        const p = get3dPointFrom2dCoords(
          new THREE.Vector2(extraction.coords[0].x, z),
          viewer.scene.cameraO,
          viewer.renderer,
        );
        return p.z;
      });
    }
  }

  return extraction;
};

export const setPointCloudClassification = (pointCloud, classification) => {
  const { material } = pointCloud;
  material.classification = classification;
};

export const setPointCloudDefaultCameras = (PotreeViewer) => {
  PotreeViewer.defaultCameras = PotreeViewer.scene.views.map((view) => ({
    pitch: view.pitch,
    position: {
      x: view.position.x,
      y: view.position.y,
      z: view.position.z,
    },
    radius: view.radius,
    yaw: view.yaw,
  }));
};

export const setPointCloudDefaultMaterial = (Potree, pointCloud) => {
  const { material } = pointCloud;
  material.pointSizeType = 'fixed';
  material.shape = 'circle';
  material.size = 2;
};

export const setPointCloudMaterialAttribute = (pointCloud, pointCloudColorAttribute) => {
  const { material } = pointCloud;
  // material.pointAttribute = pointAttribute;
  material.activeAttributeName = pointCloudColorAttribute;
  // material.activeAttributeName = 'rgb';
};

export const setVisiblePointCloudClassifications = (PotreeViewer, keys, visible) => {
  keys.forEach((key) => {
    PotreeViewer.setClassificationVisibility(key, visible);
  });
};

export const showAllPointCloudClassifications = (PotreeViewer) => {
  const keys = Object.keys(PotreeViewer.classifications);
  setVisiblePointCloudClassifications(PotreeViewer, keys, true);
};

export const showPointCloudCrop = (Potree, PotreeViewer) => {
  const tool = new Potree.VolumeTool(PotreeViewer);
  const crop = tool.startInsertion({ clip: true });

  // We do not support crop volumes rotated along x,y axis.
  PotreeViewer.transformationTool.rotationHandles['rotation.x'].node.visible = false;
  PotreeViewer.transformationTool.rotationHandles['rotation.y'].node.visible = false;

  // VolumeTool follows pointer coordinates by default,
  // we want its position to be static.
  PotreeViewer.dispatchEvent({ type: 'cancel_insertions' });

  PotreeViewer.inputHandler.toggleSelection(crop);

  const { boundingBox } = PotreeViewer.scene.pointclouds[0];
  crop.position.copy(boundingBox.getCenter());
  crop.rotation.set(0, 0, 0);
  crop.scale.copy(boundingBox.getSize());

  return crop;
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getClientLogo } from '../../../helpers';
import { isEditor } from '../../../selectors/router';

import Image from '../../UI/Image';

import logo from '../../../assets/maps-logo.svg';

import s from './index.scss';

function Logo(props) {
  const { t } = useTranslation();

  return (
    <Image
      alt={t('words.logo')}
      className={classNames(s.logo, props.className)}
      placeholder={logo}
      src={getClientLogo(props.editor)}
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  editor: PropTypes.bool.isRequired,
};

Logo.defaultProps = {
  className: undefined,
};

export const PureTestComponent = Logo;

const mapStateToProps = (state) => ({
  editor: isEditor(state),
});

export default connect(
  mapStateToProps,
)(Logo);

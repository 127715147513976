import { regex } from '@helix-re/url-pattern';

const getMatch = (str, pattern) => {
  const regexp = new RegExp(regex.start + pattern);
  return str.match(regexp);
};

export const getBoolean = (pattern) => (pathname) => {
  const match = getMatch(pathname, pattern);
  return !!match;
};

export const getValue = (pattern) => (pathname) => {
  const match = getMatch(pathname, pattern);
  return (match && match[1]) || '';
};

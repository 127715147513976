import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';
import Avatar from 'boring-avatars';

import { PropTypes } from 'constants';
import { CY_PROJECT_SHARE_LIST_LABEL } from 'constants/cypress';
import { isMemberAdmin, isMemberEditor } from 'helpers';

import Alert from 'components/UI/Alert';
import Select from 'components/UI/Select';

import s from './index.scss';

export default function ShareList(props) {
  const { t } = useTranslation();

  const isAdmin = isMemberAdmin(props.user);
  const isEditor = isMemberEditor(props.user);

  return !props.members.length ? null : (
    <div className={s.wrapper}>
      {props.warning && (
      <Alert
        className={s.alert}
        message={props.warning}
        type="warning"
      />
      )}
      <ul className={s.list}>
        {props.members.map((member) => {
          const canUpdate = isEditor || isAdmin;

          let select = null;
          if (props.onChange) {
            select = !canUpdate
              ? props.optionLabels[member.role]
              : (
                <Select
                  dark
                  defaultValue={member.role}
                  disabled={props.changing[member.userId]}
                  onChange={(value) => props.onChange(member.userId, value)}
                  options={props.options}
                />
              );
          }

          return (
            <li
              className={s.item}
              key={member.userId}
            >
              <div className={s.left}>
                <div className={s.avatar}>
                  <Avatar
                    colors={['#FEF6D8', '#ACF0C8', '#25B1BB', '#3A434D', '#FFD9D9']}
                    name={member.userId}
                    size={24}
                    square
                    variant="marble"
                  />
                </div>
                <div className={s.label} data-cy={CY_PROJECT_SHARE_LIST_LABEL}>
                  {member.userId.includes('@') ? member.userId : `@${member.userId}`}
                </div>
                {select}
              </div>
              {/* Viewers can only remove themselves */}
              {(canUpdate || member.userId === props.user.email) && (
              <div className={s.right}>
                {props.removing[member.userId]
                  ? (
                    <Icon
                      className={s.icon}
                      spin
                      type="loading"
                    />
                  )
                  : (
                    <button
                      className={s.remove}
                      onClick={() => props.onRemove(member.userId)}
                      type="button"
                    >
                      {t('words.remove')}
                    </button>
                  )}
              </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ShareList.propTypes = {
  changing: PropTypes.shape(),
  members: PropTypes.arrayOf(PropTypes.Member).isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  optionLabels: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.SelectOption),
  removing: PropTypes.shape().isRequired,
  user: PropTypes.Member.isRequired,
  warning: PropTypes.string,
};

ShareList.defaultProps = {
  changing: {},
  optionLabels: {},
};

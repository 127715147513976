import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <path
      clipRule="evenodd"
      d="M8.9043 8.78516H21.0957C21.648 8.78516 22.0957 9.23287 22.0957 9.78516V21.9766C22.0957 22.5288 21.648 22.9766 21.0957 22.9766H8.9043C8.35201 22.9766 7.9043 22.5288 7.9043 21.9766V9.78516C7.9043 9.23287 8.35201 8.78516 8.9043 8.78516ZM6.9043 9.78516C6.9043 8.68059 7.79973 7.78516 8.9043 7.78516H21.0957C22.2003 7.78516 23.0957 8.68059 23.0957 9.78516V21.9766C23.0957 23.0811 22.2003 23.9766 21.0957 23.9766H8.9043C7.79973 23.9766 6.9043 23.0811 6.9043 21.9766V9.78516ZM11.6631 19.2169V12.543H18.3371V19.2169H11.6631ZM10.6631 12.243C10.6631 11.8564 10.9765 11.543 11.3631 11.543H18.6371C19.0237 11.543 19.3371 11.8564 19.3371 12.243V19.5169C19.3371 19.9035 19.0237 20.2169 18.6371 20.2169H11.3631C10.9765 20.2169 10.6631 19.9035 10.6631 19.5169V12.243Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

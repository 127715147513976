import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as AntButton } from 'antd';

import Link from '../../common/Link';

import s from './index.scss';

const ICON_TYPE = {
  CIRCLE: 'circle',
  SQUARE: 'square',
};

export default function Button(props) {
  const {
    children,
    className,
    danger,
    dangerBorderless,
    transparent,
    dark,
    disabled,
    icon,
    newTab,
    onClick,
    secondary,
    sidebar,
    simple,
    size,
    to,
    type,
    ...rest
  } = props;

  const useLink = to && !disabled;

  const componentProps = useLink ? {
    newTab,
    onClick,
    to,
  } : {
    disabled,
    onClick,
    type,
  };

  const getClassName = () => {
    const common = {
      [s.center]: !Array.isArray(children),
      [s.disabled]: disabled,
      [s.isDanger]: danger,
      [s.isDangerBorderless]: dangerBorderless,
      [s.isTransparent]: transparent,
      [s.isDark]: dark,
      [s.isLight]: !dark && !sidebar,
      [s.isSidebar]: sidebar,
      [s.isPrimary]: !secondary && !simple,
      [s.isSecondary]: secondary,
      [s.isSimple]: simple,
      [s.sizeXXS]: size === 'xxs',
      [s.sizeXS]: size === 'xs',
      [s.sizeMd]: size === 'md',
      [s.sizeLg]: size === 'lg',
      [s.sizeXl]: size === 'xl',
      [s.sizeXXl]: size === 'xxl',
    };

    if (icon) {
      return classNames(s.icon, className, {
        ...common,
        [s.isCircle]: icon === ICON_TYPE.CIRCLE,
      });
    }

    return classNames(s.default, className, {
      'ant-btn ant-btn-button': to,
      [s.small]: size === 'sm',
      ...common,
    });
  };

  const buttonProps = {
    ...componentProps,
    ...rest,
    className: getClassName(),
  };

  if (type === 'submit') {
    return (
      <AntButton
        htmlType={type}
        {...buttonProps}
      >
        {children}
      </AntButton>
    );
  }

  const Component = useLink ? Link : AntButton;

  return (
    <Component {...buttonProps}>
      {children}
    </Component>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  danger: PropTypes.bool,
  dangerBorderless: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf([
    true,
    ICON_TYPE.CIRCLE,
  ]),
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  sidebar: PropTypes.bool,
  simple: PropTypes.bool,
  size: PropTypes.string,
  to: PropTypes.string,
  transparent: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  danger: false,
  dangerBorderless: false,
  dark: false,
  disabled: false,
  newTab: false,
  secondary: false,
  sidebar: false,
  simple: false,
  size: 'md',
  to: '',
  transparent: false,
  type: 'button',
};

import React from 'react';
import SvgIcon from 'components/UI/Icons';

export default function IconCaretDown(props) {
  return (
    <SvgIcon {...props}>
      <path clipRule="evenodd" d="M7.6585 9.24744L6.34149 10.7526L12 15.7038L17.6585 10.7526L16.3415 9.24744L12 13.0462L7.6585 9.24744Z" fill="currentColor" fillRule="evenodd" />
    </SvgIcon>
  );
}

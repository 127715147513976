import { SCENE_HOTSPOT_TYPE } from '../constants';
import { stopPropagation } from './event';

// Prevent touch and scroll events from reaching the parent element.
const stopTouchAndScrollEventPropagation = (element) => {
  const events = [
    'mousewheel',
    'touchcancel',
    'touchend',
    'touchmove',
    'touchstart',
    'wheel',
  ];

  events.forEach((event) => {
    element.addEventListener(event, stopPropagation);
  });
};

const createHotspotElement = (show) => {
  // Wrapper will hold icon and tooltip.
  const wrapper = document.createElement('div');

  // Prevent the view control logic from interfering with the hotspot.
  if (show) {
    stopTouchAndScrollEventPropagation(wrapper);
  }

  return wrapper;
};

export const getHotspotData = (hotspot) => hotspot.params || {};

export const setHotspotData = (hotspot, data) => {
  hotspot.params = data;
};

const filterSceneHotspotAnnotations = (hotspot) => {
  const { type } = getHotspotData(hotspot);

  return type === SCENE_HOTSPOT_TYPE.ANNOTATION;
};

const filterSceneHotspotLinks = (hotspot) => {
  const { type } = getHotspotData(hotspot);

  return type !== SCENE_HOTSPOT_TYPE.ANNOTATION;
};

export const getHotspotContainer = (scene) => scene.hotspotContainer();

export const createHotspot = (scene, coords, data, show) => {
  const domElement = createHotspotElement(show);
  const hotspotContainer = getHotspotContainer(scene);
  const hotspot = hotspotContainer.createHotspot(domElement, coords);
  setHotspotData(hotspot, data);
  return hotspot;
};

export const getHotspotList = (scene) => {
  const hotspotContainer = getHotspotContainer(scene);

  return hotspotContainer.listHotspots();
};

export const getHotspotPosition = (hotspot) => hotspot.position();

export const getSceneHotspotAnnotations = (scene) => getHotspotList(scene)
  .filter(filterSceneHotspotAnnotations);

export const getSceneHotspotLinks = (scene) => getHotspotList(scene)
  .filter(filterSceneHotspotLinks);

export const getSceneView = (viewer) => viewer.view();

export const setHotspotPosition = (hotspot, coords) => hotspot.setPosition(coords);

export const viewCoordinatesToScreen = (viewer, coords) => {
  const view = getSceneView(viewer);
  return view.coordinatesToScreen(coords);
};

export const viewScreenToCoordinates = (viewer, screen) => {
  const view = getSceneView(viewer);
  return view.screenToCoordinates(screen);
};

import React from 'react';
import PropTypes from 'prop-types';
import Svg from '.';

export default function IconLoading(props) {
  const { color, ...rest } = props;
  // ID must be unique, otherwise we could have conflicting
  // definitions and the first one to be loaded would "win."
  const id = `loading-linear-gradient-${color}`;
  return (
    <Svg
      color={color}
      viewBox="0 0 45 45"
      {...rest}
    >
      <defs>
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 46)"
          gradientUnits="userSpaceOnUse"
          id={id}
          x1="2.341"
          x2="41.318"
          y1="13.41"
          y2="35.913"
        >
          <stop
            offset=".344"
            stopColor="currentColor"
            stopOpacity="0"
          />
          <stop
            offset=".432"
            stopColor="currentColor"
            stopOpacity=".204"
          />
          <stop
            offset=".548"
            stopColor="currentColor"
            stopOpacity=".443"
          />
          <stop
            offset=".659"
            stopColor="currentColor"
            stopOpacity=".642"
          />
          <stop
            offset=".763"
            stopColor="currentColor"
            stopOpacity=".797"
          />
          <stop
            offset=".857"
            stopColor="currentColor"
            stopOpacity=".908"
          />
          <stop
            offset=".939"
            stopColor="currentColor"
            stopOpacity=".976"
          />
          <stop
            offset="1"
            stopColor="currentColor"
          />
        </linearGradient>
      </defs>
      <path
        d="M22.5 42.5a20 20 0 1 1 20-20"
        fill="none"
        stroke={`url(#${id})`}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="5px"
      />
    </Svg>
  );
}

IconLoading.propTypes = {
  color: PropTypes.string,
};

IconLoading.defaultProps = {
  color: 'currentColor',
};

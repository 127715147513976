import { v5 } from 'uuid';

// This will always generate the same UUID for a given value
const generateUUID = (value) => v5(value, 'e3513f06-26be-49d1-9081-63979b60ea42');

export const CY_GALLERY_TWIN_LIST_TITLE = generateUUID('1');
export const CY_PROJECT_HEADER_BUTTONS = generateUUID('2');
export const CY_PROJECT_SHARE_MODAL = generateUUID('3');
export const CY_PROJECT_SHARE_LIST_LABEL = generateUUID('4');
export const CY_HEADER_SPACESELECT_SELECT = generateUUID('5');
export const CY_HEADER_SPACESELECT_ITEM = generateUUID('6');
export const CY_MAPEDITOR_POPOVER = generateUUID('7');
export const CY_DROPZONE_ROOT = generateUUID('8');
export const CY_COMMON_MINIMAP_ROOT = generateUUID('9');
export const CY_COMMON_SCENELIST = generateUUID('11');
export const CY_COMMON_VIEWER360_ROOT = generateUUID('12');
export const CY_COMMON_VIEWERIFSCENE_PLACEHOLDER = generateUUID('13');
export const CY_SCENESELECT_HEADER_CIRCULARBUTTON = generateUUID('14');
export const CY_MINIMAP_RAWMINIMAP_SVGCONTAINER = generateUUID('15');
export const CY_HEADER_PUBLISH_PUBLISHBUTTON = generateUUID('16');

import querystring from 'querystring';

export const parseSearch = (search) => {
  const str = search.startsWith('?') ? search.substring(1) : search;
  return querystring.parse(str);
};

export const stringifySearch = (params, useQueryString) => (useQueryString
  ? querystring.stringify(params)
  : Object.entries(params)
    .map(([key, value]) => {
      const encodedValue = encodeURIComponent(value);
      return Array.isArray(value)
        ? `${key}=${JSON.stringify(value)}`
        : `${key}=${encodedValue}`;
    })
    .join('&'));

import { FLOORPLAN_MODES } from 'constants/minimap';

export const getMarkerSVGCoords = (marker) => ({
  x: Number.parseFloat(marker.getAttribute('cx')),
  y: Number.parseFloat(marker.getAttribute('cy')),
});

export const showBoundingBox = (mode) => [
  FLOORPLAN_MODES.MARQUEE,
  FLOORPLAN_MODES.MOVE,
].includes(mode);

// TODO: Use svg instead of mapHeight
export const svgCoordsToMapCoords = (coords, mapHeight) => ({
  x: coords.x,
  y: mapHeight - coords.y,
});

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    viewBox="0 0 24 24"
    {...ICON_PROPS}
    {...props}
  >
    <path clipRule="evenodd" d="M12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12ZM12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10Z" fill="currentColor" fillRule="evenodd" />
    <path d="M4 22H6L6 17C6 16.4477 6.44772 16 7 16L16 16V14L7 14C5.34315 14 4 15.3431 4 17L4 22Z" fill="currentColor" />
    <path d="M18 16H20V18L22 18V20H20V22H18V20H16L16 18H18V16Z" fill="currentColor" />
  </Svg>
);

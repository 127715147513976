import { noop } from './function';
import { ensureNoSlash, ensureSlash } from './string';
import { stringifySearch } from './url';

export const collect = (defaultValue, children, paths = []) => {
  const add = (path) => {
    if (!paths.includes(path)) {
      paths = [...paths, path];
    }
  };

  const obj = (o) => collect(defaultValue, o, paths).forEach(add);

  Object.values(children).forEach((child) => {
    if (typeof child === 'function') {
      const value = child(defaultValue);

      if (typeof value === 'string') {
        add(value);
      } else {
        obj(value);
      }
    } else if (typeof child === 'object') {
      obj(child);
    }
  });

  return paths;
};

export const required = (defaultValue, children, value) => {
  const resolvedValue = value || defaultValue;

  const returnFunction = (childrenFunc) => (...args) => {
    const childrenFuncResponse = childrenFunc(...args) || '';

    if (typeof childrenFuncResponse === 'object') {
      return required(resolvedValue, childrenFuncResponse);
    }

    if (childrenFuncResponse) {
      if (childrenFuncResponse.startsWith('?')) {
        return ensureNoSlash(resolvedValue) + childrenFuncResponse;
      }

      return ensureSlash(resolvedValue) + childrenFuncResponse;
    }

    return resolvedValue;
  };

  const defineCommonProperties = (childrenObj) => {
    if (!childrenObj.params) {
      childrenObj.params = (params) => (params
        ? `?${stringifySearch(params)}`
        : '');
    }

    if (!childrenObj.root) {
      childrenObj.root = noop;
    }

    return childrenObj;
  };

  const returnObject = (childrenObj) => {
    const response = defineCommonProperties(childrenObj);

    return Object.entries(response)
      .reduce((acc, [key, child]) => {
        if (typeof child === 'function') {
          acc[key] = returnFunction(child);
        } else if (typeof child === 'object') {
          acc[key] = required(resolvedValue, child);
        }
        return acc;
      }, response);
  };

  return typeof children === 'function'
    ? returnFunction(children)
    : returnObject({ ...children });
};

export const optional = (defaultValue, children) => (value) => required(
  defaultValue,
  children,
  value,
);

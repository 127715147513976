import update from 'immutability-helper';
import { RECEIVE_FORGE_TOKEN, RECEIVE_POTREE_SIGNED_URLS } from '../actions/types';
import initialState from '../initialState';

export default (state = initialState.viewers, action) => {
  switch (action.type) {
    case RECEIVE_FORGE_TOKEN:
      return update(state, {
        forge: {
          token: {
            $set: action.payload.token,
          },
          tokenReceivedAt: {
            $set: action.payload.tokenReceivedAt,
          },
        },
      });
    case RECEIVE_POTREE_SIGNED_URLS:
      return update(state, {
        potree: {
          [action.payload.previewLink]: {
            $set: action.payload,
          },
        },
      });
    default:
      return state;
  }
};

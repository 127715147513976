import { colors, GoogleMapsStyles } from 'styles/variables';

// The Earth is not a perfect spehere.
// See https://en.wikipedia.org/wiki/Earth_radius
export const EARTH_RADIUS_DIFFERENCE = 22e3;

export const GOOGLE_MAPS_DEFAULT_CONFIG = {
  backgroundColor: colors['gray-300'],
  disableDoubleClickZoom: true,
  fullscreenControl: false,
  mapTypeControl: false,
  minZoom: 2,
  scrollwheel: false,
  streetViewControl: false,
  styles: GoogleMapsStyles,
  zoom: 2,
};

export const MAPS_SORT = {
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  PUBLISHED_ASC: 'PUBLISHED_ASC',
  PUBLISHED_DESC: 'PUBLISHED_DESC',
  SIZE_ASC: 'SIZE_ASC',
  SIZE_DESC: 'SIZE_DESC',
  STATUS: 'STATUS',
};

export const MAPS_SORT_KEYS = {
  NAME_ASC: 'name',
  NAME_DESC: 'name',
  PUBLISHED_ASC: 'lastPublished',
  PUBLISHED_DESC: 'lastPublished',
  SIZE_ASC: 'estimatedArea',
  SIZE_DESC: 'estimatedArea',
  STATUS: 'deliveryStatus',
};

export const MAPS_SORT_LABELS = {
  NAME_ASC: 'A-Z',
  NAME_DESC: 'Z-A',
  PUBLISHED_ASC: 'Old-New',
  PUBLISHED_DESC: 'New-Old',
  SIZE_ASC: 'Small-Large',
  SIZE_DESC: 'Large-Small',
  STATUS: 'Status',
};

export const MAPS_SORT_ORDER = [
  MAPS_SORT.STATUS,
  MAPS_SORT.PUBLISHED_DESC,
  MAPS_SORT.PUBLISHED_ASC,
  MAPS_SORT.SIZE_DESC,
  MAPS_SORT.SIZE_ASC,
  MAPS_SORT.NAME_ASC,
  MAPS_SORT.NAME_DESC,
];

export const MAPS_SORT_OPTIONS = MAPS_SORT_ORDER
  .map((value) => ({ label: MAPS_SORT_LABELS[value], value }));

export const MAPS_STYLES = (Color) => ({
  cluster: Color.fromCssColorString(colors.black),
  font: '32px Open Sans, sans-serif',
  scene: Color.fromCssColorString(colors['gray-800']),
});

// Map zoom levels. Similar to how Google Maps define zoom in their API. The
// difference is that Density Maps levels start with the most zoomed in value.
// All distances are in metric units.
export const MAPS_ZOOM_LEVELS = [
  {
    distance: 2e2,
    panX: 7e-6,
    panY: 4e-6,
  },
  {
    distance: 8e2,
    panX: 3e-5,
    panY: 2e-5,
  },
  {
    distance: 2e3,
    panX: 8e-5,
    panY: 4e-5,
  },
  {
    distance: 9e3,
    panX: 32e-5,
    panY: 18e-5,
  },
  {
    distance: 7e4,
    panX: 25e-4,
    panY: 14e-4,
  },
  {
    distance: 3e5,
    panX: 88e-4,
    panY: 68e-4,
  },
  {
    distance: 1e6,
    panX: 3e-2,
    panY: 2e-2,
  },
  {
    distance: 4e6,
    panX: 2e-1,
    panY: 15e-2,
  },
  {
    distance: 12e6,
    latLimit: 80,
    panX: 7e-1,
    panY: 7e-1,
  },
  {
    distance: 26e6,
    latLimit: 70,
    panX: 1,
    panY: 1,
  },
].map((level, index) => ({ ...level, level: index }));

// Do not uppercase, we use these values to look up i18n keys.
export const MAPS_MODE = {
  MAP: 'map',
  SATELLITE: 'satellite',
};

import cookie from 'cookie';
import formurlencoded from 'form-urlencoded';
import { formHeaders, jsonHeaders, DENSITY_PLANS_TOKEN } from 'constants';
import axios from 'axios';

const nullsToEmptyStrings = (payload) => Object.keys(payload).reduce((acc, curr) => {
  if (payload[curr] === null) {
    acc[curr] = '';
  } else if (Object.prototype.toString.call(payload[curr]) === '[object Object]') {
    acc[curr] = nullsToEmptyStrings(payload[curr]);
  } else {
    acc[curr] = payload[curr];
  }
  return acc;
}, {});

const withCsrf = (payload) => ({
  _csrf: cookie.parse(document.cookie)['XSRF-TOKEN'],
  ...payload,
});

export const createAssetFormData = (params) => {
  const { thumbnail, ...values } = params;
  const blob = new Blob([JSON.stringify(values)], { type: 'application/json' });
  const formData = new FormData();

  if (thumbnail) {
    formData.append('thumbnail', thumbnail);
  }
  formData.append('assetViewData', blob, 'assetViewData.json');

  return formData;
};

export const formBody = (params) => ({
  body: formurlencoded(nullsToEmptyStrings(withCsrf(params))),
  headers: formHeaders,
});

export const jsonBody = (params) => ({
  body: JSON.stringify(params),
  headers: jsonHeaders,
});

export const objectToFormData = (src) => Object.entries(src)
  .reduce((acc, [key, value]) => {
    acc.append(key, value);
    return acc;
  }, new FormData());

export const fetchDensitySensors = async () => {
  try {
    return await axios.get('https://api.density.io/v1/org_717836024721441650/plans/pln_1040046761453813866', {
      headers: {
        // eslint-disable-next-line
        Authorization: `bearer ${DENSITY_PLANS_TOKEN}`,
      },
    }).then(({ data }) => data);
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error', e);
    return null;
  }
};

const format = (values) => values.map((ext) => (ext.includes('/') || ext[0] === '.' ? ext : `.${ext}`));

const GIF = 'image/gif';
const JPG = 'image/jpeg';
const PNG = 'image/png';
const SVG = 'image/svg+xml';
const TIF = 'image/tiff';
const ZIP = 'zip';
const MP4 = 'mp4';

const tourScene = [
  JPG,
  PNG,
  MP4,
];

export const ACCEPT_FLOOR_PLANS = format([
  JPG,
  PNG,
  SVG,
  TIF,
]);

export const ACCEPT_FLOOR_PLANS_SIZE_LIMIT = 1e7; // 10MB

export const ACCEPT_POINT_CLOUDS = format([
  'e57',
  'las',
  'laz',
  'rcs',
]);

export const ACCEPT_THUMBNAILS = format([
  GIF,
  JPG,
  PNG,
  SVG,
]);

export const ACCEPT_TOUR_SCENE = format(tourScene);

export const ACCEPT_TOUR_SCENES = format([
  ...tourScene,
  ZIP,
  MP4,
]);

import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg {...props}>
    <path
      d="M14.0893 9.12891H16.7143V22.5664H14.0893V16.0499M14.0893 9.12891V13.7429M14.0893 9.12891V11.4359H9.41747L8.40997 10.2824L9.41747 9.12891H14.0893ZM14.0893 16.0499H11.4487L10.45 14.8964L11.4487 13.7429H14.0893M14.0893 16.0499V13.7429M16.7143 11.4359V13.7429H21.3862L22.3898 12.5894L21.3862 11.4359H16.7143Z"
      stroke="currentColor"
    />
  </Svg>
);

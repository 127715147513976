import React from 'react';

import { PropTypes } from 'constants/propTypes';

export default function Svg(props) {
  const {
    children,
    height,
    size,
    width,
    ...rest
  } = props;
  const h = height !== undefined ? height : size;
  const w = width !== undefined ? width : size;

  return (
    <svg
      fill="none"
      height={h}
      version="1.1"
      width={w}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </svg>
  );
}

Svg.propTypes = {
  children: PropTypes.Node.isRequired,
  color: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Svg.defaultProps = {
  color: 'currentColor',
  size: 30,
  viewBox: '0 0 30 30',
};

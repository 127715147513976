import update from 'immutability-helper';

import {
  ADD_TO_EDITOR_SELECTED_LINKS,
  ADD_TO_EDITOR_SELECTED_SCENE_IDS,
  CLOSE_EDITOR_PANELS,
  CLOSE_LAUNCHER,
  OPEN_EDITOR_PANELS,
  REMOVE_FROM_EDITOR_SELECTED_LINKS,
  REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS,
  SET_EDITOR_COPIED_ANNOTATION,
  SET_EDITOR_FILTER,
  SET_EDITOR_FILTERS,
  SET_EDITOR_IS_VISIBLE_SIDEBAR,
  SET_EDITOR_PREVIEW,
  SET_EDITOR_SELECTED_LINKS,
  SET_EDITOR_SELECTED_MODEL_CARD,
  SET_EDITOR_SELECTED_SCENE_CURRENT_TIME,
  SET_EDITOR_SELECTED_SCENE_DURATION,
  SET_EDITOR_SELECTED_SCENE_IDS,
  SET_EDITOR_SELECTED_SCENE_IDS_TIME,
  SET_SHOULD_FOCUS_SCENE_SETTINGS,
  SET_TOUR_EDITOR_FOCUSED_CONSTELLATION,
  TOGGLE_LAUNCHER,
} from 'actions/types';
import initialState from 'initialState';
import { getEditorSelectedSceneIds } from 'selectors/editor';

export default (state = initialState.editor, action) => {
  switch (action.type) {
    case SET_EDITOR_COPIED_ANNOTATION:
      return update(state, {
        copiedAnnotation: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_FILTER:
      return update(state, {
        filters: {
          $merge: {
            [action.payload.filter]: action.payload.value,
          },
        },
      });
    case SET_EDITOR_FILTERS:
      return update(state, {
        filters: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_PREVIEW:
      return update(state, {
        isPreview: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_IS_VISIBLE_SIDEBAR:
      return update(state, {
        isVisibleSidebar: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_SELECTED_LINKS:
      return update(state, {
        selectedLinks: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_SELECTED_SCENE_IDS:
      return update(state, {
        selectedModelCard: {
          $set: !action.payload || action.payload.length === 0
            ? state.selectedModelCard
            : {},
        },
        selectedSceneIds: {
          $set: Array.isArray(action.payload) ? action.payload : [action.payload],
        },
      });
    case SET_EDITOR_SELECTED_SCENE_IDS_TIME:
      return update(state, {
        selectedSceneIdsTime: {
          $set: Array.isArray(action.payload) ? action.payload : [action.payload],
        },
      });
    case SET_EDITOR_SELECTED_SCENE_CURRENT_TIME:
      return update(state, {
        selectedSceneCurrentTime: {
          $set: action.payload,
        },
      });
    case SET_EDITOR_SELECTED_SCENE_DURATION:
      return update(state, {
        selectedSceneDuration: {
          $set: action.payload,
        },
      });
    case ADD_TO_EDITOR_SELECTED_LINKS:
      return update(state, {
        selectedLinks: {
          $apply: (selectedLinks) => {
            const newSelectedLinks = {
              ...selectedLinks,
            };
            newSelectedLinks[action.payload.id] = {
              ...newSelectedLinks[action.payload.id],
              [action.payload.otherId]: true,
            };
            return newSelectedLinks;
          },
        },
      });
    case ADD_TO_EDITOR_SELECTED_SCENE_IDS:
      return update(state, {
        selectedModelCard: {
          $set: {},
        },
        selectedSceneIds: {
          $apply: (selectedSceneIds) => {
            const newIds = Array.isArray(action.payload) ? action.payload : [action.payload];
            const newSelectedSceneIds = selectedSceneIds.filter((id) => !newIds.includes(id));
            newIds.forEach((id) => newSelectedSceneIds.push(id));
            return newSelectedSceneIds;
          },
        },
      });
    case REMOVE_FROM_EDITOR_SELECTED_LINKS:
      return update(state, {
        selectedLinks: {
          $apply: (selectedLinks) => {
            const newSelectedLinks = {
              ...selectedLinks,
            };
            newSelectedLinks[action.payload.id] = {
              ...newSelectedLinks[action.payload.id],
              [action.payload.otherId]: false,
            };
            newSelectedLinks[action.payload.otherId] = {
              ...newSelectedLinks[action.payload.otherId],
              [action.payload.id]: false,
            };
            return newSelectedLinks;
          },
        },
      });
    case REMOVE_FROM_EDITOR_SELECTED_SCENE_IDS: {
      const selectedSceneIds = getEditorSelectedSceneIds({ editor: state });
      const index = selectedSceneIds.indexOf(action.payload);
      if (index !== -1) {
        return update(state, {
          selectedSceneIds: {
            $splice: [[index, 1]],
          },
        });
      }
      return state;
    }
    case CLOSE_EDITOR_PANELS:
      return update(state, {
        openPanels: {
          $merge: action.payload.reduce((acc, curr) => {
            acc[curr] = false;
            return acc;
          }, {}),
        },
      });
    case OPEN_EDITOR_PANELS:
      return update(state, {
        openPanels: {
          $merge: action.payload.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {}),
        },
      });
    case SET_SHOULD_FOCUS_SCENE_SETTINGS:
      return update(state, {
        shouldFocusSceneSettings: {
          $set: action.payload,
        },
      });

    case SET_EDITOR_SELECTED_MODEL_CARD:
      return update(state, {
        selectedModelCard: {
          assetViewId: {
            $set: action.payload.assetViewId,
          },
          modelId: {
            $set: action.payload.modelId,
          },
        },
        selectedSceneIds: {
          $set: [],
        },
      });
    case CLOSE_LAUNCHER:
      return update(state, {
        launcherOpen: {
          $set: false,
        },
      });
    case TOGGLE_LAUNCHER:
      return update(state, {
        launcherOpen: {
          $set: !state.launcherOpen,
        },
      });
    case SET_TOUR_EDITOR_FOCUSED_CONSTELLATION:
      return update(state, {
        focusedConstellation: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../Typography/Label';

import s from './index.scss';

export default function LabelError(props) {
  return !props.value ? null : (
    <Label
      className={s.label}
      tag="p"
    >
      {props.value}
    </Label>
  );
}

LabelError.propTypes = {
  value: PropTypes.string,
};

LabelError.defaultProps = {
  value: undefined,
};

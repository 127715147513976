import React from 'react';
import IconLoading from '../../../assets/svg/IconLoading';
import s from './index.scss';

export default function Loading(props) {
  return (
    <div className={s.root}>
      <IconLoading {...props} />
    </div>
  );
}

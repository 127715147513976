import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sendErrorToTracker } from 'actions/errors';

import ErrorPage from 'components/common/ErrorPage';

class ErrorBoundary extends React.Component {
  state = {
    error: false,
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error, info) {
    this.props.sendErrorToTracker(error, info);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    return error ? <ErrorPage /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  sendErrorToTracker: PropTypes.func.isRequired,
};

export const PureTestComponent = ErrorBoundary;

const mapDispatchToProps = {
  sendErrorToTracker,
};

export default connect(
  null,
  mapDispatchToProps,
)(ErrorBoundary);

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path d="M11 5.99998H13L13 13H11L11 5.99998Z" fill="currentColor" />
    <path d="M13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6715 11.1716 15 12 15C12.8284 15 13.5 15.6715 13.5 16.5Z" fill="currentColor" />
    <path clipRule="evenodd" d="M21.6603 17.5773L21.6603 6.42263L12 0.845276L2.33975 6.42263L2.33975 17.5773L12 23.1547L21.6603 17.5773ZM4.33975 7.57733L12 3.15468L19.6603 7.57733L19.6603 16.4226L12 20.8453L4.33975 16.4226L4.33975 7.57733Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';

import Button from 'components/UI/Button';
import IconMicrosoft from 'assets/svg/IconMicrosoft';

import authStyles from '../auth.scss';

export function ButtonMicrosoft({
  className,
  disabled,
  label,
  loading,
  onClick,
}) {
  return (
    <Button
      className={classNames(authStyles.buttonSignIn, authStyles.buttonMicrosoft, className)}
      dark
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      <IconMicrosoft />
      {label}
    </Button>
  );
}

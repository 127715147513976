// Rename to `MINIMAP_TOOL`, uppercase strings, remove lowercase keys.
export const FLOORPLAN_MODES = {
  link: 'link',
  LINK: 'link',
  marquee: 'marquee',
  MARQUEE: 'marquee',
  move: 'move',
  MOVE: 'move',
  select: 'select',
  SELECT: 'select',
  view: 'view',
  VIEW: 'view',
};

export const LEGACY_MARKER_SIZE = 14;
export const LEGACY_BOUNDING_BOX_PADDING = LEGACY_MARKER_SIZE + 4;
export const PLAN_MARKER_SIZE = 20;

import React from 'react';
import { Tooltip as AntTooltip } from 'antd';

export default function Tooltip(props) {
  return (
    <AntTooltip
      destroyTooltipOnHide
      mouseEnterDelay={0.4}
      mouseLeaveDelay={0.1}
      {...props}
    />
  );
}

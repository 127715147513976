export const capitalize = (key) => {
  if (!key) {
    return key;
  }
  const [firstLetter, ...rest] = key;
  return firstLetter.toUpperCase() + rest.join('');
};

export const ensureNoSlash = (value) => (value.endsWith('/')
  ? value.slice(0, value.length - 1)
  : value);

export const ensureSlash = (value) => (value.endsWith('/')
  ? value
  : `${value}/`);

export const stripTails = (value, tails) => {
  for (let i = 0; i < tails.length; i += 1) {
    const tail = tails[i];
    if (value.endsWith(tail)) {
      return value.slice(0, -tail.length);
    }
  }
  return value;
};

import update from 'immutability-helper';
import { normalize } from 'normalizr';

import {
  RECEIVE_DELETE_POINT_CLOUD,
  RECEIVE_DELETE_POINT_CLOUDS,
  RECEIVE_PATCH_POINT_CLOUD,
  RECEIVE_PATCH_POINT_CLOUDS,
  RECEIVE_POINT_CLOUD_SIGNATURES,
  RECEIVE_POINT_CLOUD,
  RECEIVE_POINT_CLOUDS,
  RECEIVE_POST_MERGE_POINT_CLOUD,
  RECEIVE_POST_POINT_CLOUD,
  RECEIVE_POINT_CLOUD_DERIVATIVES,
} from '../actions/types';
import { getValue, mergeNested, updateDefault } from '../helpers/immutability';
import initialState from '../initialState';
import { pointCloudSchema } from '../schemas';

export default (state = initialState.pointClouds, action) => {
  switch (action.type) {
    // We merge states to update only those attributes that changed.
    // Otherwise, we would replace the state completely, which might
    // lead to us clearing values that existed before, but were not part of
    // the response. As an example, suppose response A defines the property
    // "a" and response B defines the property "b". Calling them in the order
    // ABA with this method will give us an object with both "a" and "b".
    // Without it, we would end up with an object containing only "a".
    case RECEIVE_POINT_CLOUDS: {
      const normalized = normalize(action.payload, [pointCloudSchema]);
      return update(state, {
        items: {
          entities: {
            pointClouds: updateDefault({}, {
              $apply: mergeNested(getValue(normalized.entities.pointClouds, {})),
            }),
          },
          result: {
            $set: normalized.result,
          },
        },
      });
    }
    case RECEIVE_POST_POINT_CLOUD:
    case RECEIVE_POST_MERGE_POINT_CLOUD:
      return update(state, {
        items: {
          entities: {
            $apply: (entities) => {
              if (entities.pointClouds) {
                return update(entities, {
                  pointClouds: {
                    $merge: {
                      [action.payload.id]: action.payload,
                    },
                  },
                });
              }
              return {
                ...entities,
                pointClouds: {
                  [action.payload.id]: action.payload,
                },
              };
            },
          },
          result: {
            $push: [action.payload.id],
          },
        },
      });
    case RECEIVE_POINT_CLOUD:
    case RECEIVE_PATCH_POINT_CLOUD:
      return update(state, {
        items: {
          entities: {
            pointClouds: {
              $merge: {
                [action.payload.id]: action.payload,
              },
            },
          },
        },
      });
    case RECEIVE_PATCH_POINT_CLOUDS:
      return update(state, {
        items: {
          entities: {
            pointClouds: {
              $apply: (x) => {
                const toMerge = action.payload.pointCloudIds.reduce((acc, id) => ({
                  ...acc,
                  [id]: {
                    ...x[id],
                    spaceIds: action.payload.spaceIds,
                  },
                }), {});
                return {
                  ...x,
                  ...toMerge,
                };
              },
            },
          },
        },
      });
    case RECEIVE_DELETE_POINT_CLOUD:
      return update(state, {
        items: {
          result: {
            $splice: [[state.items.result.indexOf(action.payload), 1]],
          },
        },
      });
    case RECEIVE_DELETE_POINT_CLOUDS:
      return update(state, {
        items: {
          result: {
            $set: state.items.result.filter((x) => !action.payload.includes(x)),
          },
        },
      });
    case RECEIVE_POINT_CLOUD_SIGNATURES:
      return update(state, {
        items: {
          entities: {
            $apply: (entities) => {
              if (entities.signatures) {
                return update(entities, {
                  signatures: {
                    $merge: {
                      [action.payload.id]: action.payload,
                    },
                  },
                });
              }
              return {
                ...entities,
                signatures: {
                  [action.payload.id]: action.payload,
                },
              };
            },
          },
        },
      });
    case RECEIVE_POINT_CLOUD_DERIVATIVES:
      return update(state, {
        items: {
          entities: {
            $apply: (entities) => {
              if (entities.derivatives) {
                return update(entities, {
                  derivatives: {
                    $merge: {
                      [action.payload.id]: action.payload,
                    },
                  },
                });
              }
              return {
                ...entities,
                derivatives: {
                  [action.payload.id]: action.payload,
                },
              };
            },
          },
        },
      });
    default:
      return state;
  }
};

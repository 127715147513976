import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import IconDraw from 'assets/svg/IconDraw';
import IconBuilding from 'assets/svg/IconBuilding';
import IconCaretRight from 'assets/svg/IconCaretRight';
import { toggleLauncher } from '../../../../actions/editor';
import { PropTypes, ROUTES } from '../../../../constants';
import { getProject } from '../../../../selectors/project';

import s from './index.scss';

function HeaderProject(props) {
  const { launcherOpen, project } = props;

  return (
    <div className={s.container}>
      <button
        className={classNames(s.hamburger, { [s.open]: launcherOpen })}
        onClick={props.toggleLauncher}
        type="button"
      >
        Menu
      </button>

      <div className={s.homeButton}>
        <Link className={s.logo} to={ROUTES.editor.root()}>
          <IconDraw size={20} />
        </Link>
        Maps Editor
      </div>

      <div className={s.projectNav}>
        {project && (
          <>
            <IconCaretRight className={s.projectNavDivider} size={20} />
            <div className={s.projectNavItem}>
              <IconBuilding size={18} />
              {project.config.name}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

HeaderProject.propTypes = {
  launcherOpen: PropTypes.bool.isRequired,
  project: PropTypes.Project,
  toggleLauncher: PropTypes.func.isRequired,
};

HeaderProject.defaultProps = {
  project: undefined,
};

export const PureTestComponent = HeaderProject;

const mapStateToProps = (state) => ({
  launcherOpen: state.editor.launcherOpen,
  project: getProject(state),
});

const mapDispatchToProps = {
  toggleLauncher,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderProject);

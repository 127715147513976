import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { ACL_ROLES, EMAIL_REGEX, PropTypes } from 'constants';
import {
  addToObject,
  capitalize,
  getFormError,
  isMemberAdmin,
  isMemberEditor,
  removeFromObject,
} from 'helpers';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

import List from '../List';

import s from './index.scss';

export default function IndividualAccess(props) {
  const { t } = useTranslation();

  const [pendingRemove, setPendingRemove] = useState({});
  const [pendingRoleUpdate, setPendingRoleUpdate] = useState({});

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      role: ACL_ROLES.USER,
    },
  });

  const onChange = (email, role) => {
    setPendingRoleUpdate(addToObject(pendingRoleUpdate, email));

    return props.onChange(email, role)
      .then(() => {
        setPendingRoleUpdate(removeFromObject(pendingRoleUpdate, email));
        message.success(t('share.updateSuccess', { email }));
      });
  };

  const onRemove = (email) => {
    setPendingRemove(addToObject(pendingRemove, email));

    return props.onRemove(email)
      .then(() => {
        setPendingRemove(removeFromObject(pendingRemove, email));
        message.success(t('share.removeSuccess', { email }));
      });
  };

  const onSubmit = (data) => {
    clearError('email');

    // Create an array of lowercased values without whitespace.
    let emails = data.email.split(/[;, ]+/g)
      .map((x) => x.toLowerCase().trim());

    // Discard invalid values.
    emails = emails.filter((x) => x && EMAIL_REGEX.test(x));
    if (!emails.length) {
      setError('email', 'invalid', t('share.emailNotValid'));
      return undefined;
    }

    // Discard duplicate values.
    emails = emails.filter((x, i) => emails.indexOf(x) === i);

    // Discard already invited emails.
    emails = emails.filter((x) => !props.members.find((y) => y.userId === x));
    if (!emails.length) {
      setError('email', 'duplicate', t('share.alreadyInvited', { count: emails.length }));
      return undefined;
    }

    return props.onInvite({
      emails: emails.join(','),
      role: data.role,
    })
      .then(() => {
        message.success(t('share.inviteSuccess', { count: emails.length, emails }));
        reset();
      });
  };

  const isAdmin = isMemberAdmin(props.user);
  const isEditor = isMemberEditor(props.user);

  const optionLabels = {
    [ACL_ROLES.ADMIN]: capitalize(t('words.admin')),
    [ACL_ROLES.EDITOR]: capitalize(t('words.editor')),
    [ACL_ROLES.USER]: capitalize(t('words.viewer')),
  };

  const options = [
    {
      label: optionLabels[ACL_ROLES.USER],
      value: ACL_ROLES.USER,
    },
    {
      label: optionLabels[ACL_ROLES.ADMIN],
      value: ACL_ROLES.ADMIN,
    },
    isEditor && {
      label: optionLabels[ACL_ROLES.EDITOR],
      value: ACL_ROLES.EDITOR,
    },
  ].filter(Boolean);

  return (
    <>
      <div className={s.title}>
        {t('share.individualAccess')}
      </div>
      {(isAdmin || isEditor) ? (
        <form
          className={s.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            className={s.input}
            dark
            disabled={formState.isSubmitting}
            error={getFormError(errors, 'email')}
            name="email"
            placeholder={t('share.individualPlaceholder')}
            ref={register({
              required: t('form.email.validate'),
            })}
          />
          <div className={s.select}>
            <Select
              dark
              defaultValue={ACL_ROLES.USER}
              innerRef={register({
                name: 'role',
              })}
              name="role"
              onChange={(value) => setValue('role', value)}
              options={options}
            />
          </div>
          <Button
            className={s.button}
            dark
            loading={formState.isSubmitting}
            type="submit"
          >
            {t('share.inviteButton')}
          </Button>
        </form>
      ) : (
        <p className={s.message}>
          {t('share.adminMessage')}
        </p>
      )}
      <List
        changing={pendingRoleUpdate}
        members={props.members}
        onChange={onChange}
        onRemove={onRemove}
        optionLabels={optionLabels}
        options={options}
        removing={pendingRemove}
        user={props.user}
      />
    </>
  );
}

IndividualAccess.propTypes = {
  members: PropTypes.arrayOf(PropTypes.Member).isRequired,
  onChange: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  user: PropTypes.Member.isRequired,
};

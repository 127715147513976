import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import naturalSort from 'natural-sort';
import shallowEqual from 'shallowequal';

import { MARKER_SIZE, SCENE_STATUS } from 'constants';
import {
  createCachedSelectorWithArgs,
  createSelectorWithArgs,
  getSceneAnnotations,
  isDefined,
} from 'helpers';

// for shallow equality
const emptyArray = [];
const emptyObj = {};

export const getProject = (state) => (state.project.inst.result === undefined
  ? undefined
  : state.project.inst.entities.projects[state.project.inst.result]
);

export const getSceneEntities = (state) => (state.project.inst.entities
  && state.project.inst.entities.scenes
  ? state.project.inst.entities.scenes
  : emptyObj);

const getProjectScenes = (state) => {
  const project = getProject(state);

  return project ? project.scenes : undefined;
};

const getSceneNames = createSelector(
  getProjectScenes,
  getSceneEntities,
  (scenes, entities) => (scenes ? scenes.reduce((acc, curr) => {
    acc[curr] = entities[curr].name;
    return acc;
  }, {}) : emptyObj),
);

const createSceneIdSelector = createSelectorCreator(
  defaultMemoize,
  shallowEqual,
);

export const getSceneIds = createSceneIdSelector(
  getProjectScenes,
  getSceneNames,
  (scenes, names) => (scenes ? scenes.concat().sort((a, b) => {
    const nameA = names[a];
    const nameB = names[b];
    return naturalSort()(nameA, nameB);
  }) : emptyArray),
);

export const getSpaceEntities = (state) => (state.project.inst.entities && state.project.inst.entities.spaces
  ? state.project.inst.entities.spaces
  : emptyObj);

export const getSpaceIds = (state) => {
  const project = getProject(state);
  return project ? project.spaces : emptyArray;
};

export const getModelEntities = (state) => state.project.inst.entities.models || emptyObj;
export const getModelIds = (state) => {
  const project = getProject(state);
  return project && project.models ? project.models : emptyArray;
};

export const getModelIdsBySpaceId = createSelector(
  getSpaceIds,
  getModelIds,
  getModelEntities,
  (spaceIds, modelIds, entities) => spaceIds.reduce((acc, spaceId) => {
    acc[spaceId] = modelIds.filter((modelId) => !entities[modelId].spaceIds
      || !entities[modelId].spaceIds.length
      || entities[modelId].spaceIds.includes(spaceId));
    return acc;
  }, {}),
);

export const getModelsBySpaceId = createSelector(
  getSpaceIds,
  getModelIdsBySpaceId,
  getModelEntities,
  (spaceIds, modelIds, entities) => spaceIds.reduce((acc, spaceId) => {
    acc[spaceId] = modelIds[spaceId].map((modelId) => entities[modelId]);
    return acc;
  }, {}),
);

export const getMarkerSize = (state) => getProject(state).config.markerSize || MARKER_SIZE;

export const getScenes = createSelector(
  getSceneIds,
  getSceneEntities,
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getSuccessScenes = createSelector(
  getSceneIds,
  getSceneEntities,
  (sceneIds, sceneEntities) => sceneIds.reduce((acc, sceneId) => {
    const scene = sceneEntities[sceneId];
    return !scene.state || ['REDACTING', 'SUCCESS'].includes(scene.state)
      ? [...acc, scene]
      : acc;
  }, []),
);

export const getSpaces = createSelector(
  getSpaceEntities,
  getSpaceIds,
  (entities, ids) => ids.map((id) => entities[id]),
);

export const getSpaceFloorPlansInProgress = (space = {}) => space.floorPlansInProgress || [];

export const getSpacesFloorPlansInProgress = createSelector(
  getSpaces,
  (spaces) => spaces.reduce((acc, space) => {
    acc.push(...getSpaceFloorPlansInProgress(space));
    return acc;
  }, []),
);

export const getSpaceIdsBySceneId = createSelector(
  getSceneIds,
  getSpaces,
  (sceneIds, spaces) => sceneIds.reduce((acc, curr) => {
    acc[curr] = spaces.filter((space) => space.scenes[curr]).map((space) => space.id);
    return acc;
  }, {}),
);

export const getSceneIdsBySpaceId = createSelector(
  getSpaces,
  getSceneIds,
  (spaces, sceneIds) => spaces.reduce((acc, space) => ({
    ...acc,
    [space.id]: sceneIds.filter((sceneId) => space.scenes[sceneId]),
  }), {}),
);

export const getScenesBySpaceId = createSelector(
  getSpaceIds,
  getSceneIdsBySpaceId,
  getSceneEntities,
  (spaceIds, sceneIdsBySpaceId, entities) => spaceIds.reduce((acc, spaceId) => ({
    ...acc,
    [spaceId]: sceneIdsBySpaceId[spaceId].map((sceneId) => entities[sceneId]),
  }), {}),
);

export const getAnnotationById = createSelectorWithArgs(
  [getSceneEntities, 2],
  (entities, sceneId, annotationId) => {
    const annotations = getSceneAnnotations(entities[sceneId]);

    return annotationId
      ? annotations.find((annotation) => annotation.id === annotationId)
      : undefined;
  },
);

export const getProcessingScenesForSpaceId = createCachedSelectorWithArgs(
  [getScenes, getScenesBySpaceId, 1],
  (scenes, scenesBySpaceId, spaceId) => {
    let scenesToFilter = scenes;
    if (spaceId !== null) {
      scenesToFilter = scenesBySpaceId[spaceId];
    }
    return scenesToFilter
      ? scenesToFilter.filter((scene) => [SCENE_STATUS.PROCESSING, SCENE_STATUS.REDACTING].includes(scene.state))
      : emptyArray;
  },
)((_, spaceId) => (spaceId === null ? 'ALL_SPACES_OPTION' : spaceId));

export const getMappedScenesBySpaceId = createSelector(
  getSpaces,
  getSceneIds,
  getSceneEntities,
  (spaces, sceneIds, sceneEntities) => spaces.reduce((acc, space) => {
    acc[space.id] = sceneIds.filter((sceneId) => {
      const spaceScene = space.scenes[sceneId];
      return spaceScene && isDefined(spaceScene.mapX) && isDefined(spaceScene.mapY);
    }).map((id) => sceneEntities[id]);
    return acc;
  }, {}),
);

export const getEnabledSpaceIds = createSelector(
  getSpaces,
  getSceneIdsBySpaceId,
  getModelIdsBySpaceId,
  (spaces, sceneIdsBySpaceId, modelIdsBySpaceId) => spaces.filter((space) => space.active && (
    sceneIdsBySpaceId[space.id].length > 0
    || modelIdsBySpaceId[space.id].length > 0
  )).map((space) => space.id),
);

export const getModels = createSelector(
  getModelEntities, getModelIds,
  (entities, ids) => ids.map((id) => entities[id]),
);

export const getLinks = createSelector(
  getSceneEntities,
  getSceneIds,
  getSpaceIdsBySceneId,
  (entities, ids, spaceIdsBySceneId) => ids.reduce((acc, curr) => {
    acc[curr] = [];
    entities[curr].linkHotspots.forEach((lh) => {
      const { target: id, yaw } = lh;
      // don't link to self
      if (id === curr) { return; }
      // don't link twice
      if (acc[curr].findIndex((link) => link.id === id) > -1) {
        return;
      }
      // don't link to non-existent scene
      if (!entities[id] || !spaceIdsBySceneId[id]) {
        return;
      }
      if (
        // don't link if other scene appears in same space and already links to current scene
        (spaceIdsBySceneId[curr].filter((value) => spaceIdsBySceneId[id].includes(value)).length)
        && (acc[id] !== undefined && acc[id].findIndex((otherLink) => otherLink.id === curr) > -1)
      ) {
        return;
      }
      const link = {
        id,
        yaw,
      };
      if (entities[id].linkHotspots.findIndex((otherLh) => otherLh.target === curr) !== -1) {
        link.bidirectional = true;
      }
      acc[curr].push(link);
    });
    return acc;
  }, {}),
);

export const getLinkedSceneIdObject = createSelector(
  getScenes,
  (scenes) => scenes.reduce((acc, scene) => {
    scene.linkHotspots.forEach((lh) => {
      if (lh.target) {
        acc[lh.target] = true;
      }
    });
    return acc;
  }, {}),
);

export const getSignedUrls = /* istanbul ignore next */ (state) => state.project.signedUrls;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Typography(props) {
  const {
    baseClass,
    children,
    className,
    dangerouslySetInnerHTML,
    tag: Tag,
    ...rest
  } = props;

  const hasContent = Boolean(children || dangerouslySetInnerHTML);

  return !hasContent ? null : (
    <Tag
      className={classNames(baseClass, className)}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...rest}
    >
      {children}
    </Tag>
  );
}

Typography.propTypes = {
  baseClass: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.shape({
    __html: PropTypes.any,
  }),
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
};

Typography.defaultProps = {
  tag: 'span',
};

import React from 'react';
import classNames from 'classnames';

import { PropTypes } from 'constants';

import Label from 'components/UI/Typography/Label';

import s from './index.scss';

export const INPUT_PLACEMENT = {
  LEFT: 'left',
  TOP: 'top',
};

export const INPUT_SIZE = {
  MEDIUM: 'md',
  SMALL: 'sm',
  XS: 'xs',
};

export default function LabelInput(props) {
  return !props.value ? null : (
    <Label
      className={classNames(s.label, props.className, {
        [s.left]: props.placement === INPUT_PLACEMENT.LEFT,
        [s.medium]: props.size === INPUT_SIZE.MEDIUM,
        [s.small]: props.size === INPUT_SIZE.SMALL,
        [s.top]: props.placement === INPUT_PLACEMENT.TOP,
        [s.xs]: props.size === INPUT_SIZE.XS,
      })}
      tag="p"
    >
      {props.value}
    </Label>
  );
}

LabelInput.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.oneOfMap(INPUT_PLACEMENT),
  size: PropTypes.oneOfMap(INPUT_SIZE),
  value: PropTypes.string,
};

LabelInput.defaultProps = {
  placement: INPUT_PLACEMENT.TOP,
  size: INPUT_SIZE.MEDIUM,
};

import { createSelector } from 'reselect';
import { EMAIL_DOMAIN_REGEX } from '../constants/regex';

export const getMemberEntities = (state) => state.members.items.entities.members;
export const getMemberIds = (state) => state.members.items.result;

export const getMembers = createSelector(
  getMemberIds,
  getMemberEntities,
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getIndividualMembers = createSelector(
  getMembers,
  (members) => members.filter((member) => !EMAIL_DOMAIN_REGEX.test(member.userId)),
);

export const getOrganizationMembers = createSelector(
  getMembers,
  (members) => members.filter((member) => EMAIL_DOMAIN_REGEX.test(member.userId)),
);

// We retry requests on 401 (UNAUTHORIZED) responses
export const REQUEST_RETRY_LIMIT = 1;

export const ICON_SIZE = 24;

export const ICON_PROPS = {
  size: ICON_SIZE,
  viewBox: `0 0 ${ICON_SIZE} ${ICON_SIZE}`,
};

export const DEMO_PROJECT = 'presidio-building-103b';
// Amount of pixels that users needs to drag a mouse to ignore a click event
export const MARZIPANO_DRAG_THRESHOLD = 3;
export const MARKER_SIZE = 14;
export const MODEL_TYPES = {
  EXTERNAL_RESOURCE: 'EXTERNAL_RESOURCE',
  PHOTO_TOUR: 'PHOTO_TOUR',
  PLAN: 'PLAN',
  POINT_CLOUD: 'POINT_CLOUD',
  REVIT: 'REVIT',
  SKETCHUP: 'SKETCHUP',
};
export const UPLOAD = {
  chunkSize: 262144 * 76, // close to 20MB
  resumable: false, // Resumable upload calculates checksum for each chunk. It uses a lot of memory and crashes browser with bigger files.
  storage: window.localStorage,
  validateChecksum: false,
};

// Scanners.
export const SCANNERS = {
  FARO_S350: 'FARO_S350',
  GEOSLAM_REVO: 'GEOSLAM_REVO',
  OTHER: 'OTHER',
};

const SCANNER_LABEL = {
  FARO_S350: 'Faro S350',
  GEOSLAM_REVO: 'GeoSLAM',
  OTHER: 'Other',
};

export const SCANNER_OPTIONS = Object.keys(SCANNERS).map((key) => ({
  label: SCANNER_LABEL[key],
  value: key,
}));

export const MOUSE_BUTTON_CODES = {
  BACK: 3,
  FORWARD: 4,
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
};

export const KEY_CODES = {
  A: 65,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  BACKSPACE: 8,
  D: 68,
  DELETE: 46,
  ENTER: 13,
  EQUAL: 187,
  ESCAPE: 27,
  L: 76,
  M: 77,
  MINUS: 189,
  NUM_1: 49,
  NUM_2: 50,
  NUM_3: 51,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  S: 83,
  SPACE: 32,
  TAB: 9,
  V: 86,
  W: 87,
};
export const GENERIC_STATES = {
  COMPLETE: 'COMPLETE',
  FAILURE: 'FAILURE',
};
export const ASSET_PREVIEW_TYPES = {
  entwine: 'entwine',
  potree: 'potree',
  unity: 'unity',
};
export const ASSET_STATUSES = {
  ...GENERIC_STATES,
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
};
export const ATTACHMENT_SOURCES = {
  ASSET_MANAGER: 'ASSET_MANAGER',
  EXTRACTION: 'EXTRACTION',
  FLOORPLAN_GENERATION: 'FLOORPLAN_GENERATION',
  FLOORPLAN_UPLOAD: 'FLOORPLAN_UPLOAD',
  MODEL: 'MODEL',
  POST_MERGE_POINT_CLOUD_UPLOAD: 'POST_MERGE_POINT_CLOUD_UPLOAD',
  RAW_POINT_CLOUD_UPLOAD: 'RAW_POINT_CLOUD_UPLOAD',
  SCENE: 'SCENE',
};

export const UNRESUMABLE_ATTACHMENT_SOURCES = [
  ATTACHMENT_SOURCES.EXTRACTION,
  ATTACHMENT_SOURCES.POST_MERGE_POINT_CLOUD_UPLOAD,
];

export const ATTACHMENT_TYPES = {
  IMAGE: 'IMAGE',
  IMAGE_360: 'IMAGE_360',
  LABEL: 'LABEL',
  OTHER: 'OTHER',
  PLAN: 'PLAN',
  POINT_CLOUD: 'POINT_CLOUD',
  REVIT: 'REVIT',
  VIDEO: 'VIDEO',
};
export const DATE_FORMAT = 'd mmm yyyy';
export const DATETIME_FORMAT = `${DATE_FORMAT}, H:MM`;
export const POLL_INTERVAL = 5000;

export const EXTRACTION_FORMATS = {
  E57: 'E57',
  LAS: 'LAS',
  LAZ: 'LAZ',
  RCS: 'RCS',
};

export const TRANSFORMATION_UNIT_MATRIX = `1.0 0.0 0.0 0.0
0.0 1.0 0.0 0.0
0.0 0.0 1.0 0.0
0.0 0.0 0.0 1.0`;

export const ZERO_PLACEHOLDER = '--';

export const INTERNAL_SELECT_ALL = 'all';

// Constellations.
export const DRAG_TYPES = {
  constellation: 'constellation',
  scene: 'scene',
};

export const CONSTELLATION_MAP_SIZE = 150; // Size of the grid of scenes in constellation
export const CONSTELLATION_MAP_PREVIEW_PADDING = 1; // Padding when dragging constellation

// Access control list.
export const ACL_ROLES = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  USER: 'USER',
};

// Globe.
export const FPS = {
  HIGH: 1000 / 60,
  MEDIUM: 1000 / 30,
};

export const ZOOM_DIRECTION = {
  IN: 'IN',
  OUT: 'OUT',
};

// Spaces.
export const SPACE_ID_ALL = 'all';

export const SPACE_SCENE_PARAMS = ['mapX', 'mapY'];

// Array.
export const INTERNAL_INDEX = '$index';

// Potree Tools
export const MEASURE_TYPES = {
  AREA: 'AREA',
  DISTANCE: 'DISTANCE',
  NONE: 'NONE',
  POI: 'POI',
  VOLUME: 'VOLUME',
};

export const MINIMAP_IMAGE_MULTIPLIER = 18;

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { onChangeUser, refreshToken } from 'actions/auth';
import { setErrorUserContext, showErrorPage } from 'actions/errors';
import { setIdToken } from 'actions/user';
import useLoader from 'components/common/Loading/useLoader';
import { PropTypes } from 'constants';
import { isDemoAccount, isUserLoggedIn, lazyLoad } from 'helpers';
import { getPathProjectId, isEditor, isGlobeView } from 'selectors/router';
import { getUser } from 'selectors/user';

import { Login } from '../Login';

const ModalVerifyEmail = lazyLoad(/* istanbul ignore next */ () => import(/* webpackChunkName: "modal-verify-email" */ 'components/UI/Modal/VerifyEmail'), null);

function PrivateRoute(props) {
  const {
    component: Component,
    isPrivate,
    projectId,
    setErrorUserContext: _setErrorUserContext,
    setIdToken: _setIdToken,
    showErrorPage: _showErrorPage,
    user,
    ...rest
  } = props;

  const { t } = useTranslation();

  const unsubscriber = useRef();

  const [renderLoader, setLoading, loading] = useLoader();

  const isDemo = isDemoAccount(user);
  const isLoggedIn = isUserLoggedIn(user);

  useEffect(() => {
    const onChangeAuthState = (account) => {
      const promise = account ? refreshToken() : Promise.resolve();
      return promise
        .then((idToken) => {
          _setIdToken(idToken);
          setLoading(false);
          _setErrorUserContext();
        })
        .catch((error) => {
          if (error.code === 'auth/network-request-failed') {
            _showErrorPage({
              message: t('offline.error.message'),
              title: t('offline.error.title'),
            });
          }
        });
    };

    unsubscriber.current = onChangeUser(onChangeAuthState);

    return () => {
      unsubscriber.current();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // For guests viewing projects, sign in happens in the background.
    if (isPrivate && projectId && !isLoggedIn && !loading) {
      setLoading(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivate, projectId, user]);

  const renderRoute = () => {
    if (isPrivate) {
      const showLogin = !isLoggedIn || (isDemo && !projectId);

      return showLogin
        ? <Login isLoadingPublicTwin={!!projectId} />
        : <Component {...rest} />;
    }

    return <Component {...rest} />;
  };

  const showVerifyEmailModal = isPrivate && isLoggedIn && !isDemo && !user.emailVerified;

  return renderLoader() || (
    <>
      {renderRoute()}
      {showVerifyEmailModal && (
        <ModalVerifyEmail />
      )}
    </>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  setErrorUserContext: PropTypes.func.isRequired,
  setIdToken: PropTypes.func.isRequired,
  showErrorPage: PropTypes.func.isRequired,
  user: PropTypes.User.isRequired,
};

export const PureTestComponent = PrivateRoute;

const mapStateToProps = (state) => {
  const projectId = !isEditor(state) && !isGlobeView(state)
    ? getPathProjectId(state)
    : '';

  return {
    projectId,
    user: getUser(state),
  };
};

const mapDispatchToProps = {
  setErrorUserContext,
  setIdToken,
  showErrorPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);

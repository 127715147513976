import { PROJECT_STATUS, PROJECT_STATUS_LABELS } from 'constants';

export const getOwnerDensityDetails = (project, ownersDensityDetails) => {
  if (ownersDensityDetails && project) {
    const ownerDensityDetail = ownersDensityDetails.find((o) => [o.name].includes(project.config.ownerName));
    return ownerDensityDetail;
  }
  return {};
};

const getStatus = (project) => {
  if (project.deliveryStatus) {
    return project.deliveryStatus;
  }
  return project.config
    ? project.config.deliveryStatus
    : project.deliveryStatus;
};

export const getProjectStatus = (project) => {
  const value = getStatus(project);
  const defaultValue = PROJECT_STATUS.UNSCANNED;
  return value || defaultValue;
};

export const getProjectStatusLabel = (project) => {
  const status = getProjectStatus(project);
  return PROJECT_STATUS_LABELS[status];
};

export const isProjectStatusBadgeVisible = (project) => {
  const status = getProjectStatus(project);
  return status !== PROJECT_STATUS.DELIVERED;
};

export const isProjectUnscanned = (project) => {
  const status = getProjectStatus(project);
  return status === PROJECT_STATUS.UNSCANNED;
};

/* eslint-disable sort-keys */
/* istanbul ignore file */
import { optional, required } from 'helpers/routes';

export const API = {
  editor: required(process.env.EDITOR_API_URL, {
    forgeToken: () => 'forge/token',
    owners: () => 'owners',
    projects: required('folios', {
      one: optional(':projectId', {
        annotations: required('annotations', {
          one: optional(':annotationId', {
            attachments: required('attachments', {
              one: optional(':attachmentId'),
            }),
            scenes: required('scenes', {
              one: optional('sceneId'),
            }),
          }),
        }),
        attachments: required('attachments', {
          one: optional(':attachmentId', {
            download: required('download'),
            resume: required('resume'),
            views: required('assetviews', {
              one: optional(':viewId'),
            }),
          }),
        }),
        constellations: required('constellations', {
          one: optional(':constellationId'),
        }),
        density: required('density', {
          spaces: () => 'spaces',
          companies: () => 'companies',
          one: optional('spaceId', {
            plan: () => 'plan',
          }),
        }),
        floorPlans: required('floorplans', {
          one: optional(':floorPlanId', {
            copy: () => 'copy',
          }),
        }),
        members: () => 'members',
        pointClouds: required('pointclouds', {
          merge: () => 'merge',
          one: optional(':pointCloudId', {
            extractions: required('extractions', {
              estimate: required('estimate'),
              one: optional(':extractionId'),
            }),
            signatures: () => 'signatures',
            derivatives: () => 'derivatives',
          }),
        }),
        publish: () => 'publish',
        scenes: required('scenes', {
          one: optional(':sceneId', {
            annotations: required('annotations', {
              one: optional(':annotationId'),
            }),
            download: () => 'download',
            links: required('links', {
              one: optional(':linkId'),
            }),
          }),
        }),
        spaces: required('spaces', {
          one: optional(':spaceId', {
            scenes: required('scenes', {
              autolink: () => 'autolink',
              one: optional(':sceneId', {
                redact: () => 'redact',
                replace: () => 'replace',
              }),
              update: () => 'bulkupdate',
            }),
          }),
          update: () => 'bulkupdate',
        }),
      }),
    }),
    scans: required('scans', {
      one: optional(':projectId', {
        density: required('density', {
          spaces: () => 'spaces',
          companies: () => 'companies',
          one: optional('spaceId', {
            plan: () => 'plan',
          }),
        }),
      }),
    }),
    support: required('support', {
      issues: () => 'issues',
      scanRequests: () => 'scan-requests',
    }),
  }),
  events: required(process.env.EVENTS_API_URL, {
    notifications: required('notifications', {
      editor: required('editor', {
        project: (projectId) => projectId,
      }),
    }),
  }),
  functions: required(process.env.CLOUD_FUNCTIONS_URL, {
    verifyEmail: () => 'verificationEmailHandler-resendVerificationEmail',
  }),
  models: required(process.env.MODEL_SERVICE_URL, {
    projects: required('folios', {
      pointCloud: (projectId, pointCloudId) => `${projectId}/pointclouds/${pointCloudId}`,
    }),
  }),
};

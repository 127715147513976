import {
  goBack as popPath,
  push as pushPath,
  replace as replacePath,
} from 'connected-react-router';

import { DEMO_PROJECT, ROUTES } from 'constants';
import { stringifySearch, isDifferentSpace } from 'helpers';
import { getSpaceEntities, getSpaceIdsBySceneId, getSceneIdsBySpaceId } from 'selectors/project';
import {
  getPathAssetId,
  getPathProjectId,
  getPathSpaceId,
  isEditor,
  isFloorPlan,
  isModels,
} from 'selectors/router';

import { setEditorSelectedSceneIds } from './editor';

const getNavMethod = (replace) => (replace ? replacePath : pushPath);

const getAppRootPath = () => (_, getState) => {
  const state = getState();
  const editing = isEditor(state);

  return editing
    ? ROUTES.editor.root()
    : ROUTES.viewer.root();
};

const getRootPath = () => (_, getState) => {
  const state = getState();
  const editing = isEditor(state);
  const projectId = getPathProjectId(state);
  if (!editing) {
    return ROUTES.viewer.project(projectId).root();
  }
  if (isModels(state)) {
    return ROUTES.editor.project(projectId).models();
  }
  if (isFloorPlan(state)) {
    return ROUTES.editor.project(projectId).plan();
  }
  if (projectId) {
    return ROUTES.editor.project(projectId).tour();
  }
  return ROUTES.editor.root();
};

const doSetPathSpaceId = (spaceId, replace, attachment = undefined) => (dispatch) => {
  let path = `${dispatch(getRootPath())}`;

  if (attachment) {
    path = `${path}/s/${spaceId}/${attachment}`;
  } else if (spaceId !== undefined) {
    // TODO: Use ROUTES constant.
    path = `${path}/s/${spaceId}`;
  }
  return dispatch(getNavMethod(replace)(path));
};

export const goToProjectSettings = (projectId, replace) => (dispatch) => {
  const path = ROUTES.editor.project(projectId).settings();
  dispatch(getNavMethod(replace)(path));
};

export const setPath = (path, replace) => (dispatch) => {
  dispatch(getNavMethod(replace)(path));
};

export const setPathAssetManager = (assetViewId = '', replace) => (dispatch, getState) => {
  const assetId = getPathAssetId(getState());
  if (assetId) {
    const projectId = getPathProjectId(getState());
    const path = ROUTES.editor.project(projectId).assets.one(assetId).view(assetViewId);
    dispatch(getNavMethod(replace)(path));
  }
};

export const setPathAssetViewId = (assetId, assetViewId = '', replace) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());
  const path = ROUTES.editor.project(projectId).assets.one(assetId).view(assetViewId);
  dispatch(getNavMethod(replace)(path));
};

export const setPathConstellationId = (pathSpaceId, constellationId, replace) => (dispatch) => {
  // TODO: Use ROUTES constant.
  const path = `${dispatch(getRootPath())}/s/${pathSpaceId}/c/${constellationId}`;
  return dispatch(getNavMethod(replace)(path));
};

export const setPathDemoProject = () => (dispatch) => {
  const path = dispatch(getRootPath());
  const pathDemoProject = ROUTES.viewer.project(DEMO_PROJECT).root();
  if (path !== pathDemoProject) {
    dispatch(getNavMethod(true)(pathDemoProject));
  }
};

export const setPathEditorProject = (projectId, replace) => (dispatch) => {
  const path = ROUTES.editor.project(projectId).root();
  dispatch(getNavMethod(replace)(path));
};

export const setPathGlobeProject = (projectId, replace) => (dispatch) => {
  const path = ROUTES.viewer.place(projectId);
  dispatch(getNavMethod(replace)(path));
};

export const setPathGoBack = () => (dispatch) => {
  dispatch(popPath());
};

export const setPathModelId = (spaceId, modelId) => (dispatch) => {
  // TODO: Use ROUTES constant.
  const path = `${dispatch(getRootPath())}/s/${spaceId}/m/${modelId}`;
  dispatch(getNavMethod(false)(path));
};

export const setPathPointCloudId = (pointCloudId, replace) => (dispatch, getState) => {
  const projectId = getPathProjectId(getState());
  const path = ROUTES.editor.project(projectId).extractor.pointCloud(pointCloudId);
  dispatch(getNavMethod(replace)(path));
};

export const setPathRoot = (replace) => (dispatch) => {
  const path = dispatch(getAppRootPath());
  dispatch(getNavMethod(replace)(path));
};

export const setPathSceneId = (sceneId, replace) => (dispatch, getState) => {
  const state = getState();
  let spaceId = getPathSpaceId(state);
  const sceneIdsBySpaceId = getSceneIdsBySpaceId(state);
  if (!sceneIdsBySpaceId[spaceId] || !sceneIdsBySpaceId[spaceId].includes(sceneId)) {
    [spaceId] = getSpaceIdsBySceneId(state)[sceneId];
  }
  const projectId = getPathProjectId(state);
  // TODO: Use ROUTES constant.
  let path = `/${projectId}/s/${spaceId}/s/${sceneId}`;

  if (isEditor(state)) {
    path = `/editor/${projectId}/tour/s/${spaceId}/s/${sceneId}`;
  }
  return dispatch(getNavMethod(replace)(path));
};

export const setPathSearchQuery = (data, replace) => (dispatch) => {
  const search = `?${stringifySearch(data)}`;
  dispatch(getNavMethod(replace)({ search }));
};

export const setPathSpaceId = (spaceId, replace) => (dispatch, getState) => {
  const state = getState();
  if (isEditor(state)) {
    dispatch(setEditorSelectedSceneIds([]));
    dispatch(doSetPathSpaceId(spaceId, replace));
  } else {
    const sceneIds = getSceneIdsBySpaceId(state)[spaceId];
    const space = getSpaceEntities(state)[spaceId];
    if (sceneIds && sceneIds.length) {
      dispatch(setPathSceneId(space.initialScene || sceneIds[0], replace));
    } else {
      dispatch(doSetPathSpaceId(spaceId, replace));
    }
  }
};

export const setPathSpaceIdAndAttachments = (spaceId, attachmentId, replace) => (dispatch, getState) => {
  const state = getState();
  if (isEditor(state)) {
    dispatch(setEditorSelectedSceneIds([]));
    dispatch(doSetPathSpaceId(spaceId, replace, attachmentId));
  } else {
    const sceneIds = getSceneIdsBySpaceId(state)[spaceId];
    const space = getSpaceEntities(state)[spaceId];
    if (sceneIds && sceneIds.length) {
      dispatch(setPathSceneId(space.initialScene || sceneIds[0], replace));
    } else {
      dispatch(doSetPathSpaceId(spaceId, replace, attachmentId));
    }
  }
};

export const maybeSwitchSpaces = (sceneId) => (dispatch, getState) => {
  const state = getState();
  const pathSpaceId = getPathSpaceId(state);
  const spaceIdsForSceneId = getSpaceIdsBySceneId(state)[sceneId];
  if (isDifferentSpace(spaceIdsForSceneId, pathSpaceId)) {
    dispatch(doSetPathSpaceId(spaceIdsForSceneId[0], false));
  }
};

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import attachments from './attachments';
import cloudManager from './cloudManager';
import constellations from './constellations';
import controlsEnabled from './controlsEnabled';
import editor from './editor';
import error from './error';
import extractions from './extractions';
import mapDimensions from './mapDimensions';
import members from './members';
import minimapCenter from './minimapCenter';
import owners from './owners';
import panelWidths from './panelWidths';
import pointClouds from './pointClouds';
import project from './project';
import projects from './projects';
import shortcutsGuide from './shortcutsGuide';
import user from './user';
import viewers from './viewers';
import yaw from './yaw';

export default (history) => combineReducers({
  attachments,
  cloudManager,
  constellations,
  controlsEnabled,
  editor,
  error,
  extractions,
  form: formReducer,
  mapDimensions,
  members,
  minimapCenter,
  owners,
  panelWidths,
  pointClouds,
  project,
  projects,
  router: connectRouter(history),
  shortcutsGuide,
  user,
  viewers,
  yaw,
});

import React from 'react';
import classNames from 'classnames';
import { Menu as AntMenu } from 'antd';

import { PropTypes } from 'constants';

import s from './index.scss';

export default function Menu(props) {
  const {
    className: menuClassName,
    items: menuItems,
    ...rest
  } = props;

  const renderItems = (items) => items.map((item, index) => {
    const itemProps = {
      className: classNames(s.item, item.className, {
        [s.danger]: item.danger,
      }),
      disabled: item.disabled,
      key: item.key || index,
    };

    if (!item.items || !item.items.length) {
      return (
        <AntMenu.Item {...itemProps}>
          {item.content}
        </AntMenu.Item>
      );
    }

    return (
      <AntMenu.SubMenu
        {...itemProps}
        title={item.content}
      >
        {renderItems(item.items)}
      </AntMenu.SubMenu>
    );
  });

  return (
    <AntMenu
      className={classNames(s.menu, menuClassName)}
      {...rest}
    >
      {renderItems(menuItems)}
    </AntMenu>
  );
}

Menu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.MenuItems.isRequired,
};

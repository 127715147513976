export const fonts = {
  'font-1': '10px',
  'font-2': '13px',
  'font-3': '16px',
};

export const colors = {
  black: '#000000',
  blue: 'hsl(215, 100%, 55%)',
  'blue-000': 'hsl(219, 100%, 97%)',
  'blue-100': 'hsl(221, 100%, 93%)',
  'blue-200': 'hsl(220, 100%, 83%)',
  'blue-300': 'hsl(220, 96%, 70%)',
  'blue-400': 'hsl(215, 100%, 55%)',
  'blue-500': 'hsl(220, 96%, 38%)',
  'blue-600': 'hsl(220, 100%, 27%)',
  'blue-700': 'hsl(220, 100%, 21%)',
  'gray-000': 'hsl(212, 11%, 96%)',
  'gray-100': 'hsl(212, 10%, 94%)',
  'gray-200': 'hsl(212, 11%, 89%)',
  'gray-300': 'hsl(212, 11%, 78%)',
  'gray-400': 'hsl(212, 10%, 61%)',
  'gray-500': 'hsl(212, 10%, 45%)',
  'gray-600': 'hsl(212, 13%, 35%)',
  'gray-700': 'hsl(212, 14%, 26%)',
  'gray-800': 'hsl(212, 13%, 22%)',
  'gray-900': 'hsl(212, 13%, 18%)',
  'green-000': 'hsl(145, 68%, 96%)',
  'green-100': 'hsl(158, 61%, 94%)',
  'green-200': 'hsl(145, 70%, 81%)',
  'green-300': 'hsl(145, 56%, 64%)',
  'green-400': 'hsl(158, 100%, 37%)',
  'green-500': 'hsl(158, 77%, 33%)',
  'green-600': 'hsl(162, 77%, 22%)',
  'green-700': 'hsl(155, 75%, 12%)',
  'midnight-900': 'hsla(210, 10%, 12%, 1)',
  orange: 'hsl(17, 84%, 58%)',
  'orange-000': 'hsl(25, 100%, 95%)',
  'orange-100': 'hsl(22, 100%, 89%)',
  'orange-200': 'hsl(25, 100%, 79%)',
  'orange-300': 'hsl(22, 99%, 72%)',
  'orange-400': 'hsl(17, 84%, 58%)',
  'orange-500': 'hsl(17, 60%, 45%)',
  'orange-600': 'hsl(17, 58%, 29%)',
  'orange-700': 'hsl(36, 60%, 16%)',
  'pink-000': 'hsl(340, 100%, 97%)',
  'pink-100': 'hsl(340, 100%, 90%)',
  'pink-200': 'hsl(340, 100%, 82%)',
  'pink-300': 'hsl(340, 97%, 73%)',
  'pink-400': 'hsl(340, 100%, 63%)',
  'pink-500': 'hsl(340, 64%, 45%)',
  'pink-600': 'hsl(340, 62%, 34%)',
  'pink-700': 'hsl(340, 60%, 19%)',
  'purple-000': 'hsl(270, 90%, 98%)',
  'purple-100': 'hsl(270, 90%, 93%)',
  'purple-200': 'hsl(270, 90%, 84%)',
  'purple-300': 'hsl(270, 90%, 71%)',
  'purple-400': 'hsl(270, 80%, 60%)',
  'purple-500': 'hsl(270, 65%, 47%)',
  'purple-600': 'hsl(270, 66%, 33%)',
  'purple-700': 'hsl(270, 60%, 16%)',
  'red-000': 'hsl(0, 100%, 97%)',
  'red-100': 'hsl(0, 100%, 93%)',
  'red-200': 'hsl(0, 100%, 85%)',
  'red-300': 'hsl(356, 100%, 74%)',
  'red-400': 'hsl(357, 100%, 64%)',
  'red-500': 'hsl(356, 61%, 50%)',
  'red-600': 'hsl(354, 62%, 33%)',
  'red-700': 'hsl(348, 60%, 16%)',
  'sienna-500': '#d47d5b',
  'teal-000': 'hsl(184, 100%, 98%)',
  'teal-100': 'hsl(184, 100%, 93%)',
  'teal-200': 'hsl(184, 72%, 78%)',
  'teal-300': 'hsl(184, 63%, 59%)',
  'teal-400': 'hsl(184, 67%, 44%)',
  'teal-500': 'hsl(184, 79%, 34%)',
  'teal-600': 'hsl(184, 85%, 24%)',
  'teal-700': 'hsl(184, 86%, 14%)',
  white: '#ffffff',
  'yellow-000': 'hsl(48, 83%, 98%)',
  'yellow-100': 'hsl(48, 95%, 92%)',
  'yellow-200': 'hsl(45, 100%, 83%)',
  'yellow-300': 'hsl(43, 100%, 69%)',
  'yellow-400': 'hsl(43, 89%, 55%)',
  'yellow-500': 'hsl(42, 83%, 42%)',
  'yellow-600': 'hsl(42, 87%, 28%)',
  'yellow-700': 'hsl(42, 86%, 14%)',
};

export const grid = {
  'grid-1': '10px',
  'grid-2': '16px',
  'grid-3': '20px',
  'grid-4': '30px',
  'grid-5': '40px',
  'grid-6': '50px',
  'grid-7': '70px',
};

export const antd = {
  'border-color-base': colors['gray-200'],
  'border-radius-base': '6px',
  'btn-primary-bg': colors.black,
  'collapse-content-bg': 'rgba(0, 0, 0, 0.8)',
  'collapse-header-bg': 'rgba(0, 0, 0, 0.8)',
  'collapse-header-padding': `12px ${grid['grid-5']} 12px 12px`,
  'disabled-color': colors['gray-300'],
  'font-family': "'Aeonik', 'Helvetica', 'Arial', sans-serif; /* Aeonik Font Stack */",
  'font-family-no-number': "'Aeonik', 'Helvetica', 'Arial', sans-serif; /* Aeonik Font Stack */",
  'font-size-base': fonts['font-2'],
  'font-size-lg': fonts['font-3'],
  'font-size-sm': fonts['font-1'],
  'heading-color': 'inherit',
  'input-bg': 'transparent',
  'link-active-color': colors['yellow-500'],
  'link-color': colors.white,
  'link-decoration': 'underline',
  'link-hover-color': colors['yellow-500'],
  'link-hover-decoration': 'underline',
  'padding-lg': grid['grid-4'],
  'padding-md': grid['grid-3'],
  'primary-color': colors['red-500'],
  'radio-button-bg': 'transparent',
  'radio-dot-color': colors.white,
  'switch-color': colors.black,
  'switch-height': '25px',
  'table-header-bg': 'transparent',
  'tag-default-bg': colors['gray-200'],
  'tag-default-color': colors.black,
  'text-color': 'inherit',
  'text-color-secondary': 'inherit',
};

export const GoogleMapsStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: colors['gray-600'],
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: colors['gray-300'],
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.park',
    stylers: [
      {
        color: colors['gray-500'],
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road',
    stylers: [
      {
        color: colors['gray-500'],
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'transit',
    stylers: [
      {
        color: colors['gray-500'],
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'water',
    stylers: [
      {
        color: colors['gray-800'],
      },
    ],
  },
];

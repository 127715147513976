import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { getUser } from '../selectors/user';
import { SHOW_ERROR_PAGE } from './types';

const setLogRocketSentryIntegration = () => {
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.setExtra('sessionURL', sessionURL);
  });
};

const setLogRocketUserContext = (user) => {
  const authenticated = !!user.uid;
  if (authenticated) {
    LogRocket.identify(user.uid, {
      email: user.email,
      name: user.email,
    });
  } else {
    LogRocket.identify();
  }

  setLogRocketSentryIntegration();
};

const setSentryUserContext = (user) => {
  const authenticated = !!user.uid;
  const userContext = !authenticated ? {} : {
    email: user.email,
    id: user.uid,
  };
  Sentry.setUser(userContext);
};

export const showErrorPage = (error) => ({
  payload: error,
  type: SHOW_ERROR_PAGE,
});

export const sendErrorToTracker = (error, info) => () => {
  Sentry.setExtras(info);
  Sentry.captureException(error);
};

export const setErrorUserContext = () => (dispatch, getState) => {
  const user = getUser(getState());
  setSentryUserContext(user);
  setLogRocketUserContext(user);
};

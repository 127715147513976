import React from 'react';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    size={21}
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      d="M16.9902 14.0917H14.0394M7.26178 4.36328V7.34928M4.31836 7.34928L14.0394 7.34928L14.0394 17.0703M7.26178 8.77659V14.0917H12.5743"
      stroke="currentColor"
    />
  </Svg>
);

import { createSelector } from 'reselect';

import { createSelectorWithArgs, getFormat } from 'helpers';

export const getAttachmentEntities = (state) => state.attachments.items.entities.attachments;
export const getAttachmentIds = (state) => state.attachments.items.result;
export const getAttachmentUploadEntities = (state) => state.attachments.uploads.entities;
export const getAttachmentUploadIds = (state) => state.attachments.uploads.result;

export const getAttachments = createSelector(
  getAttachmentIds,
  getAttachmentEntities,
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getAttachmentById = createSelectorWithArgs(
  [getAttachments, 1],
  (attachments, id) => attachments.find((a) => a.id === id),
);

export const getAssetViewById = createSelectorWithArgs(
  [getAttachmentEntities, 2],
  (attachments, attachmentId, assetViewId) => {
    const attachment = attachments[attachmentId];
    if (attachment && attachment.assetViews) {
      return attachment.assetViews.find((view) => view.id === assetViewId);
    }
    return undefined;
  },
);

export const getUploadingAttachments = createSelector(
  getAttachmentUploadIds,
  getAttachmentUploadEntities,
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getUploadingAttachmentsById = createSelector(
  getUploadingAttachments,
  (attachments) => attachments.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {}),
);

export const getAttachmentTypes = createSelector(
  getAttachments,
  (attachments) => Object.keys(attachments.reduce((acc, curr) => {
    const format = getFormat(curr.originalFileName);
    if (format) {
      acc[format] = true;
    }
    return acc;
  }, {})).sort(),
);

export const getModelAttachments = createSelector(
  getAttachments,
  (attachments) => attachments.filter((attachment) => attachment.source === 'MODEL'),
);

export const getModelAttachmentById = createSelectorWithArgs(
  [getModelAttachments, 1],
  (attachments, id) => attachments.find((a) => a.id === id),
);

export const getModelCards = createSelector(
  getAttachments,
  (attachments) => attachments.reduce((modelCards, attachment) => {
    if (!attachment.assetViews) {
      attachment.assetViews = [];
    }

    if (!attachment.assetViews.length && attachment.source === 'MODEL') {
      return [
        ...modelCards,
        attachment,
      ];
    }

    if (attachment.assetViews.length) {
      return [
        ...modelCards,
        ...attachment.assetViews.filter((view) => view.showModelCard).map((assetView) => {
          const { assetViews, ...attachmentWithoutAssetViews } = attachment;
          const {
            cutPlanes,
            initialCameraPosition,
            name,
            vectorCutPlanes,
          } = assetView;

          return {
            ...attachmentWithoutAssetViews,
            assetView,
            cardTitle: name,
            cutPlanes,
            initialCameraPosition,
            vectorCutPlanes,
          };
        }),
      ];
    }

    return modelCards;
  }, []),
);

export const getModelCardsBySpaceId = createSelector(
  getModelCards,
  (modelCards) => modelCards.reduce((acc, curr) => {
    const spaceIds = curr.assetView
      ? curr.assetView.spaceIds
      : curr.spaceIds;

    acc.all = [...acc.all, curr];
    spaceIds.forEach((spaceId) => {
      if (!acc[spaceId]) {
        acc[spaceId] = [];
      }
      acc[spaceId] = [...acc[spaceId], curr];
    });

    return acc;
  }, { all: [] }),
);

export const getModelCardTypes = createSelector(
  getModelCards,
  (attachments) => Object.keys(attachments.reduce((acc, curr) => {
    const format = getFormat(curr.originalFileName);
    if (format) {
      acc[format] = true;
    }
    return acc;
  }, {})).sort(),
);

export const getModelCardById = createSelectorWithArgs(
  [getModelCards, 2],
  (modelCards, modelId, assetViewId) => modelCards
    .find((modelCard) => modelCard.id === modelId
      && (!assetViewId || (modelCard.assetView && modelCard.assetView.id === assetViewId))),
);

import { useMediaQuery } from 'react-responsive';

import { PropTypes } from 'constants';

const BREAKPOINT_SM = 480; // hard-coded from `mixins/misc.scss`

export const useMediaQueryIsDesktop = () => {
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINT_SM + 1,
  });

  return isDesktop;
};

export const useMediaQueryIsMobile = () => {
  const isMobile = useMediaQuery({
    maxWidth: BREAKPOINT_SM,
  });

  return isMobile;
};

const QueryProps = {
  children: PropTypes.Node,
};

export function QueryDesktop(props) {
  const canRender = useMediaQueryIsDesktop();

  return canRender
    ? props.children
    : null;
}

QueryDesktop.propTypes = QueryProps;

export function QueryMobile(props) {
  const canRender = useMediaQueryIsMobile();

  return canRender
    ? props.children
    : null;
}

QueryDesktop.propTypes = QueryProps;

export const PUBLISH_STATUS = {
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const PUBLISH_STATUS_LABELS = {
  FAILED: 'Publishing failed',
  IN_PROGRESS: 'Publishing in progress',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Not published',
};

export const PUBLISH_STATUS_ORDER = [
  PUBLISH_STATUS.FAILED,
  PUBLISH_STATUS.UNPUBLISHED,
  PUBLISH_STATUS.IN_PROGRESS,
  PUBLISH_STATUS.PUBLISHED,
];

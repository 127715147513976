import { SORT_DIR, SORT_KEYS } from '../constants';
import { noop } from './function';
import { sorterByNumber, sorterByString } from './misc';
import { stripTails } from './string';

const getSorter = (key) => {
  switch (key) {
    case SORT_KEYS.date:
    case SORT_KEYS.size:
      return sorterByNumber;
    case SORT_KEYS.format:
    case SORT_KEYS.name:
      return sorterByString;
    default:
      return noop;
  }
};

export const getSortOptions = (t, order) => order.reduce((acc, base) => [
  ...acc,
  {
    label: t(`sort.${base + SORT_DIR.DESC}`),
    value: base + SORT_DIR.DESC,
  },
  {
    label: t(`sort.${base + SORT_DIR.ASC}`),
    value: base + SORT_DIR.ASC,
  },
], []);

export const sortObjects = (arr, keys, sortBy) => {
  const base = stripTails(sortBy, [SORT_DIR.ASC, SORT_DIR.DESC]);
  const sorter = getSorter(base)(keys[base]);
  const sorted = arr.sort(sorter);
  return sortBy.endsWith(SORT_DIR.ASC) ? sorted.reverse() : sorted;
};

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path clipRule="evenodd" d="M9.77185 8.13409L11.277 6.81708L16.2282 12.4756L11.277 18.1341L9.77185 16.8171L13.5707 12.4756L9.77185 8.13409Z" fill="currentColor" fillRule="evenodd" />
  </Svg>
);

import { ASSET_STATUSES, ATTACHMENT_SOURCE } from '../constants';
import { getValue } from './immutability';

const safeAttachment = (attachment) => getValue(attachment, {});

const getAttachmentStatus = (attachment) => {
  attachment = safeAttachment(attachment);
  return attachment.status;
};

export const getAttachmentProgress = (attachment) => {
  attachment = safeAttachment(attachment);
  const progress = getValue(attachment.progress, {});
  return getValue(progress.percentageProgress, 0);
};

export const isAttachmentComplete = (attachment) => {
  const status = getAttachmentStatus(attachment);
  return status === ASSET_STATUSES.COMPLETE;
};

export const isAttachmentFailed = (attachment) => {
  const status = getAttachmentStatus(attachment);
  return status === ASSET_STATUSES.FAILURE;
};

export const isAttachmentGeneratingExtraction = (attachment) => {
  attachment = safeAttachment(attachment);
  return !isAttachmentComplete(attachment)
    && attachment.source === ATTACHMENT_SOURCE.EXTRACTION;
};

export const isAttachmentUploading = (attachment) => {
  attachment = safeAttachment(attachment);
  return !!(attachment.directUploadUrl || window.uploads[attachment.id]);
};

import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path
      d="M18.9232 11.39C19.1949 11.4393 19.4551 11.2589 19.5043 10.9872L20.3065 6.55925C20.3557 6.28753 20.1753 6.02735 19.9036 5.97813C19.6319 5.9289 19.3717 6.10927 19.3225 6.38099L18.6095 10.3169L14.6735 9.60389C14.4018 9.55466 14.1416 9.73503 14.0924 10.0068C14.0432 10.2785 14.2236 10.5386 14.4953 10.5879L18.9232 11.39ZM8.58975 18.9065C5.32755 17.0231 4.20984 12.8517 6.09327 9.58955L5.22724 9.08955C3.06767 12.83 4.34926 17.613 8.08975 19.7726L8.58975 18.9065ZM6.09327 9.58955C7.9767 6.32735 12.1481 5.20964 15.4103 7.09307L15.9103 6.22705C12.1698 4.06747 7.38682 5.34906 5.22724 9.08955L6.09327 9.58955ZM17.9067 16.4101C16.0233 19.6723 11.8519 20.79 8.58975 18.9065L8.08975 19.7726C11.8302 21.9321 16.6132 20.6506 18.7728 16.9101L17.9067 16.4101ZM15.4103 7.09307C16.1584 7.52501 16.6748 8.12476 17.1457 8.83534C17.3832 9.19373 17.603 9.57044 17.8392 9.96967C18.0727 10.3643 18.3201 10.7771 18.6014 11.1829L19.4232 10.6132C19.1644 10.2398 18.9331 9.85466 18.6998 9.46046C18.4693 9.07083 18.2343 8.66771 17.9793 8.28293C17.4652 7.50715 16.8457 6.76715 15.9103 6.22705L15.4103 7.09307Z"
      fill="currentColor"
    />
  </Svg>
);

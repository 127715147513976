export const getD3DragCursorPosition = (event) => ({
  x: event.x,
  y: event.y,
});

export const getDistanceBetweenEvents = (eventA, eventB) => ({
  x: eventA.clientX - eventB.clientX,
  y: eventA.clientY - eventB.clientY,
});

export const getDistanceValueBetweenEvents = (eventA, eventB) => {
  const d = getDistanceBetweenEvents(eventA, eventB);
  return Math.sqrt((d.x ** 2) + (d.y ** 2));
};

export const didCursorMove = (eventA, eventB, threshold = 0) => {
  const distance = getDistanceValueBetweenEvents(eventA, eventB);
  return distance > threshold;
};

import convert from 'color-convert';

import { INTERNAL_INDEX } from '../../constants';
import { getValue } from '../immutability';

import * as THREE from '../../../public/libs/three.js/build/three.module';

const getCategories = (pointCloud) => getValue(pointCloud.categories, []);

const loopClasses = (pointCloud, callback) => getCategories(pointCloud) // group = acc = {}, group = {name: 'ARCHITECTURE', type: 'CATEGORY', color: '#629393', children: Array(12)}
  .reduce((acc, group) => {
    group.children.forEach((child) => callback(acc, child));
    return acc;
  }, {});

const getClassNames = (pointCloud, condition) => getValue(loopClasses(pointCloud, (acc, child) => {
  acc.names = getValue(acc.names, []);

  if (!condition || condition(child)) {
    acc.names = [...acc.names, child.name];
  }
}).names, []);

export const getOnlyClassNames = (pointCloud) => getValue(loopClasses(pointCloud, (acc, child) => {
  acc.names = getValue(acc.names, []);
  acc.names = [...acc.names, child.name];
}).names, []);

const classIdsToNames = (pointCloud, ids) => getClassNames(
  pointCloud,
  (child) => ids.includes(child.code),
);

export const getPointCloudCategoryNames = (pointCloud, options) => {
  const prevState = options.visible || options.prevState.length
    ? options.prevState
    : getClassNames(pointCloud);

  const changed = classIdsToNames(pointCloud, options.changed);

  const getFilter = (arr) => (name) => !arr.includes(name);

  return options.visible
    ? [...prevState, ...changed.filter(getFilter(prevState))]
    : prevState.filter(getFilter(changed));
};

export const pointCloudCategoriesToPotreeClassification = (pointCloud) => {
  const categories = getCategories(pointCloud); // Array[4]{}[{children: Array(12)[{}], color: #629393, name: 'ARCHITECTURE', type: 'CATEGORY'},{MEP},{FURNITURE},{OTHER}]
  return categories.length > 0 && loopClasses(pointCloud, (acc, child) => { // acc = {2: ga, 3: ga, 8: ga, 16: ga, DEFAULT: ga}, child = {name: 'BEAM, type: 'CLASS', code: 2, color: '#5F9B86}
    const rgb = convert.hex.rgb(child.color); // child.color = #629393 // rgb = [98, 147, 147]
    acc[child.code] = rgb.map((x) => x / 255);
    acc.DEFAULT = new THREE.Vector4(0, 0, 0); // sets default classifications
  });
};

export const pointCloudCategoriesToSegmentationCategories = (pointCloud, t) => {
  const categories = getCategories(pointCloud);

  const getId = (id, index) => getValue(id, INTERNAL_INDEX + index);

  const getLabel = (key) => {
    const value = getValue(key, '').toLowerCase();
    return t(`segmentation.${value}`);
  };

  return categories.map((group, i) => ({
    children: group.children.map((child, j) => ({
      children: [],
      color: child.color,
      id: getId(child.code, `${i}${j}`),
      label: getLabel(child.name),
      open: false,
      visible: true,
    })),
    color: group.color,
    id: getId(group.code, i),
    label: getLabel(group.name),
    open: false,
    visible: true,
  }));
};

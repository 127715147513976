import React from 'react';
import { ICON_PROPS } from 'constants';
import Svg from '.';

export default /* istanbul ignore next */ (props) => (
  <Svg
    {...ICON_PROPS}
    {...props}
  >
    <path
      d="M5.75 12.9767L10.1628 17.3895L17.3576 7.125"
      stroke="currentColor"
    />
  </Svg>
);

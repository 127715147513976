import { setEditorSelectedLinks } from 'actions/editor';
import { fetchAction } from 'actions/helpers';
import {
  BULK_UPDATE_SPACE_SCENES,
  CLEAR_PROJECT,
  RECEIVE_DELETE_LINK,
  RECEIVE_DELETE_PROJECT,
  RECEIVE_DELETE_SCENE,
  RECEIVE_DELETE_SPACE_SCENE,
  RECEIVE_PATCH_LINK,
  RECEIVE_POST_AUTOLINK,
  RECEIVE_POST_LINK,
  RECEIVE_POST_PUBLISH,
  RECEIVE_POST_REDACT,
  RECEIVE_PROJECT,
  RECEIVE_PUBLICATION,
  RECEIVE_SCENE,
  RECEIVE_SCENES_FOR_SPACE,
  RECEIVE_SIGNED_URLS,
  RECEIVE_SPACE,
  RECEIVE_SPACES,
  RECEIVE_UPDATE_SPACE_SCENE,
  REQUEST_REPLACE_SCENE,
  REQUEST_SCENE_UPDATE,
  REQUEST_SIGNED_URLS,
} from 'actions/types';
import { API, FETCH_AUTHORIZATION, FETCH_PARSER } from 'constants';
import {
  createUploadStream,
  fileDownload,
  formBody,
  isDefined,
  jsonBody,
  makeMarkerGrid,
  objectToFormData,
} from 'helpers';
import { getEditorSelectedLinks, getEditorSelectedSceneIds } from 'selectors/editor';
import { getMapDimensions } from 'selectors/mapDimensions';
import { getMarkerSize, getSceneIdsBySpaceId } from 'selectors/project';

export const archiveProject = (projectId) => fetchAction({
  method: 'DELETE',
  type: RECEIVE_DELETE_PROJECT,
  url: API.editor.projects.one(projectId).root(),
});

export const clearProject = () => ({ type: CLEAR_PROJECT });

const fetchProject = (url) => fetchAction({ type: RECEIVE_PROJECT, url });

export const fetchEditorProject = (id) => fetchProject(API.editor.projects.one(id).root());

export const fetchViewerProject = (id) => fetchProject(API.editor.scans.one(id).root());

export const createProject = (params) => fetchAction({
  body: objectToFormData(params),
  method: 'POST',
  url: API.editor.projects.root(),
});

export const publishProject = (projectId) => fetchAction({
  method: 'POST',
  type: RECEIVE_POST_PUBLISH,
  url: API.editor.projects.one(projectId).publish(),
});

export const fetchPublication = (projectId) => fetchAction({
  type: RECEIVE_PUBLICATION,
  url: API.editor.projects.one(projectId).publish(),
});

export const updateProject = (projectId, params) => fetchAction({
  body: objectToFormData(params),
  method: 'PATCH',
  type: RECEIVE_PROJECT,
  url: API.editor.projects.one(projectId).root(),
});

export const saveSpaces = (projectId, params) => fetchAction({
  ...jsonBody(params),
  method: 'POST',
  type: RECEIVE_SPACES,
  url: API.editor.projects.one(projectId).spaces.update(),
});

export const fetchScenes = (projectId, spaceId) => fetchAction({
  ignoreDuplicates: true,
  payload: (scenes) => ({
    scenes,
    spaceId,
  }),
  type: RECEIVE_SCENES_FOR_SPACE,
  url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.root(),
});

export const fetchSignedUrlsForScene = (projectId, sceneId) => (dispatch) => {
  dispatch({ payload: sceneId, type: REQUEST_SIGNED_URLS });

  return dispatch(fetchAction({
    type: RECEIVE_SIGNED_URLS,
    url: API.editor.projects.one(projectId).scenes.one(sceneId).root(),
  }));
};

export const replaceScene = (projectId, spaceId, scene, file) => async (dispatch) => {
  const params = {
    fileName: file.name,
    sceneName: scene.name,
  };

  const { id } = scene;

  dispatch({
    payload: { id },
    type: REQUEST_REPLACE_SCENE,
  });

  const attachment = await dispatch(fetchAction({
    body: objectToFormData(params),
    method: 'PATCH',
    url: API.editor
      .projects.one(projectId)
      .spaces.one(spaceId)
      .scenes.one(id)
      .replace(),
  }));

  if (attachment.directUploadUrl) {
    createUploadStream({ attachment, file }).start();
  }
};

export const updateScene = (projectId, sceneId, params) => (dispatch) => {
  dispatch({
    payload: {
      id: sceneId,
      params,
    },
    type: REQUEST_SCENE_UPDATE,
  });

  return dispatch(fetchAction({
    body: objectToFormData(params),
    method: 'PATCH',
    type: RECEIVE_SCENE,
    url: API.editor.projects.one(projectId).scenes.one(sceneId).root(),
  }));
};

export const updateSpaceScene = (projectId, spaceId, sceneId, params) => (dispatch) => {
  const scene = {};

  if (isDefined(params.mapX)) {
    params.mapX = Number.parseInt(params.mapX, 10);
    scene.mapX = params.mapX;
  }

  if (isDefined(params.mapY)) {
    params.mapY = Number.parseInt(params.mapY, 10);
    scene.mapY = params.mapY;
  }

  if (params.spaceId) {
    scene.spaceId = params.spaceId;
  }

  dispatch({
    payload: {
      scene,
      sceneId,
      spaceId,
    },
    type: RECEIVE_UPDATE_SPACE_SCENE,
  });

  return dispatch(fetchAction({
    ...formBody(params),
    ignoreTypeOnce: RECEIVE_SCENES_FOR_SPACE,
    method: 'PATCH',
    url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.one(sceneId).root(),
  }));
};

export const bulkUpdateSpaceScenes = (projectId, spaceId, payload) => (dispatch) => {
  dispatch({
    payload: {
      spaceId,
      spaceScenes: payload,
    },
    type: BULK_UPDATE_SPACE_SCENES,
  });

  return dispatch(fetchAction({
    ...jsonBody(payload),
    method: 'PATCH',
    parser: false,
    url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.update(),
  }));
};

export const deleteScene = (projectId, sceneId) => fetchAction({
  method: 'DELETE',
  parser: false,
  payload: sceneId,
  type: RECEIVE_DELETE_SCENE,
  url: API.editor.projects.one(projectId).scenes.one(sceneId).root(),
});

export const removeSceneFromSpace = (projectId, spaceId, sceneId) => fetchAction({
  method: 'DELETE',
  parser: false,
  payload: {
    sceneId,
    spaceId,
  },
  type: RECEIVE_DELETE_SPACE_SCENE,
  url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.one(sceneId).root(),
});

export const createLink = (projectId, sceneId, toSceneId) => fetchAction({
  ...formBody({ to_scene_id: toSceneId }),
  method: 'POST',
  type: RECEIVE_POST_LINK,
  url: API.editor.projects.one(projectId).scenes.one(sceneId).links.root(),
});

const doDeleteLink = (projectId, fromId, toId, bidirectional) => fetchAction({
  method: 'DELETE',
  parser: false,
  url: API.editor.projects.one(projectId).scenes.one(fromId).links.one(toId).params({
    bidirectional,
  }),
});

export const deleteLink = (projectId, fromId, toId, bidirectional = false) => (dispatch) => dispatch(doDeleteLink(projectId, fromId, toId, bidirectional))
  .then(() => dispatch({
    payload: {
      bidirectional,
      fromId,
      toId,
    },
    type: RECEIVE_DELETE_LINK,
  }));

export const deleteSelectedLinks = (projectId) => (dispatch, getState) => {
  const editorSelectedLinks = getEditorSelectedLinks(getState());

  const linkPairs = Object.keys(editorSelectedLinks).reduce((acc, id) => {
    Object.keys(editorSelectedLinks[id]).forEach((otherId) => {
      acc.push([id, otherId]);
    });
    return acc;
  }, []);

  const promises = [];
  linkPairs.forEach((linkPair) => {
    promises.push(dispatch(doDeleteLink(projectId, linkPair[0], linkPair[1], true)));
  });

  // wait to reduce so links remain visible until all deletions are complete
  return Promise.all(promises).then(() => {
    linkPairs.forEach((linkPair) => {
      dispatch({
        payload: {
          bidirectional: true,
          fromId: linkPair[0],
          toId: linkPair[1],
        },
        type: RECEIVE_DELETE_LINK,
      });
    });
    dispatch(setEditorSelectedLinks({}));
  });
};

export const updateLink = (projectId, fromId, toId, coords, params) => (dispatch) => {
  const { target, ...restParams } = params;

  return dispatch(fetchAction({
    ...formBody({
      ...coords,
      ...restParams,
      to_scene_id: toId,
    }),
    method: 'PATCH',
    payload: (link) => ({
      fromId,
      link,
    }),
    type: RECEIVE_PATCH_LINK,
    url: API.editor.projects.one(projectId).scenes.one(fromId).links.root(),
  }));
};

export const autoLink = (projectId, spaceId) => (dispatch, getState) => {
  const state = getState();
  let scenes = getEditorSelectedSceneIds(state);

  if (scenes.length < 2) {
    scenes = getSceneIdsBySpaceId(state)[spaceId];
  }
  if (scenes.length > 1) {
    return dispatch(fetchAction({
      ...formBody({ scene_ids: scenes.join(',') }),
      method: 'POST',
      type: RECEIVE_POST_AUTOLINK,
      url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.autolink(),
    }));
  }
  return Promise.resolve();
};

export const downloadScene = (projectId, scene) => async (dispatch) => {
  const response = await dispatch(fetchAction({
    authorization: FETCH_AUTHORIZATION.URL,
    method: 'GET',
    parser: FETCH_PARSER.BLOB,
    url: API.editor.projects.one(projectId).scenes.one(scene.id).download(),
  }));
  fileDownload(response, `${scene.name}.jpg`);
};

export const redactScene = (projectId, spaceId, sceneId, redactions) => fetchAction({
  // remove hotspots
  ...jsonBody(redactions.map((redaction) => ({
    coords: redaction.coords,
    style: redaction.style,
  }))),
  method: 'POST',
  type: RECEIVE_POST_REDACT,
  url: API.editor.projects.one(projectId).spaces.one(spaceId).scenes.one(sceneId).redact(),
});

export const fetchSpaces = (projectId) => fetchAction({
  type: RECEIVE_SPACES,
  url: API.editor.projects.one(projectId).spaces.root(),
});

export const updateSpace = (projectId, spaceId, params) => fetchAction({
  body: objectToFormData(params),
  method: 'PATCH',
  type: RECEIVE_SPACE,
  url: API.editor.projects.one(projectId).spaces.one(spaceId).root(),
});

export const uploadMap = (projectId, spaceId, file) => updateSpace(projectId, spaceId, { map: file });

export const placeOnMap = (projectId, spaceId, sceneIds, center) => (dispatch, getState) => {
  const state = getState();

  if (sceneIds.length) {
    const markerSize = getMarkerSize(state);
    const {
      grid,
      gridHeight,
      gridWidth,
    } = makeMarkerGrid(sceneIds.length, markerSize);
    let gridX;
    let gridY;
    if (center) {
      gridX = center.x;
      gridY = center.y;
    } else {
      const { minimapCenter } = state;
      if (minimapCenter) {
        gridX = minimapCenter.x;
        gridY = minimapCenter.y;
      } else {
        const mapDimensions = getMapDimensions(state)[spaceId];
        gridX = mapDimensions.width / 2;
        gridY = mapDimensions.height / 2;
      }
    }
    gridX -= (gridWidth / 2) - markerSize;
    gridY += (gridHeight / 2) - markerSize;
    sceneIds.forEach((id, index) => {
      dispatch(updateSpaceScene(projectId, spaceId, id, {
        mapX: gridX + grid[index].x,
        mapY: gridY - grid[index].y,
      }));
    });
  }
};
